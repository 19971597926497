import validation_imports from "./validation_imports"
import transform_error_list from "../transform_errors_list"
import {
  MISSING_NAME,
  DUPLICATE_VARIANTS,
  MISSING_VARIANT,
  MISSING_DIMENSION,
  MISSING_CATEGORIZATION,
  MISSING_PROJECT_COUNTRY,
  MISSING_VARIANT_VALUE_FOR_TRANSLATION,
  MISSING_CROSS_CURRENCY,
  DEFAULT_ERROR
} from "../../constants/validation/error_types"
import { deleted, imported, PRODUCTS_STORE } from "../../constants/others_constants"
import get_correct_translation_value from "../../../Shared/methods/get_correct_translation_value"

export default {
  ...validation_imports(PRODUCTS_STORE),
  async validate() {
    if (this.show_item_preview) return true
    // Clear error list and reset validator
    this.clear_errors(this.product_data.id)

    // Defining validator without reactivity
    this.validator = true

    if (this.product_data.change_type === deleted) return this.validator

    const does_product_have_title_for_each_translation = this.all_available_languages.every(lang =>
      this.product_data.translations && this.product_data.translations[lang] && this.product_data.translations[lang].title
    )

    // Title - Checks if title is filled out
    if (!does_product_have_title_for_each_translation && !this.product_data.title) this.transforming_errors(
      await MISSING_NAME(
        this.$translate("products.product"),
        this.product_data.id,
        this.$translate("title_translation").toLowerCase()
      ),
      this.product_data
    )

    // Dimensions
    if (
      !this.is_cross_sold_product &&
      this.products_type === imported &&
      this.requires_shipping &&
      !["weight", "length", "height", "width"].every(dimension => this.variants.every(variant => variant[dimension]))
    ) this.transforming_errors(await MISSING_DIMENSION(this.product_data.id), this.product_data)

    if (
      !this.is_cross_sold_product &&
      this.enable_cross_selling &&
      !this.cross_currency
    ) this.transforming_errors(await MISSING_CROSS_CURRENCY(this.product_data.id), this.product_data)

    // Marketplace and Cross selling availability
    if (
      !this.is_cross_sold_product &&
      this.products_type === imported &&
      (this.allow_on_marketplace || this.enable_cross_selling) &&
      !this.product_data.categories
    ) this.transforming_errors(await MISSING_CATEGORIZATION(this.product_data.id), this.product_data)

    if (
      this.products_type === imported &&
      this.allow_on_marketplace &&
      (
        !this.shipping_options ||
        (
          !this.shipping_options.project_address ||
          (this.shipping_options.project_address && !this.shipping_options.project_address.country)
        )
      )
    ) this.transforming_errors(await MISSING_PROJECT_COUNTRY(this.product_data.id), this.product_data)

    // Variants
    const are_there_any_duplicate_variants = this.all_available_languages.some((lang) => {
      const any_duplicates_for_language = this.variants.reduce((tot, variant) => {
        if (tot === true) return tot

        const variant_name = `${
          get_correct_translation_value(variant, "option1", [lang], true)}/${
          get_correct_translation_value(variant, "option2", [lang], true)}/${
          get_correct_translation_value(variant, "option3", [lang], true)
        }`

        if (tot.some(variant => variant === variant_name) && variant.change_type !== deleted) tot = true
        else if (variant.change_type !== deleted) tot.push(variant_name)
  
        return tot
      }, [])

      // If an array is returned, no duplicated have been found
      return any_duplicates_for_language.length ? false : true
    }, false)

    const are_all_languages_filled_out = this.variants.every(({ translations }) => {
      if (!translations) return false

      const keys_to_check = [
        // Only add options if more than one (Default option)
        ...(this.product_data?.options?.length === 1 ?
          [] :
          this.product_data?.options?.map((_, ind) => `option${ind + 1}`)),
        "price"
      ]

      if (!this.all_available_languages.every(lang => translations[lang])) return false
      if (!this.all_available_languages.every(lang => keys_to_check.every(key => translations[lang][key] || translations[lang][key] === 0))) return false
      return true
    })

    const has_product_or_variant_purchase_price = this.variants.some(({ purchase_price }) => purchase_price)

    if (
      this.products_type === imported &&
      !this.product_data.title && // If product doesnt have old data structure without translations
      !are_all_languages_filled_out
    ) this.transforming_errors(await MISSING_VARIANT_VALUE_FOR_TRANSLATION(this.product_data.id), this.product_data)

    if (
      !this.is_cross_sold_product &&
      has_product_or_variant_purchase_price &&
      !this.product_data.purchase_currency
    ) this.transforming_errors(
      DEFAULT_ERROR(this.product_data.id, this.$translate("errors.product.missing_purchase_currency")),
      this.product_data
    )

    if (are_there_any_duplicate_variants === true) this.transforming_errors(
      await DUPLICATE_VARIANTS(this.product_data.id),
      this.product_data
    )
    if (!this.variants.length || this.variants.every(({ change_type }) => change_type === deleted)) this.transforming_errors(
      await MISSING_VARIANT(this.product_data.id),
      this.product_data
    )

    return this.validator
  },
  transforming_errors(error, product, remove) {
    this.update_errors(transform_error_list(this.get_error_products_list(), error, remove, product.id))
    this.update_error_list_title(this.get_error_products_list().length ? this.$translate(
      "errors.product.title",
      { product_title: get_correct_translation_value(product, "title", [this.user_info.language_code]) }
    ) : "")
    this.validator = false
  }
}
