export const csv_fields = {
  parent_collection: "Parent collection",
  collection: "Collection",
  product_title: "Product title",
  is_product_available: "Is product available",
  tags: "Tags",
  images: "Images",
  image_alt_texts: "Image Alt Texts",
  message: "Message",
  description: "Description",
  categorization: "Categorization",
  place_on_marketplace: "Place On Marketplace",
  cost_price_currency: "Cost Price Currency",
  dimensions: "Dimensions",
  variant_option_names: "Variant Option Names",
  variant_option_values: "Variant Option Values",
  variant_prices: "Variant Prices",
  variant_prices_before_discount: "Variant Prices Before Discount",
  variant_skus: "Variant SKUs",
  variant_total_inventory: "Variant Total Inventory",
  variant_purchase_prices: "Variant Purchase Prices",
  variant_dimensions: "Variant Dimensions"
}
