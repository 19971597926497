import { deleted, imported } from "../constants/others_constants"
import get_correct_translation_value from "../../Shared/methods/get_correct_translation_value"

export default function () {
  return this.get_all_products.filter(({ translations, variants, allow_on_marketplace, options, purchase_currency, categories, resale_percentage }) => {
    if (this.page_type === imported) {
      // Title - Checks if title is filled out for each language
      const does_product_have_title_for_each_translation = this.all_available_languages.every(lang =>
        translations && translations[lang] && translations[lang].title
      )

      if (!does_product_have_title_for_each_translation) return true

      // Check dimensions for physical products
      if (
        !resale_percentage &&
        variants[0].requires_shipping &&
        !["weight", "length", "height", "width"].every(dimension => variants.every(variant => variant[dimension]))
      ) return true

      // Product enabled on marketplace but doesnt have categories
      if (
        !resale_percentage &&
        allow_on_marketplace &&
        !categories
      ) return true

      const are_all_languages_filled_out = variants.every(({ translations: variant_translations }) => {
        if (!variant_translations) return true

        const keys_to_check = [
          // Only add options if more than one (Default option)
          ...(options?.length === 1 ? [] : options?.map((_, ind) => `option${ind + 1}`) || []),
          "price"
        ]

        if (!this.all_available_languages.every(lang => variant_translations[lang])) return false
        if (!this.all_available_languages.every(
          lang => keys_to_check.every(key => variant_translations[lang][key] || variant_translations[lang][key] === 0))
        ) return false
        return true
      })

      if (!are_all_languages_filled_out) return true
    }

    // Variants
    const are_there_any_duplicate_variants = this.all_available_languages.some(lang =>
      variants.reduce((tot, variant) => {
        if (tot === true) return tot

        const variant_name = `${
          get_correct_translation_value(variant, "option1", [lang])}/${
          get_correct_translation_value(variant, "option2", [lang])}/${
          get_correct_translation_value(variant, "option3", [lang])
        }`

        if (tot.some(variant => variant === variant_name) && variant.change_type !== deleted) tot = true
        else if (variant.change_type !== deleted) tot.push(variant_name)

        return tot
      }, []) === true
    )

    if (
      !resale_percentage &&
      variants.some(({ purchase_price }) => purchase_price) &&
      !purchase_currency
    ) return true
    if (are_there_any_duplicate_variants) return true

    return false
  })
}
