<template>
  <transition name="slide-error-bar">
    <div v-if="error_list.length" class="error-bar" :style="{ height }">
      <h3 class="error-bar__top">
        <span>
          {{ title || $translate("errors.title") }}
        </span>

        <md-button class="md-icon-button md-dense" @click="toggle_errors">
          <md-icon>{{ open_errors ? "keyboard_arrow_up" : "keyboard_arrow_down"}}</md-icon>
        </md-button>
      </h3>

      <div v-if="open_errors" class="error-bar__errors">
        <p
          v-for="{ message, type } in list_without_duplicates"
          :key="`${type}-error`"
          class="error-bar__error"
        >
          - {{ message }}
        </p>
      </div>
    </div>
  </transition>
</template>

<script>
import { event_hub } from "../../../../main"

export default {
  props: {
    error_list: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: false,
      default: ""
    },
    height: {
      type: String,
      required: false,
      default: "auto"
    },
    product_id: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      open_errors: true,
      scroll_listener: null,
      user_opened: false
    }
  },
  computed: {
    list_without_duplicates() {
      return this.error_list
        .filter(({ id }) => this.product_id ? id === this.product_id : true)
        .reduce((tot, error) => {
          if (
            !tot.some(({ type }) => type === error.type)
          ) tot.push(error)
          return tot
        }, [])
    }
  },
  watch: {
    error_list() {
      if (!this.error_list.length) {
        this.clear_bar_state()
      }
    }
  },
  mounted() {
    event_hub.$on("toggle_error_bar", (value) => {
      this.open_errors = value === undefined ? !this.open_errors : value
    })

    // expand error bar when route changes
    this.$router.afterEach(() => {
      this.open_errors = true
    })

    // close error bar when scrolling
    this.scroll_listener = window.addEventListener("scroll", () => {
      if (!this.user_opened) this.open_errors = false
    })
  },
  beforeDestroy() {
    this.clear_bar_state()
  },
  methods: {
    toggle_errors() {
      this.open_errors = !this.open_errors

      if (this.open_errors) this.user_opened = true
      else this.user_opened = false
    },
    clear_bar_state() {
      event_hub.$off("toggle_error_bar")
      window.removeEventListener("scroll", this.scroll_listener)
      this.open_errors = true
      this.user_opened = false
    }
  }
}
</script>

<style lang="scss">
  @use "../../../../styles/_global-constants" as *;
  @use "../../../../styles/_admin-constants" as *;

  .error-bar {
    position: fixed;
    top: calc(#{$admin-top-bar-height} + #{$material-size});
    width: calc(100% - 100px);
    border-radius: $border-radius;
    font-weight: bold;
    z-index: $z-index--middle;
    font-weight: 600;
    color: $pure-white;
    overflow: hidden;

    @media (max-width: $material-medium-breakpoint) {
      top: calc(#{$admin-top-bar-height--mobile} + #{$material-size});
      width: calc(100% - #{$admin-side-menu-width--mobile + $default-size});
      font-size: $font-size--tiny;
    }

    &__top {
      display: grid;
      grid-template-columns: 1fr $icon-size;
      grid-gap: $half-default-size;
      font-weight: bold;
      align-items: center;
      padding: $half-default-size $default-size;
      margin: 0;
      background-color: rgba($material-red, 0.9);

      @media (max-width: $tablet--small) {
        padding: $half-default-size;
      }
    }

    &__error {
      margin: 0 0 5px;
    }

    &__errors {
      overflow: auto;
      max-height: 200px;
      padding: 0 $half-default-size $half-default-size;
      background-color: rgba($material-red, 0.9);
    }
  }

  .slide-error-bar-enter-active, .slide-error-bar-leave-active {
    transition: .5s;
  }
  .slide-error-bar-enter, .slide-error-bar-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translateY(-20px);
  }
</style>
