import validation_imports from "./validation_imports"
import transform_error_list from "../transform_errors_list"
import { MISSING_NAME, MISSING_SELECTED_ITEMS, DUPLICATE_ENTITY_TITLE } from "../../constants/validation/error_types"
import { ADMIN } from "../../constants/others_constants"

export default {
  ...validation_imports(ADMIN),
  async validate(validating_when_saving = true) {

    // Clear error list and reset validator
    this.clear_errors()
    this.validator = true

    if (this.is_edited_collection_parent) {
      if (!this.edited_collection_name)
        this.transforming_errors(await MISSING_NAME(this.$translate("errors.collections.parent_collection")))
      if (!this.collection_entities.length && validating_when_saving) {
        this.validator = false
        this.select_collections_error = await MISSING_SELECTED_ITEMS(this.$translate("errors.collections.collection")).then(({ message }) => message)
      }
      else this.collection_name_error = null

      // If parent collection has duplicate name
      if (
        this.edited_collection_name &&
        this.get_parent_collections
          .filter((_, ind) => ind !== this.edited_collection_index)
          .some(parent => parent.toLowerCase() === this.edited_collection_name.toLowerCase())
      ) {
        this.validator = false
        this.collection_name_error = await DUPLICATE_ENTITY_TITLE(this.$translate("errors.collections.parent_collection")).then(({ message }) => message)
      } else this.collection_name_error = null
    }
    else {
      if (
        this.edited_collection_name &&
        this.get_correct_collections_data.some(({ title, id }) =>
          (window.isNaN(this.edited_collection_index) || id !== this.get_collections[this.edited_collection_index]?.id) &&
          title.toLowerCase() === this.edited_collection_name.toLowerCase()
        )
      ) {
        this.validator = false
        this.collection_name_error = await DUPLICATE_ENTITY_TITLE(this.$translate("errors.collections.collection")).then(({ message }) => message)
      }
      else this.collection_name_error = null
    }

    // Check if newly created collection has name
    if (this.open_collection_edit_modal && this.edited_collection_index === undefined && !this.edited_collection_name)
        this.transforming_errors(await MISSING_NAME(
          this.is_edited_collection_parent ?
            this.$translate("errors.collections.parent_collection") :
            this.$translate("errors.collections.collection")
        ))

    return this.validator
  },
  transforming_errors(error, remove) {
    this.update_errors(transform_error_list(this.get_error_list(), error, remove))
    this.validator = false
  }
}
