import store from "../../../store"
import { SHARED_STORE } from "../../Shared/constants/other"
import convert_currency from "../../Shared/methods/convert_currency"
import { GET_CURRENCY_RATES } from "../../Shared/stores/Shared/constants"

export default async (currency, base_price) => {
  const currency_rates = store.state[SHARED_STORE].currency_rates?.[base_price.currency]

  // If currency rates already fetched
  if (currency_rates) return convert_currency(
    base_price.price,
    currency,
    currency_rates.rates,
    false
  )

  await store.dispatch(
    `${SHARED_STORE}/${GET_CURRENCY_RATES}`,
    base_price.currency
  )

  const updated_currency_rates = store.state[SHARED_STORE].currency_rates?.[base_price.currency]

  return convert_currency(
    base_price.price,
    currency,
    updated_currency_rates.rates,
    false
  )
}
