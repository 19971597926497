<template>
  <section class="cross-product-modal" :class="{ 'cross-product-modal--previewing': product_for_preview}">
    <div v-if="product_for_preview">
      <div class="cross-product-modal__back-button" @click="product_for_preview = null">
        <md-icon>chevron_left</md-icon>
        {{ $translate("back") }}
        <md-button
          class="md-icon-button md-raised md-primary md-success"
          @click="$emit('add_product', product_for_preview)"
        >
          <md-icon>add</md-icon>
          <md-tooltip md-direction="left">
            {{ $translate("products.cross_sale.add_cross_product") }}
          </md-tooltip>
        </md-button>
      </div>
      <product_detail
        :collection_index="0"
        :product_index="0"
        collections_type=""
        :product_data="product_for_preview"
        products_type="imported"
        :is_shopify_product="false"
        image_route=""
        :show_item_preview="true"
      />
    </div>
    <div v-show="!product_for_preview">
      <md-tabs class="md-transparent" md-alignment="fixed" @md-changed="clear_searches">
        <md-tab
          id="tab-categories"
          class="cross-product-modal__tab"
          :md-label="$translate('products.cross_sale.search_by_category')"
        >
          <categorization_selector
            @selected_categories="(categories) => search_by_category(categories[categories.length - 1])"
          />
        </md-tab>
        <md-tab
          id="tab-search"
          class="cross-product-modal__tab"
          :md-label="$translate('products.cross_sale.search_by_parameters')"
        >
          <div class="cross-product-modal__search">
            <md-field>
              <md-input
                v-model="query"
                :placeholder="$translate('products.cross_sale.search_by_parameters_placeholder')"
                @input="() => search_products(query)"
              />
            </md-field>
          </div>
        </md-tab>
      </md-tabs>

      <h3 v-if="!found_products || loading || (found_products && found_products.length)" class="md-title">
        {{ $translate("products.cross_sale.found_products") }}:

        <div class="cross-product-modal__search-items-toggle">
          <md-button class="md-icon-button md-raised" :disabled="show_product_list" @click="show_product_list = true">
            <md-icon>list</md-icon>
          </md-button>
          <md-button class="md-icon-button md-raised" :disabled="!show_product_list" @click="show_product_list = false">
            <md-icon>apps</md-icon>
          </md-button>
        </div>
      </h3>

      <p v-if="!loading && !found_products" class="cross-product-modal__search-items-none">{{ $translate("products.cross_sale.no_products") }}</p>
      <div v-if="(found_products && found_products.length) || loading" class="cross-product-modal__search-items-wrapper">
        <Loader v-if="loading && found_products && !found_products.length" class="cross-product-modal__loader"/>
        <div
          v-else
          class="cross-product-modal__search-items"
          :class="{ 'cross-product-modal__search-items-list': show_product_list }"
          ref="found_cross_items"
        >
          <small_product_detail
            v-for="product in found_products"
            :key="product.id"
            :main_product_image="get_correct_first_image(product)"
            :is_product_without_variants="product.variants.length === 1"
            :product_options="product.options"
            :title="get_correct_translation_value(product, 'title', [user_info.language_code])"
            :product_data="product"
            :languages="[user_info.language_code]"
            :show_list_item="show_product_list"
            :show_cross_sale_info="true"
            :already_added="is_product_already_in_store(product.id)"
            @add_product="$emit('add_product', product)"
            @preview_product="assign_product(product)"
          />
          <Loader v-if="!last_load_empty && further_loading" class="cross-product-modal__loader" /> 
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Loader from "@/applications/Shared/components/Loader/index.vue"
import { mapState } from "vuex"
import debounce from "../../../../Shared/methods/debounce"
import get_correct_first_image from "../../../../Shared/methods/get_correct_first_image"
import get_correct_translation_value from "../../../../Shared/methods/get_correct_translation_value"
import { get_cross_products_by_categories, search_cross_products } from "../../../constants/endpoints/other"
import { PRODUCTS_STORE, USER_STORE } from "../../../constants/others_constants"
import categorization_selector from "./categorization_selector"
import small_product_detail from "./small_product_detail"
import product_detail from "./product_detail"

export default {
  components: {
    Loader,
    categorization_selector,
    small_product_detail,
    product_detail
  },
  data() {
    return {
      loading: false,
      show_product_list: true,
      further_loading: false,
      last_load_empty: false,
      searched_products_page: 0,
      query: "",
      selected_category: [],
      found_products: [],
      product_for_preview: null,
      search_results_amount: 10,
      modal_scroll_position: 0,
      modal_element: null
    }
  },
  computed: {
    ...mapState(USER_STORE, ["user_info"]),
    ...mapState(PRODUCTS_STORE, ["imported_products"]),
  },
  watch: {
    query(new_query, old_query) {
      if (new_query !== old_query) this.searched_products_page = 0
    },
    found_products(items_loaded, old_items) {
      this.$nextTick(() => {
        if (items_loaded?.length && (!old_items || !old_items?.length)) this.$refs.found_cross_items.addEventListener(
          "scroll",
          (event) => {
            const element = event.target;

            if (element.scrollHeight - element.scrollTop < element.clientHeight + 200) this.load_more_products()
        })
      })
    },
    product_for_preview(new_val) {
      if (!new_val) {
        this.$nextTick(() => {
          this.modal_element.scrollTop = this.modal_scroll_position
          this.modal_scroll_position = 0
        })
      }
    }
  },
  mounted() {
    this.modal_element = document.querySelector(".md-dialog")
  },
  methods: {
    get_correct_first_image,
    get_correct_translation_value,
    assign_product(product) {
      this.modal_scroll_position = this.modal_element.scrollTop
      this.product_for_preview = product

      this.$nextTick(() => this.modal_element.scrollTop = 0)
    },
    async load_more_products() {
      debounce(async () => {
        if (this.further_loading || this.last_load_empty) return

        this.further_loading = true
        this.searched_products_page++

        if (this.query) await this.search_products(this.query, false)
        else await this.search_by_category(this.selected_category)

        setTimeout(() => this.further_loading = false, 500);
      }, 500, this)()
    },
    async search_products(query, should_wait = true) {
      this.loading = true
      this.selected_category = []

      debounce(async () => {
        if (query.length > 2) {
          const { data } = await search_cross_products({
            query,
            page: this.searched_products_page,
            number_of_results: this.search_results_amount
          })

          setTimeout(() => {
            if (this.searched_products_page) {
              this.found_products = [...this.found_products, ...data]
              this.last_load_empty = Boolean(!data.length)
            }
            else this.found_products = data.length ? data : null

            this.loading = false
          }, 500);
        }
        else {
          this.loading = false
          this.found_products = this.found_products?.length ? this.found_products : []
        }
      }, should_wait ? 1000 : 0, this)()
    },
    async search_by_category(category) {
      if (this.selected_category !== category) this.searched_products_page = 0
      this.selected_category = category
      this.loading = true

      const { data } = await get_cross_products_by_categories({
        category,
        page: this.searched_products_page,
        number_of_results: this.search_results_amount
      })

      setTimeout(() => {
        if (this.searched_products_page) {
          this.found_products = [...this.found_products, ...data]
          this.last_load_empty = Boolean(!data.length)
        }
        else this.found_products = data.length ? data : null
        this.loading = false
      }, 500);
    },
    is_product_already_in_store(product_id) {
      return this.imported_products.some(({ id }) => id === product_id)
    },
    clear_searches() {
      this.query = ""
      this.selected_category = []
      this.searched_products_page = 0
      this.last_load_empty = false
      this.further_loading = false
      this.found_products = []
    }
  }
}
</script>

<style lang="scss">
  @use "../../../../../styles/_admin-constants" as *;
  @use "../../../../../styles/_global-constants" as *;

  .cross-product-modal {
    padding: $default-size;

    &__search {
      max-width: 400px;
      margin: 0 auto;

      &-items {
        display: grid;
        max-height: 500px;
        overflow: auto;
        padding: $half-default-size;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: $default-size;
        row-gap: $default-size;

        &-list {
          grid-template-columns: 1fr;
          row-gap: $half-default-size;
        }

        &-toggle {
          float: right;
        }

        &-wrapper {
          position: relative;
        }

        &-none {
          text-align: center;
          padding: $default-size;
        }
      }
    }

    &__search-again {
      display: block !important;
      margin: $half-default-size auto !important;
    }

    &__loader {
      margin: $double-default-size auto;
    }

    &__search {
      .md-input {
        text-align: center;
      }
    }

    &__tab {
      box-shadow: inset -1px 0px 12px 1px $shadow-color-light;
      border-radius: $border-radius;
      overflow: hidden;

      .md-tab {
        min-height: 250px;
      }

      &-closed {
        box-shadow: unset;
      }
    }

    &__back-button {
      font-weight: bold;
      transition: $material-transition;
      cursor: pointer;
      margin-bottom: $half-default-size;
      line-height: $material-button-small;

      .md-button {
        float: right;
      }

      i {
        vertical-align: middle;
      }

      &:hover {
        color: $blue;

        i {
          color: $blue !important;
        }

        .md-button {
          background-color: var(--md-theme-default-text-primary-on-primary, $pure-white);

          i {
            color: var(--md-theme-default-text-primary-on-primary, $pure-white) !important;
          }
        }
      }
    }
  }
</style>
