import store from "../../../store"
import un_bind from "../../Shared/methods/un_bind"
import get_correct_variant_price from "./get_correct_variant_price"
import { ADMIN, CONFIGURATION, default_currency, default_option_name, default_product_name } from "../constants/others_constants";

export default async cross_product => {
  const store_translations = store.state[ADMIN][CONFIGURATION].translations

  // Updating translations to reflect the stores language structure
  const updated_translations = Object.keys(store_translations).reduce((tot, lang) => ({
    ...tot,
    [lang]: {
      title: default_product_name,
      body_html: "",
      ...(cross_product.translations?.[lang] || {}),
      currency_code: (store_translations[lang] || {}).currency_code || default_currency
    }
  }), {})

  // Updating variant prices to be correctly converted to store specific currencies per store language
  const updated_variants = await Promise.all(cross_product.variants.map(async variant => {
    const translations = await Object
      .keys(store_translations)
      .reduce(async (tot, lang) => {
        const total_variants = await tot
        const price = await get_correct_variant_price(
          updated_translations[lang].currency_code,
          variant.base_price
        )

        return {
          ...total_variants,
          [lang]: {
            option1: default_option_name,
            option2: null,
            option3: null,
            ...(variant.translations?.[lang] || {}),
            compare_at_price: null,
            price
          }
        }
      }, Promise.resolve({}))

    return ({
      ...variant,
      translations
    })
  }))

  return un_bind({
    ...cross_product,
    translations: updated_translations,
    variants: updated_variants,
    _highlightResult: undefined,
    objectID: undefined,
    project_country: undefined,
    project_domain: undefined,
    project_email: undefined,
    project_name: undefined,
    purchase_currency: undefined,
    views_on_store: undefined,
  })
}
