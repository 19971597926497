<template>
  <div class="admin-product-variants">
    <!-- For product without variants (meaning one variant without options) -->

    <div v-if="is_product_without_variants">
      <p class="md-title">
        {{ $translate("products.variants.pricing_and_inventory") }}:

        <md-button
          data-cy="add-variant"
          v-if="permissions.products.manage && !show_item_preview"
          class="md-icon-button md-raised md-primary product-detail__fullscreen__add"
          @click="add_variant"
        >
          <md-icon>add</md-icon>
          <md-tooltip md-direction="top">{{ $translate("products.variants.add_new_variant") }}</md-tooltip>
        </md-button>
      </p>
      <div class="admin-product-variants__single-variant admin-product-variants__variant">
        <div class="md-layout">

          <!-- Price -->
          <div
            class="md-layout-item"
            :class="{ 'admin-product-variants__variants-price': variants[0].price_history && Object.keys(variants[0].price_history).length > 1 }"
          >
            <md-field>
              <label>{{ $translate('products.price') }}*</label>
              <span v-if="is_currency_symbol(currency)" class="md-prefix">{{ currency }}</span>
              <md-input
                data-cy="product-price"
                :disabled="!permissions.products.manage || show_item_preview || is_cross_sold_product"
                :value="get_correct_translation_value(variants[0], 'price', languages, false)"
                @change="event => update_variant(0, 'price', Number(event.target.value))"
              />
              <span v-if="!is_currency_symbol(currency)" class="md-suffix">{{ currency }}</span>
            </md-field>
            <md-button
              v-if="variants[0].price_history && Object.keys(variants[0].price_history).length > 1"
              class="md-icon-button"
              @click="selected_variant_price_history = 0"
            >
              <md-icon>timeline</md-icon>
              <md-tooltip md-direction="top">{{ $translate("products.variants.price_history") }}</md-tooltip>
            </md-button>
          </div>

          <!-- Compare price -->
          <div class="md-layout-item">
            <md-field>
              <label>{{ $translate('products.compare_at_price') }}</label>
              <span v-if="is_currency_symbol(currency)" class="md-prefix">{{ currency }}</span>
              <md-input
                data-cy="price-before"
                type="number"
                :disabled="!permissions.products.manage || show_item_preview || is_cross_sold_product"
                :value="get_correct_translation_value(variants[0], 'compare_at_price', languages, false)"
                @change="event => update_variant(0, 'compare_at_price', Number(event.target.value))"
              />
              <span v-if="!is_currency_symbol(currency)" class="md-suffix">{{ currency }}</span>
            </md-field>
          </div>

          <!-- Quantity -->
          <div class="md-layout-item">
            <md-field>
              <label>{{ $translate('products.quantity') }}</label>
              <md-input
                data-cy="total-inventory"
                :disabled="!permissions.products.manage || show_item_preview || is_cross_sold_product"
                type="number"
                :value="variants[0].inventory_quantity"
                @change="event => update_variant_quantity(0, Number(event.target.value))"
              />
            </md-field>
          </div>

          <!-- SKU -->
          <div class="md-layout-item">
            <md-field>
              <label>{{ $translate('products.sku') }}</label>
              <md-input
                data-cy="sku"
                :disabled="!permissions.products.manage || show_item_preview"
                :value="variants[0].sku"
                @change="event => update_variant(0, 'sku', event.target.value)"
              />
            </md-field>
          </div>

          <!-- Purchase price -->
          <div v-if="!is_shopify_product && !is_cross_sold_product" class="md-layout-item">
            <md-field>
              <label>
                {{ $translate("products.purchase_price") }}
                <span class="product-detail__question-mark">
                  <md-icon>help_outline</md-icon>
                  <md-tooltip md-direction="top">{{ $translate("products.purchase_price_message") }}</md-tooltip>
                </span>
              </label>
              <span v-if="is_currency_symbol(purchase_currency_sign)" class="md-prefix">{{ purchase_currency_sign }}</span>
              <md-input
                data-cy="purchase-price"
                :disabled="!permissions.products.manage || show_item_preview"
                type="number"
                :value="get_correct_translation_value(variants[0], 'purchase_price', languages, false) || 0"
                @change="event => update_variant(0, 'purchase_price', Number(event.target.value))"
              />
              <span v-if="!is_currency_symbol(purchase_currency_sign)" class="md-suffix">{{ purchase_currency_sign }}</span>
            </md-field>
          </div>
        </div>
      </div>
    </div>

    <!-- For product with variants -->

    <div v-else-if="render_variants">
      <p class="md-title">{{ $translate("products.variants.title") }}:</p>
      <p v-if="!show_item_preview" class="md-body-1">{{ $translate("products.variants.message") }}</p>
      <md-table
        v-model="variants"
        class="md-elevation-3 admin-product-variants__variants"
      >
        <md-table-toolbar v-if="!show_item_preview">
          <md-button
            v-if="permissions.products.manage"
            class="md-icon-button md-raised md-primary admin-product-variants__variants__add admin-product-variants__variants__options"
            @click="edit_options(false)"
          >
            <md-icon>tune</md-icon>
          </md-button>

          <md-button
            v-if="permissions.products.manage"
            class="md-icon-button md-raised md-primary admin-product-variants__variants__add"
            @click="add_variant"
          >
            <md-icon>add</md-icon>
            <md-tooltip md-direction="top">{{ $translate("products.variants.add_new_variant") }}</md-tooltip>
          </md-button>
        </md-table-toolbar>

        <md-table-row
          slot="md-table-row"
          slot-scope="{ item, index }"
          :class="{ 'admin-product-variants__variants--deleted': item.change_type === deleted }"
          class="admin-product-variants__variants-row"
        >
          <md-table-cell :md-label="$translate('products.image')">
            <img :src="get_variant_image(item)" @click="item.change_type !== deleted && changing_variant_image(item, index)">
          </md-table-cell>

          <!-- Name -->
          <md-table-cell
            v-for="(option, ind) in product_data.options"
            :key="`selected-option-${ind}`"
            :md-label="get_correct_translation_value(option, 'name', languages)"
          >
            <md-field md-inline>
              <md-input
                data-cy="variant-title"
                :disabled="!permissions.products.manage || show_item_preview || item.change_type === deleted"
                :value="get_correct_translation_value(item, `option${ind + 1}`, languages, false)"
                @change="event => {
                  update_option_values([
                    products_type,
                    product_data.id,
                    ind,
                    event.target.value,
                    index,
                    current_language_for_translations
                  ])
                  update_variant(index, `option${ind + 1}`, event.target.value, ind)
                }"
              />
            </md-field>
          </md-table-cell>

          <!-- Price -->
          <md-table-cell
            :md-label="$translate('products.price') + '*'"
            md-sort-by="price"
            :class="{ 'admin-product-variants__variants-price': show_price_history(item) }"
          >
            <md-field>
              <span v-if="is_currency_symbol(currency)" class="md-prefix">{{ currency }}</span>
              <md-input
                data-cy="variant-price"
                :disabled="!permissions.products.manage || show_item_preview || item.change_type === deleted || is_cross_sold_product"
                type="number"
                :value="get_correct_translation_value(item, 'price', languages, false)"
                @change="event => update_variant(index, 'price', Number(event.target.value))"
              />
              <span v-if="!is_currency_symbol(currency)" class="md-suffix">{{ currency }}</span>
            </md-field>
            <md-button
              v-if="show_price_history(item)"
              class="md-icon-button"
              @click="selected_variant_price_history = selected_variant_price_history === index ? null : index"
            >
              <md-icon>timeline</md-icon>
              <md-tooltip md-direction="top">{{ $translate("products.variants.price_history") }}</md-tooltip>
            </md-button>
          </md-table-cell>

          <!-- Compare price -->
          <md-table-cell :md-label="$translate('products.compare_at_price')" md-sort-by="compareAtPrice">
            <md-field md-inline>
              <span v-if="is_currency_symbol(currency)" class="md-prefix">{{ currency }}</span>
              <md-input
                data-cy="variant-price-before-discount"
                :disabled="!permissions.products.manage || show_item_preview || item.change_type === deleted || is_cross_sold_product"
                type="number"
                :value="get_correct_translation_value(item, 'compare_at_price', languages, false)"
                @change="event => update_variant(index, 'compare_at_price', Number(event.target.value))"
              />
              <span v-if="!is_currency_symbol(currency)" class="md-suffix">{{ currency }}</span>
            </md-field>
          </md-table-cell>

          <!-- SKU -->
          <md-table-cell :md-label="$translate('products.sku')" md-sort-by="sku">
            <md-field md-inline>
              <md-input
                data-cy="variant-sku"
                :disabled="!permissions.products.manage || show_item_preview || item.change_type === deleted || is_cross_sold_product"
                :value="item.sku"
                @change="event => update_variant(index, 'sku', event.target.value)"
              />
            </md-field>
          </md-table-cell>

          <!-- Quantity -->
          <md-table-cell :md-label="$translate('products.quantity')">
            <md-field md-inline>
              <md-input
                data-cy="variant-total-inventory"
                :disabled="!permissions.products.manage || show_item_preview || item.change_type === deleted || is_cross_sold_product"
                type="number"
                :value="item.inventory_quantity"
                @change="event => update_variant_quantity(index, event.target.value)"
              />
            </md-field>
          </md-table-cell>

          <!-- Purchase price -->
          <md-table-cell v-if="!is_shopify_product && !is_cross_sold_product" :md-label="$translate('products.purchase_price')">
            <md-field md-inline class="product-detail__question-mark-input">
              <span v-if="is_currency_symbol(purchase_currency_sign)" class="md-prefix">{{ purchase_currency_sign }}</span>
              <md-input
                data-cy="variant-purchase-price"
                :disabled="!permissions.products.manage || show_item_preview || item.change_type === deleted"
                type="number"
                :value="get_correct_translation_value(item, 'purchase_price', languages, false) || 0"
                @change="event => update_variant(index, 'purchase_price', Number(event.target.value))"
              />
              <span v-if="!is_currency_symbol(purchase_currency_sign)" class="md-suffix">{{ purchase_currency_sign }}</span>
            </md-field>
            <span>
              <md-icon>help_outline</md-icon>
              <md-tooltip md-direction="top">{{ $translate("products.purchase_price_message") }}</md-tooltip>
            </span>
          </md-table-cell>

          <!-- Edit buttons -->
          <md-table-cell v-if="!is_cross_sold_product && !show_item_preview">
            <md-button
              v-if="permissions.products.manage && requires_shipping"
              class="md-icon-button md-raised md-primary"
              @click="select_variant_and_edit_dimensions(index)"
            >
              <md-icon>settings_overscan</md-icon>
              <md-tooltip md-direction="top">{{ $translate("products.variants.edit_variant_dimensions") }}</md-tooltip>
            </md-button>

            <md-button
              v-if="permissions.products.manage"
              class="md-icon-button md-raised"
              @click="item.change_type === deleted ? restore_variant(index) : delete_variant(index)"
              :class="{
                'md-primary': item.change_type === deleted,
                'md-accent': item.change_type !== deleted
              }"
            >
              <md-icon>{{ item.change_type === deleted ? "restore" : "delete"}}</md-icon>
              <md-tooltip md-direction="top">{{ $translate(item.change_type === deleted ? "restore" : "delete") }}</md-tooltip>
            </md-button>
          </md-table-cell>
        </md-table-row>
      </md-table>
    </div>

    <br/>
    <div v-if="!show_item_preview">{{ $translate("products.required_fields") }}</div>

    <md-dialog
      :md-active.sync="open_variants_modal"
      :md-click-outside-to-close="false"
      class="admin-product-variants__variants__modal"
    >
      <md-dialog-title>
        {{
          editing_options ?
            $translate("products.variants.edit_variant_options") :
            $translate("products.variants.choose_variant_image")
        }}:
      </md-dialog-title>

      <md-dialog-content>
        <div v-if="editing_options">
            <div
              v-for="(option, ind) in product_data.options"
              :key="`${ind}-option-variant`"
              class="admin-product-variants__variants__option"
            >
              <md-field md-inline>
                <md-input
                  :disabled="!permissions.products.manage || show_item_preview"
                  :value="get_correct_option_name(option)"
                  :placeholder="$translate('products.variants.add_option_placeholder')"
                  @change="event => edit_options(true, ind, 'update', event.target.value, current_language_for_translations)"
                />
              </md-field>
              <md-button
                v-if="permissions.products.manage && (ind ? true : get_correct_option_name(option))"
                class="md-icon-button md-raised md-accent"
                @click="edit_options(true, ind, 'delete')"
              >
                <md-icon>delete</md-icon>
                <md-tooltip md-direction="top">{{ $translate("delete") }} {{ $translate("products.option") }}</md-tooltip>
              </md-button>
            </div>
          <md-button
            v-if="(!product_data.options.length || product_data.options.length < 3) && permissions.products.manage"
            class="md-primary md-raised"
            @click="add_option"
          >+ {{ $translate("products.variants.add_new_variant_option") }}</md-button>
        </div>
        <div
          v-else
          v-for="({ id, src, thumb_src }) in images"
          :key="`${id}-image-variant`"
          class="admin-product-variants__variants__image-selector"
          :class="{
            'md-elevation-6': selected_variant_image !== id,
            'admin-product-variants__variants__image-selector--selected': selected_variant_image === id,
          }"
          @click="selected_variant_image = id"
        >
          <img
            :src="get_image_src({ src, thumb_src })"
            crossOrigin="anonymous"
          >
        </div>
      </md-dialog-content>

      <md-dialog-actions>
        <md-button
          v-if="!editing_options"
          class="md-raised"
          @click="clear_selecting_variant_image"
        >{{ $translate("cancel") }}</md-button>
        <md-button
          v-if="permissions.products.manage"
          class="md-primary md-raised"
          @click="editing_options ? add_option(false) : update_edited_variant_image()"
        >
          {{ editing_options ? $translate("done") : $translate("select") }}
        </md-button>
      </md-dialog-actions>
    </md-dialog>

    <md-dialog :md-active="selected_variant_price_history !== null" @md-closed="selected_variant_price_history = null">
      <md-dialog-title>{{ $translate("products.variants.price_history") }}</md-dialog-title>

      <md-dialog-content>
        <div v-if="variant_price_history_data_for_language.length > 1" class="admin-product-variants__variants-price-history">
          <canvas ref="variant_price_history"/>
        </div>
        <p v-else-if="variant_price_history_data_for_language.length">{{ $translate("products.variants.price_history_data_unchanged") }}</p>
        <p v-else>{{ $translate("products.variants.price_history_language_data_missing") }}</p>
      </md-dialog-content>

      <md-dialog-actions>
        <md-button class="md-primary" @click="selected_variant_price_history = null">{{ $translate("close") }}</md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex"
import { v4 as uuid } from "uuid"
import { CLIENT_STORE } from "../../../../Client/constants/other"
import get_correct_translation_value from "../../../../Shared/methods/get_correct_translation_value"
import is_currency_symbol from "../../../../Shared/methods/is_currency_symbol"
import un_bind from "../../../../Shared/methods/un_bind"
import empty_variant_object from "../../../constants/empty_objects/empty_variant_object"
import {
  ADMIN, CONFIGURATION_STORE, created, default_option_name, default_option_title,
  deleted, imported, PRODUCTS_STORE, USER_STORE
} from "../../../constants/others_constants"
import get_image_src from "../../../../Shared/methods/get_image_src"
import { IMPORTED_DATA_UPDATED, SHOPIFY_DATA_UPDATED } from "../../../stores/Admin/constants"
import validate from "../../../methods/validations/product_detail"
import { UPDATE_IMAGES, UPDATE_OPTIONS, UPDATE_OPTION_VALUES, UPDATE_VARIANTS } from "../../../stores/Admin/products/constants"
import { default_image_src, SHARED_STORE } from "../../../../Shared/constants/other"
import { image_dimension_names } from "../../../../../data/other_constants"
import empty_option_object from "../../../constants/empty_objects/empty_option_object"
import init_chart from "../../../methods/init_chart"

export default {
  props: {
    currency: {
      type: String,
      required: true
    },
    currency_code: {
      type: String,
      required: true
    },
    purchase_currency_sign: {
      type: String,
      required: true
    },
    is_product_without_variants: {
      type: Boolean,
      required: true
    },
    languages: {
      type: Array,
      required: true
    },
    is_shopify_product: {
      type: Boolean,
      required: true
    },
    render_variants: {
      type: Boolean,
      required: true
    },
    product_data: {
      type: Object,
      required: true
    },
    component_fully_rendered: {
      type: Boolean,
      required: true
    },
    products_type: {
      type: String,
      required: true
    },
    current_language_for_translations: {
      type: String,
      required: true
    },
    selected_language: {
      type: String,
      required: true
    },
    requires_shipping: {
      type: Boolean,
      required: true
    },
    selected_distance_unit: {
      type: String,
      required: true
    },
    update_variant: {
      type: Function,
      required: true
    },
    selected_weight_unit: {
      type: String,
      required: true
    },
    show_item_preview: {
      type: Boolean,
      required: true
    },
  },
  data() {
    return {
      selected_variant_price_history: null,
      open_variants_modal: false,
      editing_options: false,
      selected_variant_image: null,
      selected_variant: {},
      deleted,
      imported,
    }
  },
  computed: {
    ...mapState(CLIENT_STORE, ["project_styles"]),
    ...mapState(USER_STORE, ["permissions", "user_info"]),
    ...mapState(CONFIGURATION_STORE, ["translations"]),
    ...mapState(ADMIN, ["$moment"]),
    ...mapState(SHARED_STORE, ["currency_rates"]),
    all_available_languages() {
      return Object.keys(this.translations)
    },
    variant_price_history_data_for_language() {
      const correct_price_history = this.variants?.[this.selected_variant_price_history]?.price_history

      if (
        this.selected_variant_price_history !== null &&
        correct_price_history
      ) return Object
        .entries(correct_price_history)
        .filter(([_, prices]) => prices[this.selected_language])

      return []
    },
    images() {
      return (this.product_data.images || [])
    },
    variants: {
      get() {
        return this.product_data.variants || []
      },
      set(new_variants) {
        if(!new_variants || !this.component_fully_rendered) return

        this.update_variants([
          this.products_type,
          this.product_data.id,
          new_variants,
          this.current_language_for_translations
        ])
        this.product_data_updated()
        this.$nextTick(() => this.validate())
      }
    },
    is_cross_sold_product() {
      return Boolean(this.product_data.resale_percentage && this.product_data.project_id)
    },
  },
  watch: {
    selected_variant_price_history() {
      this.$nextTick(() => this.init_variant_history_chart())
    }
  },
  methods: {
    ...mapMutations(ADMIN, {
      imported_data_updated: IMPORTED_DATA_UPDATED,
      shopify_data_updated: SHOPIFY_DATA_UPDATED
    }),
    ...mapMutations(PRODUCTS_STORE, {
      update_variants: UPDATE_VARIANTS,
      update_options: UPDATE_OPTIONS,
      update_option_values: UPDATE_OPTION_VALUES,
      update_images: UPDATE_IMAGES
    }),
    ...validate,
    get_correct_translation_value,
    is_currency_symbol,
    init_chart,
    get_image_src,
    get_correct_option_name(option) {
      const correct_option_name = get_correct_translation_value(option, "name", [this.selected_language], false)

      return correct_option_name === default_option_title ? '' : correct_option_name
    },
    product_data_updated() {
      this[`${this.products_type}_data_updated`](true)
    },
    init_variant_history_chart() {
      if (this.variant_price_history_data_for_language.length) {
        this.init_chart(
          this.$refs.variant_price_history,
          this.variant_price_history_data_for_language.map(([key]) => key),
          this.variant_price_history_data_for_language.map(([_, prices]) => prices[this.selected_language].value),
          "variant_price_history",
          `${this.$translate("products.price")} (${this.variant_price_history_data_for_language[0][1][this.selected_language].currency})`,
          "line"
        )
      }
    },
    show_price_history(variant) {
      if (!variant.price_history) return false

      const history_data_per_language = Object.values(variant.price_history).reduce((tot, language_values) => {
        let number_of_languages = Object.keys(language_values).reduce((tot_lang, lang) => ({
          ...tot_lang,
          [lang]: [...(tot_lang[lang] || []), lang]
        }), {})

        Object
          .entries(number_of_languages)
          .forEach(([key, values]) => tot[key] ? number_of_languages[key] = [...values, ...tot[key]] : null)

        return number_of_languages
      }, {})

      return (history_data_per_language[this.selected_language] || []).length > 1
    },
    select_variant_and_edit_dimensions(index) {
      this.$emit("toggle_variant_dimensions_modal")
      this.$emit("switch_selected_variant_index", index)
    },
    /**
     * 
     * 
     * Variant editing
     * 
     */
    add_variant() {
      this.variants = [
        ...this.variants,
        un_bind(empty_variant_object(
          this.products_type === imported ? uuid() : false,
          this.variants[0],
          this.all_available_languages,
          this.requires_shipping
        ))
      ]
    },
    delete_variant(variant_ind) {
      let temp_variants
      const is_variant_newly_created = this.variants.find(
        (_, ind) => variant_ind === ind
      ).change_type === created

      // Delete all option values
      const { option1, option2, option3 } = this.variants[variant_ind]

      Array.from([option1,  option2,  option3]).forEach((opt, ind) => {
        if(opt !== null && opt !== undefined) this.update_option_values([
          this.products_type,
          this.product_data.id,
          ind,
          `option${ind + 1}`,
          variant_ind,
          this.current_language_for_translations,
          true
        ])
      })

      // Remove variant if newly created or would resolve in one variant remaining (so that the remaining variant gets reset below)
      if (
        is_variant_newly_created ||
        this.variants.length < 3
      ) temp_variants = this.variants.filter((_, ind) => variant_ind !== ind)
      else {
        temp_variants = this.variants.map((variant, ind) => {
          if (variant_ind === ind) variant.change_type = deleted
          return variant
        })
      }

      /**
       * If only one variant is remaining, reset the whole product to having only one default variant
       */
      if (temp_variants.length === 1 && temp_variants[0].translations) {
        Object
          .keys(temp_variants[0].translations)
          .forEach(lang => this.update_options([
            this.products_type,
            this.product_data.id, 
            [empty_option_object(this.all_available_languages)],
            lang
          ]))
        temp_variants = [{
          ...temp_variants[0],
          translations: Object
            .entries(temp_variants[0].translations)
            .reduce((tot, [lang, val]) => ({
              ...tot,
              [lang]: {
                price: val.price,
                compare_at_price: val.compare_at_price
              }
            }), {})
        }]
      }
      
      this.variants = temp_variants
    },
    restore_variant(variant_ind) {
      const temp_variants = this.variants.map((variant, ind) => {
        if (variant_ind === ind) variant.change_type = undefined
        return variant
      })

      // Delete all option values
      const { option1, option2, option3 } = this.variants[variant_ind]

      Array.from([option1,  option2,  option3]).forEach((opt, ind) => {
        if(opt !== null && opt !== undefined) this.update_option_values([
          this.products_type,
          this.product_data.id,
          ind,
          opt,
          variant_ind,
          this.current_language_for_translations
        ])
      })
      
      this.variants = temp_variants
    },
    update_variant_quantity(variant_index, value) {
      this.update_variant(variant_index, "inventory_quantity", Number(value))
      this.update_variant(variant_index, "update_quantity", true)
    },
    get_variant_image({ image_id }) {
      const correct_image = ((this.product_data.images || []).find(
        ({ id }) => image_id === id) || { src: default_image_src }
      )

      return correct_image.src?.[image_dimension_names.mobile] || correct_image.src
    },
    changing_variant_image(item, index) {
      this.selected_variant = { ...item, index }
      this.selected_variant_image = item.image_id
      this.open_variants_modal = true
    },
    clear_selecting_variant_image() {
      this.open_variants_modal = false
      this.selected_variant = {}
      this.selected_variant_image = null
    },
    /**
     * 
     */
    update_edited_variant_image() {
      if (!this.selected_variant_image) return

      this.update_variant(this.selected_variant.index, "image_id", this.selected_variant_image)
      this.update_images([
        this.products_type,
        this.product_data.id,
        this.images.map(image => {
          if (image.id === this.selected_variant_image) image.variant_ids = [
            ...(image.variant_id|| []),
            this.selected_variant.id
          ]

          return image
        })
      ])
      this.product_data_updated()
      this.clear_selecting_variant_image()
    },
    /**
     * 
     * 
     * Options editing
     * 
     */
    add_option(is_function_call = true) { // TODO fix adding new option and its values
      if (is_function_call) {
        this.update_options([
          this.products_type,
          this.product_data.id,
          [...this.product_data.options, empty_option_object(this.all_available_languages)],
          this.current_language_for_translations
        ])
        this.product_data_updated()
      }
      else {
        this.open_variants_modal = !this.open_variants_modal
        this.editing_options = !this.editing_options
      }
    },
    edit_options(is_function_call, ind, change_type, value) {
      if (is_function_call) {
        let temp_options = un_bind(this.product_data.options)

        // For updating option name
        if (change_type === "update") {
          if (this.current_language_for_translations) {
            if (!temp_options[ind].translations) temp_options[ind].translations = {
              [this.current_language_for_translations]: {}
            }
            if (
              !temp_options[ind].translations[this.current_language_for_translations]
            ) temp_options[ind].translations[this.current_language_for_translations] = {}

            temp_options[ind].translations[this.current_language_for_translations].name = value
          } else temp_options[ind].name = value
        }
        // For deleting option entirely
        else {
          // Check if only 1 option and assign default options data (results in product without options and variants)
          if (temp_options.length === 1) {
            temp_options = [{ name: default_option_title, values: [default_option_name] }]

            this.variants = this.variants.map(variant => {
              if (this.current_language_for_translations && variant.translations) {
                variant.translations[`option${ind + 1}`] = undefined
              } else variant[`option${ind + 1}`] = undefined

              return variant
            })
            this.variants = [{
              ...this.variants[0],
              option1: default_option_name,
              option2: undefined,
              option3: undefined,
              ...(this.variants[0].translations && {
                translations: {
                  ...this.variants[0].translations,
                  ...this.all_available_languages.reduce((tot, key) => ({
                    ...tot,
                    [key]: {
                      ...this.variants[0].translations[key],
                      option1: default_option_name,
                      option2: undefined,
                      option3: undefined,
                    }
                  }))
                }
              })
            }]
            this.open_variants_modal = false
          }
          else {
            temp_options.splice(ind, 1)

            this.variants = this.variants.map(variant => {
              if (variant.translations) {
                variant.translations = Object.entries(variant.translations).reduce((tot, [lang, variant_value]) => ({
                  ...tot,
                  [lang]: {
                    ...variant_value,
                    option1: ind === 0 ? variant_value.option2 : variant_value.option1,
                    option2: ind === 0 ? variant_value.option3 : ind === 1 ? variant_value.option3 : variant_value.option2,
                    option3: undefined
                  }
                }), {})
              } else {
                variant.option1 = ind === 0 ? variant.option2 : variant.option1,
                variant.option2 = ind === 0 ? variant.option3 : ind === 1 ? variant.option3 : variant.option2,
                variant.option3 = undefined
              }

              return variant
            })
          }
        }

        this.update_options([
          this.products_type,
          this.product_data.id,
          temp_options,
          this.current_language_for_translations
        ])
        this.product_data_updated()
      }
      else {
        this.open_variants_modal = !this.open_variants_modal
        this.editing_options = !this.editing_options
      }
    },
  }
}
</script>

<style lang="scss">
  @use "../../../../../styles/_global-constants" as *;
  @use "../../../../../styles/_admin-constants" as *;

  .admin-product-variants {
    .md-field input {
      font-size: 15px !important;
    }

    &__single-variant {
      .md-field {
        width: calc(100% - #{$default-size}) !important;
        max-width: 400px;
      }
    }

    &__variant {
      .md-prefix {
        display: inline-block !important;
      }

      input.md-input {
        display: inline-block;
        font-size: 15px !important;
        width: calc(100% - #{$default-size}) !important; // to include currency sign
      }

      .admin-product-variants__variants-price {
        display: inline-flex;
      }

      @media (max-width: $tablet--small) {
        .md-layout {
          display: grid !important;
          row-gap: $half-default-size !important;
          text-align: center;

          input {
            text-align: center;
          }
        }
      }
    }

    &__variants {
      @media (max-width: $tablet--small) {
        padding: 0 !important;
      }

      &__option {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: $half-default-size 0;
        border-bottom: 1px solid $light-grey;
      }

      &__image-selector {
        $image-size: 150px;
        $mobile-image-size: 100px;

        margin: 0 $half-default-size $half-default-size 0;
        max-width: $image-size;
        max-height: $image-size;
        overflow: hidden;
        cursor: pointer;
        float: left;
        border-radius: $border-radius;

        @media (max-width: $tablet--small) {
          max-width: $mobile-image-size;
          max-height: $mobile-image-size;
        }

        img {
          position: relative;
          z-index: -1;
          object-fit: contain;
          object-position: center;
          max-height: $image-size;
          max-width: $image-size;

          @media (max-width: $tablet--small) {
            max-width: $mobile-image-size;
            max-height: $mobile-image-size;
          }
        }
        
        &--selected {
          box-shadow: $inset-shadow-color;
        }
      }

      img {
        cursor: pointer;
        width: 50px;
        height: 50px;
        object-fit: contain;
      }

      &-price {
        align-items: baseline;

        .md-field {
          display: inline-flex !important;
          width: calc(100% - 56px) !important;
        }
      }

      &__add {
        position: absolute !important;
        right: 20px !important;
        top: 10px !important;
      }

      &__options
       {
        right: 70px !important;

        .md-fab {
          width: $icon-size;
          height: $icon-size;
        }

        .md-speed-dial-content {
          display: block;
          flex-direction: column;
          align-items: center;
          position: absolute;
          width: 90px;
          height: $icon-size;
          top: 0;
          left: -90px;

          .md-button {
            margin: 0;
          }
        }
      }

      &__modal {
        .md-table-cell-container {
          padding-left: 0;
        }
        .md-table-cell:last-child .md-table-cell-container{
          padding-right: 0;
        }

        .md-field {
          min-height: 32px;
          padding: 0;
          margin: 0;
        }
      }

      &--deleted {
        background-color: $material-red;
        opacity: 0.8;
      }

      .md-field {
        display: flex !important;
        width: 100% !important;
        padding: 0;
        min-height: 30px;
        align-items: baseline !important;

        span {
          display: inline-block !important;
        }
      }

      .md-field input {
        display: inline-block;
        font-size: 13px !important;
      }

      .md-field span + input {
        width: calc(100% - 17px);
      }

      .md-table-cell-container {
        display: flex;
        padding: $material-size--small 15px $material-size--small;
        align-items: center;
        justify-content: center;

        @media (max-width: $tablet--small) {
          padding: 0 $half-default-size 0 0;
        }
      }

      .md-table-row td {
        height: 70px;
        padding: 0;
      }
      
      .md-table-head-container {
        height: auto;
        margin-top: $half-default-size;
        text-align: center;

        &.md-ripple {
          padding: 0;
        }
      }

      .md-table-head-label {
        padding: 0 $default-size;
        min-width: 100px;

        @media (max-width: $tablet--small) {
          padding: 0 $half-default-size;
        }
      }
    }
  }
</style>
