import { search_attributes } from "../../Marketplace/constants/other"
import create_correct_product_url from "../../Client/methods/create_correct_product_url"

export const search_function = (attribute_value, term) => {
  term = term.toLowerCase()

  if (!attribute_value) return
  
  // If type string
  if (typeof attribute_value === "string") return attribute_value.toLowerCase().includes(term) ||
    attribute_value.toLowerCase() === term

  // If type number
  if (typeof attribute_value === "number") return String(attribute_value).toLowerCase().includes(term) ||
    String(attribute_value).toLowerCase() === term

  // If type object
  if (typeof attribute_value === "object") {
    // If Array
    if (attribute_value.map) {
      return attribute_value.map(item => item.toLowerCase ?
        item.toLowerCase() : item).includes(term) ||
        attribute_value.some(item => search_function(item, term)
      )
    }
    // If Object
    else return Object.values(attribute_value).some(val => search_function(val, term))
  }
}

export default (products, term) => products.filter(
  product => search_attributes.some(attribute => search_function(product[attribute], term))
).map(product => ({ ...product, link: create_correct_product_url(product) }))
