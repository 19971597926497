var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.is_selector_enabled)?_c(_vm.display_icon_button ? 'md-button' : 'div',{tag:"component",staticClass:"product-favorite",class:{
    'product-favorite--selected': _vm.is_product_favorite,
    'product-favorite--large': _vm.large,
    'product-favorite--list-item': _vm.inside_list_item,
    'md-icon-button md-raised': _vm.display_icon_button,
    'product-favorite--align-top': _vm.align_top,
  },on:{"click":_vm.update_favorite_rating}},[_c('md-icon',[_vm._v(_vm._s(_vm.is_product_favorite ? "star" : "star_border"))]),_c('md-tooltip',{attrs:{"md-direction":"top"}},[_vm._v(_vm._s(_vm.$translate("products.favorite_product_message")))])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }