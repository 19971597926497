<template>
  <transition name="slide-save-button">
    <div v-if="show" class="save-button" :class="{ 'save-button--in-product': product_display }">
      <md-speed-dial md-direction="bottom">
        <md-speed-dial-target class="md-success" data-cy="save-changes" @click="$emit('save')">
          <md-tooltip v-html="$translate('save_changes', { section })" md-direction="right" />
          <md-icon>save</md-icon>
        </md-speed-dial-target>

        <md-speed-dial-content>
          <md-button
            data-cy="discard-changes"
            class="md-icon-button md-accent"
            @click="$emit('cancel')"
          >
            <md-icon>close</md-icon>
            <md-tooltip md-direction="right">{{ $translate("discard_changes") }}</md-tooltip>
          </md-button>
        </md-speed-dial-content>
      </md-speed-dial>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: false,
      default: true
    },
    section: {
      type: String,
      required: true,
      default: ""
    },
    product_display: {
      type: Boolean,
      required: false,
      default: false
    },
  },
}
</script>

<style lang="scss">
  @use "../../../../styles/_global-constants" as *;
  @use "../../../../styles/_admin-constants" as *;

  .save-button {
    position: fixed;
    top: #{$admin-top-bar-height + $half-default-size};;
    left: calc(50% - #{$material-button / 2});
    font-weight: bold;
    z-index: $z-index--middle;
    text-align: center;
    transition: .2s ease-in-out;

    @media (max-width: $tablet--small) {
      position: fixed;
      top: #{$admin-top-bar-height--mobile + $default-size};
    }

    button {
      display: inline-block;
      vertical-align: middle;
      box-shadow: 0 0 30px 2px $shadow-color !important;
      cursor: pointer !important;
    }

    &__message {
      padding-right: $half-default-size;
      font-weight: 500;
      text-align: justify;

      @media (max-width: $tablet--small) {
        display: block;
        width: calc(100% - 35px);
      }
    }

    &--in-product {
      position: relative;
      left: auto;
      top: auto;
      display: inline-block;
      vertical-align: top;
      margin-left: $default-size;
    }
  }

  .slide-save-button-enter-active, .slide-save-button-leave-active {
    transition: .5s;
  }
  .slide-save-button-enter, .slide-save-button-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translateY(-20px);
  }
</style>
