export default (amount, currency_to, rates, converting_to_passed_currency = true) => Number((
  amount *
  // If amount and currency_to are in the same currency
  (converting_to_passed_currency ?
    (1 / rates[currency_to]) :
    rates[currency_to])
).toFixed(2))

/**
 * 
 * Examples of how this function should be used:
 * 
 * 1. I have 300 CZK and want to convert them to USD.
 * So I fetch USD rates and pass everything into the function like so:
 * 
 * convert_currency(
 *   300,
 *   "CZK",
 *   { CZK: 22.5, ... }, // USD rates
 *   true // I am converting to currency whose rates are being passed (USD).
 * )
 * 
 * The results then will be USD 13.3
 * 
 * 
 * 2. I have multiple conversion from CZK to other currencies to do.
 * So I fetch CZK rates and pass each amount in CZK to be converted with the currency it should be converted to into the function like so:
 * 
 * convert_currency(
 *   300,
 *   "EUR",
 *   { USD: 0.044659, EUR: 0.040556, ... }, // CZK rates
 *   false // I am not converting to currency whose rates are being passed (CZK).
 * )
 * 
 * The results then will be EUR 12.1
 * 
 */
