<template functional>
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 701.75 702.48" class="ulsemo-icon">
    <path class="cls-1" d="M1350.88,808.9a159.44,159.44,0,0,1-13.68,64.91l-.72,1.69A160.45,160.45,0,0,1,1191.07,969c-88.12,0-159.81-71.84-159.81-160.13a160.55,160.55,0,0,1,94.44-146.22,158.62,158.62,0,0,1,65.37-13.92C1279.19,648.76,1350.88,720.6,1350.88,808.9Z" transform="translate(-649.12 -648.76)"/><rect class="cls-2" y="4.09" width="312.88" height="312.88"/><rect class="cls-3" y="4.09" width="312.88" height="312.88"/><rect class="cls-4" x="385.51" y="389.6" width="312.88" height="312.88"/><path class="cls-5" d="M962,1038.37v311.72c-163.62-17-294.06-147.73-311-311.72Z" transform="translate(-649.12 -648.76)"/>
  </svg>
</template>

<script>
export default {}
</script>

<style lang="scss">
  .ulsemo-icon {
    .cls-1{fill:#333;}
    .cls-2{fill:#c1272d;}
    .cls-3{fill:#fff;opacity:0.15;}
    .cls-4{fill:#3498db;}
    .cls-5{fill:#1abc9c;}
  }
</style>
