<template>
  <div>
    <div class="product-detail__dimensions md-title md-elevation-3 product-detail__variants">
      <div class="md-subheading">{{ $translate("products.weight") }}:</div>

      <md-field data-cy="weight-unit">
        <md-select v-model="weight_unit" name="weightUnit" id="weightUnit" :placeholder="$translate('products.inputs.weight_unit')">
          <md-option v-for="unit in weight_units" :key="`weight-unit-${unit}`" :value="unit">{{ unit }}</md-option>
        </md-select>
      </md-field>

      <md-field>
        <md-input
          data-cy="weight"
          :disabled="!permissions.products.manage || show_item_preview"
          :value="variants[selected_variant_index || 0].weight || 0"
          @change="event => update_variant(selected_variant_index, 'weight', normalize_value_to_decimals(event.target.value))"
        />
        <span class="md-suffix">{{ weight_unit }}</span>
      </md-field>
    </div>
    
    <div class="product-detail__dimensions md-elevation-3 product-detail__variants" v-if="show_distances">
      <div class="md-subheading">{{ $translate("products.size") }}:</div>

      <md-field data-cy="size-unit">
        <md-select v-model="distance_unit" name="distancetUnit" id="distancetUnit" :placeholder="$translate('products.inputs.distance_unit')">
          <md-option v-for="unit in distance_units" :key="`distance-unit-${unit}`" :value="unit">{{ unit }}</md-option>
        </md-select>
      </md-field>

      <md-field>
        <label>{{ $translate('products.length') }}</label>
        <md-input
          data-cy="variant-length"
          :disabled="!permissions.products.manage || show_item_preview"
          :value="variants[selected_variant_index || 0].length || ''"
          @change="event => update_variant(selected_variant_index, 'length', normalize_value_to_decimals(event.target.value))"
        />
        <span class="md-suffix">{{ distance_unit }}</span>
      </md-field>
      <md-field>
        <label>{{ $translate('products.width') }}</label>
        <md-input
          data-cy="width"
          :disabled="!permissions.products.manage || show_item_preview"
          :value="variants[selected_variant_index || 0].width || ''"
          @change="event => update_variant(selected_variant_index, 'width', normalize_value_to_decimals(event.target.value))"
        />
        <span class="md-suffix">{{ distance_unit }}</span>
      </md-field>
      <md-field>
        <label>{{ $translate('products.height') }}</label>
        <md-input
          data-cy="height"
          :disabled="!permissions.products.manage || show_item_preview"
          :value="variants[selected_variant_index || 0].height || ''"
          @change="event => update_variant(selected_variant_index, 'height', normalize_value_to_decimals(event.target.value))"
        />
        <span class="md-suffix">{{ distance_unit }}</span>
      </md-field>
    </div>
  </div>
</template>

<script>
import { weight_units, distance_units } from "../../../constants/units"

export default {
  props: {
    update_variant: {
      type: Function,
      required: true
    },
    permissions: {
      type: Object,
      required: true
    },
    variants: {
      type: Array,
      required: true
    },
    selected_distance_unit: {
      type: String,
      required: true
    },
    selected_weight_unit: {
      type: String,
      required: true
    },
    show_distances: {
      type: Boolean,
      required: true
    },
    selected_variant_index: {
      type: Number | undefined,
      required: false,
      default: undefined
    },
    show_item_preview: {
      type: Boolean,
      required: true
    }, 
  },
  data() {
    return {
      weight_units, distance_units
    }
  },
  computed: {
    distance_unit: {
      get() { return this.selected_distance_unit },
      set(unit) { this.$emit("update_distance_unit", unit) }
    },
    weight_unit: {
      get() { return this.selected_weight_unit },
      set(unit) { this.$emit("update_weight_unit", unit) }
    },
  },
  methods: {
    normalize_value_to_decimals(value) {
      return isNaN(value) ? 0 : Math.round((Number(value) + Number.EPSILON) * 10000) / 10000
    }
  }
}
</script>

<style>

</style>
