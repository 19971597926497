<template>
  <md-dialog
    :md-active="open_collection_edit_modal"
    @md-clicked-outside="clear_errors(); clear_uploaded_image()"
    class="product-collections__modal"
  >
    <md-dialog-title>
      {{ $translate(`products.${is_edited_collection_parent ? "parent_" : ""}collections.${edited_collection_index >= 0 ? "edit" : "add"}`) }}
    </md-dialog-title>

    <div v-if="is_edited_collection_parent && !edited_collection_children.length" >
      <p class="product-display__modal-info">
        {{ $translate("products.parent_collections.no_collection_message") }}
      </p>
      <md-button
        data-cy="done"
        class="md-raised md-primary"
        @click="close_modal"
      >{{ $translate("close") }}</md-button>
    </div>

    <div v-else>
      <md-field md-inline :class="{ 'md-invalid': collection_name_error }">
        <label>{{ is_edited_collection_parent ? $translate("products.parent_collections.name") : $translate("products.collections.name") }}</label>
        <md-input data-cy="collection-name" v-model="collection_name" />
        <span class="md-helper-text">{{ is_edited_collection_parent ?  $translate("products.parent_collections.explanation") : $translate("products.collections.explanation") }}</span>
        <span v-if="collection_name_error" class="md-error">{{ collection_name_error }}</span>
      </md-field>

      <div class="product-collections__extra-actions">
        <md-field :class="{ 'md-invalid': select_collections_error }">
          <label for="collection-modal-select">
            {{ is_edited_collection_parent ? $route.params.page_type : "" | capitalize }}
            {{ is_edited_collection_parent ? $translate("products.collections.title") : $translate("products.products") | capitalize }}
          </label>
          <md-select
            v-model="selected_collection_entities"
            :name="$translate('products.collections.title')"
            id="collection-modal-select"
            multiple
            :placeholder="is_edited_collection_parent ? $translate('products.collections.add_parent_collection_collections') : $translate('products.collections.add_collection_products')"
          >
            <md-option
              data-cy="imported-collections"
              v-for="(child, ind) in edited_collection_children"
              :key="`tab-${ind}-collection`"
              :id="`${ind}-tab`"
              :value="ind"
            >
              {{ get_correct_translation_value(child, "title", [user_info.language_code]) }}
            </md-option>
          </md-select>
          <span v-if="select_collections_error" class="md-error">{{ select_collections_error }}</span>
        </md-field>
        <div
          v-if="!is_edited_collection_parent"
          class="product-collections__image-upload"
          :class="{ 'product-collections__image-upload-empty': !edited_collection_image }"
        >
          <img v-if="edited_collection_image" :src="edited_collection_image">
          <div class="product-collections__image-upload-buttons">
            <label for="collection-image-upload">
              <i>{{ $translate(`products.images.${edited_collection_image  ? 'change_image' : 'add_collection_image'}`) }}</i>
            </label>
            <input
              data-cy="upload"
              type="file"
              id="collection-image-upload"
              accept=".jpg, .jpeg, .png, .webp"
              @drop="event => add_image(event.target.files[0])"
              @input="event => add_image(event.target.files[0])"
              v-cloak
            >
            <md-button
              v-if="edited_collection_image"
              class="md-raised md-accent"
              @click="remove_collection_image"
            >{{ $translate("remove") }}</md-button>
          </div>
        </div>
      </div>
      <div v-if="permissions.products.manage" class="product-collections__modal__buttons">
        <md-button
          class="md-raised"
          @click="close_modal"
        >{{$translate("cancel") }}</md-button>
        <md-button v-if="edited_collection_index >= 0" class="md-raised md-accent" @click="delete_collection">
          {{$translate("delete") }}
        </md-button>
        <md-button
          data-cy="done"
          class="md-raised md-primary"
          @click="update_collections"
          :disabled="!allow_collection_save"
        >{{ $translate("done") }}</md-button>
      </div>
    </div>
  </md-dialog>
</template>

<script>
import { mapState, mapMutations } from "vuex"
import {
  created, updated, deleted, added, PRODUCTS_STORE,
  ADMIN_STORE, USER_STORE, PRODUCTS
} from "../../../constants/others_constants"
import {
  UPDATE_PARENT_COLLECTION,
  UPDATE_COLLECTION,
  CREATE_COLLECTION,
  UPDATE_PRODUCT_COLLECTIONS,
  CLEAR_ERRORS_SECTION,
} from "../../../stores/Admin/products/constants"
import empty_collection_object from "../../../constants/empty_objects/empty_collection_object"
import {
  UPDATE_LOADER,
  UPDATE_IMAGES_FOR_DELETE,
  IMPORTED_DATA_UPDATED,
  SHOPIFY_DATA_UPDATED,
  SET_LOADING_MESSAGE,
} from "../../../stores/Admin/constants"
import upload_image from "../../../methods/upload_image"
import get_correct_translation_value from "../../../../Shared/methods/get_correct_translation_value"

export default {
  props: {
    is_edited_collection_parent: {
      type: Boolean,
      required: true
    },
    edited_collection_index: {
      type: Number | null,
      required: true
    },
    open_collection_edit_modal: {
      type: Boolean,
      required: true
    },
    edited_collection_children: {
      type: Array,
      required: true
    },
    get_parent_collections: {
      type: Array,
      required: true
    },
    get_all_products: {
      type: Array,
      required: true
    },
    get_correct_collections_data: {
      type: Array,
      required: true
    },
    get_collections: {
      type: Array,
      required: true
    },
    validate: {
      type: Function,
      required: true
    },
    encode_route: {
      type: Function,
      required: true
    },
    page_type: {
      type: String,
      required: true
    },
    edited_collection_name: {
      type: String,
      required: true
    },
    current_collection_index: {
      type: Number | null,
      required: true
    },
    determine_collection_switch: {
      type: Function,
      required: true
    },
    current_parent_collection_index: {
      type: Number | null,
      required: true
    },
    get_all_collections: {
      type: Array,
      required: true
    },
    collection_entities: {
      type: Array,
      required: true
    },
    collection_name_error: {
      type: String | null,
      required: true
    },
    select_collections_error: {
      type: String | null,
      required: true
    },
  },
  data() {
    return {
      uploaded_image: null,
    }
  },
  computed: {
    ...mapState(USER_STORE, [
      "permissions", "user_info",
    ]),
    collection_name: {
      get() { return this.edited_collection_name },
      set(val) { this.$emit("update_edited_collection_name", val) }
    },
    selected_collection_entities: {
      get() { return this.collection_entities },
      set(val) { this.$emit("update_entities_for_collection", val) }
    },
    allow_collection_save() {
      if (this.collection_name_error) return false
      // Check that parent collection name isnt being duplicated and has collections assigns
      if (this.is_edited_collection_parent)
        return !this.get_parent_collections
          .map(val => val.toLowerCase())
          .filter(val => val !== this.edited_collection_name?.toLowerCase()) // Dont include current collection name
          .includes(this.collection_name.toLowerCase()) &&
          this.collection_entities.length > 0

      return Boolean(this.collection_name)
    },
    edited_collection_image () {
      return this.get_collections?.[this.edited_collection_index]?.image || this.uploaded_image?.src || ""
    },
  },
  watch: {
    open_collection_edit_modal(new_val) {
      if (!new_val) {
        this.$emit("update_edited_collection_name", "")
        this.$emit("clear_collection_children")
        this.$emit("clear_edited_collection_parent")
        this.$emit("update_entities_for_collection", [])
        this.$emit("clear_edited_collection_index")
        this.$emit("clear_collection_name_error")
      }
    },
    collection_name(val) {
      if (val) this.validate(false)
    }
  },
  methods: {
    ...mapMutations(PRODUCTS_STORE, {
      update_parent_collection: UPDATE_PARENT_COLLECTION,
      update_collection: UPDATE_COLLECTION,
      create_collection: CREATE_COLLECTION,
      update_product_collections: UPDATE_PRODUCT_COLLECTIONS,
      clear_errors: CLEAR_ERRORS_SECTION,
    }),
    ...mapMutations(ADMIN_STORE, {
      update_loader: UPDATE_LOADER,
      image_delete_toggle: UPDATE_IMAGES_FOR_DELETE,
      imported_data_updated: IMPORTED_DATA_UPDATED,
      shopify_data_updated: SHOPIFY_DATA_UPDATED,
      set_loading_message: SET_LOADING_MESSAGE
    }),
    get_correct_translation_value,
    clear_uploaded_image(){
      this.uploaded_image = null;
    },
    close_modal() {
      this.clear_errors()
      this.clear_uploaded_image()
      this.$emit("toggle_collection_modal", false)
    },
    delete_collection() {
      const correct_children = this[this.is_edited_collection_parent ?  "get_correct_collections_data" : "get_all_products"]

      if (this.is_edited_collection_parent) {
        correct_children.forEach(({ body_html }, index) => {
          const contains_parent_collection = body_html === `{${this.encode_route(this.collection_name)}}`
          const new_body = contains_parent_collection ? "" : body_html

          this.update_parent_collection([
            this.page_type,
            index,
            new_body
          ])
        })
        this.$nextTick(() => this.$emit("update_current_collection_index", this.get_collections.length ? 0 : null))
      }
      else {
        const correct_collection = this.get_collections[this.edited_collection_index]
        const correct_collection_index_in_total_array = this.get_correct_collections_data.findIndex(
          ({ id }) => id === correct_collection.id
        );

        // Remove collection from all linked products
        (correct_collection.products || []).forEach(({ id }) => this.update_product_collections([
          this.page_type,
          id,
          correct_collection,
          false
        ]))

        this.update_collection([
          this.page_type,
          correct_collection_index_in_total_array,
          "change_type",
          deleted
        ])
        this.$nextTick(() => this.$emit("update_current_collection_index", this.get_collections.length ? 0 : null))
      }

      this[`${this.page_type}_data_updated`](true)
      this.$emit("toggle_collection_modal", false)
      this.$emit("update_collection_name", "")
      this.determine_collection_switch()
    },
    remove_collection_image() {
      if (!this.edited_collection_index) return this.uploaded_image = null

      const collection = this.get_correct_collections_data.findIndex(
        ({ id }) => id === this.get_collections[this.edited_collection_index].id
      )

      this.image_delete_toggle({
        images: [
          this.edited_collection_image
        ],
        store_type: PRODUCTS
      })
      this.update_collection([
        this.page_type,
        collection,
        "image",
        null
      ])

      this[`${this.page_type}_data_updated`](true)
    },
    add_image(value) {
      try {
        const reader = new FileReader()

        reader.addEventListener("load", () => {
          this.uploaded_image = {
            src: reader.result,
            file: value,
            altText: "image",
          }
        }, false)

        reader.readAsDataURL(value);

      } catch (error) {
        console.log(error)
      }
    },
    async save_collection_image(collection_index) {
      this.set_loading_message(this.$translate("loading_messages.uploading_collection_image"))
      this.update_loader(true)

      if (this.get_correct_collections_data[collection_index]?.image) this.image_delete_toggle({
        images: [
          this.get_correct_collections_data[collection_index]?.image
        ],
        store_type: PRODUCTS
      })

      const image_sources = await upload_image(
        this.uploaded_image,
        `${this.uploaded_image.file.name}/${this.get_correct_collections_data[collection_index]?.handle}`, // Collection handle
        false,
        false,
        false,
        true
      )

      if (!image_sources) this.clear_uploaded_image();
      else {
        this.update_collection([
          this.page_type,
          collection_index,
          "image",
          image_sources.tablet
        ])
      }

      setTimeout(() => {
        this.clear_uploaded_image();
        this.update_loader(false)
        this.set_loading_message("")
      }, 1000);
    },
    async update_collections() {
      const are_there_any_errors = await this.validate()

      if (!are_there_any_errors) return

      const correct_children = this[this.is_edited_collection_parent ?  "get_correct_collections_data" : "get_all_products"]

      if (this.is_edited_collection_parent) {
        // Parent collections dont need "change_type", since they are being added to existing collections
        // which have it already
        correct_children.forEach(({ body_html }, index) => {
          const is_in_selected = this.collection_entities.includes(index)
          const formatted_parent_collection_name = `{${this.encode_route(this.collection_name)}}`

          /**
           * If collection has an assigned parent collection then:
           *  - If its the same as this.collection_name, check if it is amongst selected collections and assing
           *    correct body
           *  - If its different, then just leave the old body
           */
          const new_body = is_in_selected ? formatted_parent_collection_name : (body_html === formatted_parent_collection_name ? "" : body_html)

          this.update_parent_collection([
            this.page_type,
            index,
            new_body
          ])
        })
        this.$nextTick(() => this.$emit("update_current_collection_index", this.get_collections.length ? 0 : null))

        // Updated collection in linked products
        correct_children.forEach((collection) => collection.products?.forEach(({ id }) => this.update_product_collections([
          this.page_type,
          id,
          collection
        ])))
      }
      else {
        let get_updated_products = correct_children
          .filter((_, index) => this.collection_entities.includes(index))
          .map(product => ({ ...product, collections: undefined })) // Setting collections to undefined ensures no "TypeError: Converting circular structure to JSON"

        // If not a new collection
        if (this.edited_collection_index > -1) {
          const correct_collection = this.get_collections[this.edited_collection_index]
          const change_type = correct_collection.change_type === created ? created : updated
          const correct_collection_index_in_total_array = this.get_correct_collections_data.findIndex(
            ({ id }) => id === correct_collection.id
          )

          if (this.uploaded_image) await this.save_collection_image(correct_collection_index_in_total_array)

          // Add collection_change_type to newly added products
          get_updated_products = get_updated_products.map(product => {
            if (
              !correct_collection.products || !correct_collection.products.some(({ id }) => id === product.id)
            ) product.collection_change_type = added
            return product
          })

          if (correct_collection.products) {
            // Remove collection from newly unlinked products
            correct_collection.products
              .filter(({ id }) => !get_updated_products.find(({ id: product_id }) => id === product_id))
              .forEach(({ id }) => this.update_product_collections([
                this.page_type,
                id,
                correct_collection,
                false
              ]))
          }

          this.update_collection([
            this.page_type,
            correct_collection_index_in_total_array,
            "change_type",
            change_type
          ])
          this.update_collection([
            this.page_type,
            correct_collection_index_in_total_array,
            "handle",
            this.encode_route(this.collection_name.toLowerCase())
          ])
          this.update_collection([
            this.page_type,
            correct_collection_index_in_total_array,
            "title",
            this.collection_name
          ])
          this.update_collection([
            this.page_type,
            correct_collection_index_in_total_array,
            "products",
            get_updated_products
          ])

          // Add collection to linked products
          get_updated_products.forEach(({ id }) => this.update_product_collections([
            this.page_type,
            id,
            correct_collection
          ]))
        }
        // If new collection
        else {
          const is_without_parent_collection =
            this.$translate("products.parent_collections.without_parent") === this.get_parent_collections[this.current_parent_collection_index]
            || !this.get_parent_collections[this.current_parent_collection_index]
          const new_collection = empty_collection_object(
            this.collection_name,
            this.encode_route(this.collection_name.toLowerCase()),
            is_without_parent_collection ?
              "" :
              `{${this.encode_route(this.get_parent_collections[this.current_parent_collection_index])}}`,
            get_updated_products,
            true
          )

          this.create_collection([this.page_type, new_collection])

          get_updated_products.forEach(({ id }) => this.update_product_collections([
            this.page_type,
            id,
            new_collection // Get last created collection
          ]))

          this.$nextTick(async () => {
            const correct_collection_index_in_total_array = this.get_correct_collections_data.findIndex(
              ({ id }) => id === new_collection.id
            )
            const collection_index_in_selected_collections = this.get_collections.findIndex(({ id }) => new_collection.id === id)

            if (this.uploaded_image) await this.save_collection_image(correct_collection_index_in_total_array)

            this.$emit("update_current_collection_index", collection_index_in_selected_collections)
          })
        }
      }
      
      this[`${this.page_type}_data_updated`](true)
      this.$emit("update_collection_name", "")
      this.$emit("toggle_collection_modal", false)
    },
  }
}
</script>

<style lang="scss">
  @use "../../../../../styles/_admin-constants" as *;
  @use "../../../../../styles/_global-constants" as *;

  .product-collections {
    display: inline-block;
    margin: 0 0 $default-size;

    .md-tabs, button, span {
      display: inline-flex;
      vertical-align: middle;
    }

    .md-title {
      text-align: center;
    }

    &__extra-actions {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: $default-size;
      align-items: center;
      margin-bottom: $default-size;

      .md-field {
        height: 48px;
      }
    }

    &__image-upload {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: $half-default-size;
      width: 100%;
      align-items: start;
      justify-content: center;

      &-empty {
        grid-template-columns: 1fr;

        .product-collections__image-upload-buttons {
          justify-content: center;
        }
      }

      &-buttons {
        display: grid;
        align-items: start;
        justify-content: left;

        .md-button {
          margin: $half-default-size 0 0;
        }
      }

      input[type="file"] {
        display: none;
      }

      label {
        border: 1px solid $material-grey--darker;
        display: inline-block;
        padding: 8px 12px;
        cursor: pointer;
        border-radius: 3px;
        transition: 0.2s;
        color: black;
        font-size: 15px;

        &:hover {
          background-color: $material-grey--darker;
          color: $pure-white;
        }

        i {
          font-style: normal;
        }
      }

      img {
        width: 100%;
        height: 100px !important;
        object-fit: contain;
        object-position: top right;
      }
    }

    &__modal {
      width: 100vw;
      max-width: 450px !important;
      padding: 0 $default-size $default-size;

      &__buttons {
        text-align: center;
      }

      .md-field.md-inline {
        margin: 4px 0 48px;
      }
    }
  }
</style>
