<template>
  <section class="video-tutorial">
    <md-button
      class="md-icon-button md-normal md-raised"
      @click="show_tutorial = true"
    >
      <md-icon>help</md-icon>
      <md-tooltip md-direction="top">{{ $translate("video_tutorial.button") }}</md-tooltip>
    </md-button>

    <md-dialog
      :md-active.sync="show_tutorial"
      @md-clicked-outside="show_tutorial = false"
      class="video-tutorial__modal"
    >
      <md-dialog-title>
        {{ $translate(`video_tutorial.${section}`) }}
      </md-dialog-title>

      <videoPlayer :options="player_options"/>
      <md-dialog-actions>
        <md-button
          data-cy="done"
          class="md-raised md-primary"
          @click="show_tutorial = false"
        >{{ $translate("close") }}</md-button>
      </md-dialog-actions>
    </md-dialog>
  </section>
</template>

<script>
import { videoPlayer } from "vue-video-player"
import { mapState } from "vuex"
import { ADMIN } from "../../constants/others_constants"
import "video.js/dist/video-js.css"

export default {
  components: {
    videoPlayer
  },
  props: {
    section: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      show_tutorial: false,
    }
  },
  computed: {
    ...mapState(ADMIN, ["open_menu"]),
    player_options() {
      return {
        language: "en",
        sources: [{
          type: "video/mp4",
          src: `/static/video/${this.section}.mp4`
        }],
      }
    },
  }
}
</script>

<style lang="scss">
  @use "../../../../styles/_global-constants" as *;
  @use "../../../../styles/_admin-constants" as *;

  .video-tutorial {
    float: right;

    &__modal {
      .video-js {
        width: 800px;
        height: 450px;

        @media (max-width: 800px) {
          width: calc(100vw - 20px);
          height: 300px;
        }

        .vjs-big-play-button {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
</style>
