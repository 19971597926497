<template>
  <div
    v-if="get_languages_to_show.length > 1 && !enable_mobile_view"
    class="language-switcher"
    :class="{ 'language-switcher--bottom-border': bottom_border }"
  >
    <p v-if="show_title">{{ $translate("available_languages") }}:</p>
    <div class="language-switcher__language-flags" :class="{ 'language-switcher__language-flags--inline': allow_only_enabled }">
      <span
        v-for="language in get_languages_to_show"
        :key="`language-flag-${language}`"
      >
        <md-tooltip
          v-if="allow_only_enabled && !enabled_languages.includes(language)"
          md-direction="top"
        >{{ $translate("enable_language_message") }}</md-tooltip>
        <md-tooltip
          v-if="missing_languages.includes(language)"
          md-direction="top"
        >{{ $translate("missing_language_values") }}</md-tooltip>
        <img
          :src="`/static/icons/countries/${language}.svg`"
          :alt="`${language} flag`"
          :data-cy="`${language}`"
          :class="{
            'language-switcher--disabled': allow_only_enabled && !enabled_languages.includes(language),
            'language-switcher--missing': missing_languages.includes(language),
            'language-switcher--selected': selected_language === language,
          }"
          :style="{
            width: sizes.width,
            height: sizes.height
          }"
          @click="language_change(language)"
        >
      </span>
    </div>
    <span v-if="!show_all_languages">
      <md-icon>help_outline</md-icon>
      <md-tooltip md-direction="top">{{ $translate("available_languages_message") }}</md-tooltip>
    </span>
  </div>
  <div v-else-if="get_languages_to_show.length > 1 && enable_mobile_view" class="language-switcher-mobile">
    <md-speed-dial md-direction="top" class="language-switcher__speed-dial">
      <md-speed-dial-target class="md-normal">
        <md-icon>language</md-icon>
      </md-speed-dial-target>

      <md-speed-dial-content>
        <md-button
          v-for="language in get_languages_to_show"
          :key="`language-flag-${language}`"
          class="md-icon-button"
          @click="language_change(language)"
          :class="{
            'language-switcher--disabled': allow_only_enabled && !enabled_languages.includes(language),
            'language-switcher--missing': missing_languages.includes(language),
            'language-switcher--selected': selected_language === language,
          }"
        >
          <md-tooltip
            v-if="allow_only_enabled && !enabled_languages.includes(language)"
            md-direction="top"
          >{{ $translate("enable_language_message") }}</md-tooltip>
          <md-tooltip
            v-if="missing_languages.includes(language)"
            md-direction="top"
          >{{ $translate("missing_language_values") }}</md-tooltip>
          <md-icon>
            <img
              :src="`/static/icons/countries/${language}.svg`"
              :alt="`${language} flag`"
              :data-cy="`${language}`"
              :style="{
                width: sizes.width,
                height: sizes.height
              }"
            >
          </md-icon>
        </md-button>
      </md-speed-dial-content>
    </md-speed-dial>
  </div>
</template>

<script>
import { mapState } from "vuex"
import { languages } from "../../../Shared/constants/other"
import { CONFIGURATION_STORE } from "../../constants/others_constants"

export default {
  props: {
    allow_only_enabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    show_title: {
      required: false,
      type: Boolean,
      default: true,
    },
    sizes: {
      required: false,
      type: Object,
      default: () => ({
        width: "50px",
        height: "40px"
      }),
    },
    show_all_languages: {
      required: false,
      type: Boolean,
      default: true,
    },
    selected_language: {
      required: false,
      type: String | undefined,
      default: "",
    },
    bottom_border: {
      required: false,
      type: Boolean,
      default: false,
    },
    missing_languages: {
      required: false,
      type: Array,
      default: () => [],
    },
    enable_mobile_view: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(CONFIGURATION_STORE, ["translations"]),
    enabled_languages() {
      return Object.keys(this.translations)
    },
    get_languages_to_show() {
      return this.show_all_languages ? languages : this.enabled_languages
    }
  },
  watch: {
    get_languages_to_show() {
      if (!this.selected_language) this.language_change(this.get_languages_to_show[0])
    }
  },
  mounted() {
    if (!this.selected_language) this.language_change(this.get_languages_to_show[0])
  },
  methods: {
    language_change(language) {
      if (this.allow_only_enabled && !this.enabled_languages.includes(language)) return

      this.$emit("change-language", language)
    }
  }
}
</script>

<style lang="scss">
  @use "../../../../styles/_global-constants" as *;
  @use "../../../../styles/_admin-constants" as *;

  .language-switcher {
    padding-left: 5px;

    p {
      font-weight: bold;
      margin: $half-default-size 0;
    }

    &__speed-dial {
      position: relative;

      .md-fab {
        @media (max-width: $tablet--small) {
          width: $icon-size;
          height: $icon-size;
        }
      }

      .md-speed-dial-content {
        display: none;
        position: absolute;
        bottom: $material-button;
        left: 0;

        @media (min-width: #{$tablet--small + 1px}) {
          width: $material-button;
          
          .md-button {
            width: $material-button;
            height: $material-button;
            padding: 5px;

            .md-icon {
              width: 100%;
              height: 100%;
            }
          }
        }

        @media (max-width: $tablet--small) {
          width: $icon-size;
          bottom: $icon-size;
        }
      }

      &:hover {
        .md-speed-dial-content {
          display: block;
        }
      }
    }

    &--missing {
      box-shadow: 0px 0px 8px 1px $red-vivid !important;
    }

    &--disabled {
      opacity: .5;
      cursor: default !important;
    }

    &--selected {
      box-shadow: $inset-shadow-color !important;
    }

    &--bottom-border {
      padding-bottom: $half-default-size;
      border-bottom: 1px solid $material-grey;
      margin-bottom: $half-default-size;
    }

    &__language-flags {
      display: inline-block;
      padding: 5px 0 5px 5px;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      vertical-align: middle;

      ::-webkit-scrollbar {
        display: none;
      }

      -ms-overflow-style: -ms-autohiding-scrollbar;

      span {
        display: inline-block;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          cursor: pointer;
          margin-right: $half-default-size;
          border-radius: 6px;
          box-shadow: 0px 0px 7px 1px $shadow-color;
          transition: .2s ease-in-out;
        }
      }

      &--inline {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
</style>
