<template>
  <section class="product-inventory-manager scroll-content">
    <h3 class="md-display-1">{{ $translate("product_inventory.title") }}:</h3>
    <section_description_preview :dense="true">
      <p class="md-subheading">{{ $translate("product_inventory.message") }}</p>
    </section_description_preview>

    <md-tabs :md-active-tab="active_tab" @md-changed="tab => active_tab = tab">
      <md-tab id="stock" :md-label="$translate('product_inventory.stock_tab')" />
      <md-tab id="inventory" :md-label="$translate('product_inventory.inventory_tab')" />
    </md-tabs>

    <div v-if="is_stock_tab || new_inventory_check || selected_inventory_check">
      <br/>

      <div
        v-if="!is_stock_tab && !selected_inventory_check_id"
        class="product-inventory-manager__inventory-actions"
      >
        <md-button data-cy="evaluate-inventory-check" class="md-raised md-primary" @click="handle_inventory_check" :disabled="!permissions.products.manage">
          <md-icon>checklist_rtl</md-icon>
          {{ $translate("product_inventory.evaluate_inventory_check") }}
        </md-button>
        <md-button data-cy="perform-inventory-check" class="product-inventory-manager__inventory-cancel md-raised md-accent" v-if="new_inventory_check" @click="new_inventory_check = false">
          {{ $translate("product_inventory.cancel_inventory_check") }}
        </md-button>
      </div>

      <p v-if="is_inventory_check_detail_open" class="md-title">
        {{ $translate("product_inventory.inventory_check_from") }} {{ selected_inventory_check.date }}

        <md-button
          v-if="is_inventory_check_detail_open"
          class="product-inventory-manager__inventory-cancel md-raised"
          @click="selected_inventory_check_id = null"
        >
          {{ $translate("close") }}
        </md-button>
      </p>

      <md-table v-if="is_inventory_check_detail_open" class="admin-table">
        <md-table-row>
          <md-table-head>{{ $translate("product_inventory.shortage_total") }}</md-table-head>
          <md-table-head>{{ $translate("product_inventory.surplus_total") }}</md-table-head>
          <md-table-head>{{ $translate("product_inventory.total_difference") }}</md-table-head>
        </md-table-row>

        <md-table-row>
          <md-table-cell data-cy="total-shortage">
            <span v-if="is_currency_symbol(currency_signs[get_total_price_info('loss', 'currency_code', selected_inventory_check.total_loss)])" class="md-prefix">
              {{ currency_signs[get_total_price_info('loss', 'currency_code', selected_inventory_check.total_loss)] }}
            </span>
            {{ get_total_price_info('loss', 'price', selected_inventory_check.total_loss) * -1 }}
            <span v-if="!is_currency_symbol(currency_signs[get_total_price_info('loss', 'currency_code', selected_inventory_check.total_loss)])" class="md-suffix">
              {{ currency_signs[get_total_price_info('loss', 'currency_code', selected_inventory_check.total_loss)] }}
            </span> / {{ selected_inventory_check.total_stock_loss * -1 }}
          </md-table-cell>

          <md-table-cell data-cy="total-surplus" >
            <span v-if="is_currency_symbol(currency_signs[get_total_price_info('gain', 'currency_code', selected_inventory_check.total_gain)])" class="md-prefix">
              {{ currency_signs[get_total_price_info('gain', 'currency_code', selected_inventory_check.total_gain)] }}
            </span>
            {{ get_total_price_info('gain', 'price', selected_inventory_check.total_gain) }}
            <span v-if="!is_currency_symbol(currency_signs[get_total_price_info('gain', 'currency_code', selected_inventory_check.total_gain)])" class="md-suffix">
              {{ currency_signs[get_total_price_info('gain', 'currency_code', selected_inventory_check.total_gain)] }}
            </span> / {{ selected_inventory_check.total_stock_gain }}
          </md-table-cell>

          <md-table-cell data-cy="total-difference">
            <b>
              <span v-if="is_currency_symbol(currency_signs[get_total_price_info('gain', 'currency_code', selected_inventory_check.total_gain)])" class="md-prefix">
                {{ currency_signs[get_total_price_info('gain', 'currency_code', selected_inventory_check.total_gain)] }}
              </span>
              {{
                get_total_price_info('gain', 'price', selected_inventory_check.total_gain) -
                get_total_price_info('loss', 'price', selected_inventory_check.total_loss)
              }}
              <span v-if="!is_currency_symbol(currency_signs[get_total_price_info('gain', 'currency_code', selected_inventory_check.total_gain)])" class="md-suffix">
                {{ currency_signs[get_total_price_info('gain', 'currency_code', selected_inventory_check.total_gain)] }}
              </span> / {{ selected_inventory_check.total_stock_gain - selected_inventory_check.total_stock_loss }}
            </b>
          </md-table-cell>
        </md-table-row>
      </md-table>

      <md-field class="product-inventory-manager__search" md-clearable>
        <label>{{ $translate("product_inventory.search_label") }}</label>
        <md-input v-model="variants_search_input" @input="search_product_variants"/>
        <span class="md-helper-text">{{ $translate("product_inventory.search_message") }}</span>
      </md-field>

      <md-table class="product-inventory-manager__table admin-table">
        <md-table-row>
          <md-table-head></md-table-head>
          <md-table-head>{{ $translate("product_inventory.stock_table.name") }}</md-table-head>
          <md-table-head>{{ $translate("product_inventory.stock_table.price") }}</md-table-head>
          <md-table-head>{{ $translate("product_inventory.stock_table.sku") }}</md-table-head>
          <md-table-head>{{ $translate("product_inventory.stock_table.inventory_quantity") }}</md-table-head>
          <md-table-head>
            {{ $translate("product_inventory.stock_table.minimum_ideal_quantity") }}
            <span>
              <md-icon>help_outline</md-icon>
              <md-tooltip md-direction="top">{{ $translate("product_inventory.ideal_quantity_explanation") }}</md-tooltip>
            </span>
          </md-table-head>
          <md-table-head v-if="show_inventory_check_field || is_inventory_check_detail_open">{{ $translate("product_inventory.stock_table.actual_stock_quantity") }}</md-table-head>
          <md-table-head v-if="is_inventory_check_detail_open">{{ $translate("product_inventory.stock_table.quantity_difference") }}</md-table-head>
          <md-table-head v-if="is_inventory_check_detail_open">{{ $translate("product_inventory.stock_table.value_difference") }}</md-table-head>
        </md-table-row>

        <md-table-row
          v-for="(variant, index) in searching ? empty_variants : filtered_variants"
          :key="index"
        >
          <md-table-cell v-if="!searching"><img :src="variant.image.mobile || variant.image.src || default_image_src"></md-table-cell>
          <md-table-cell v-if="!searching">
            <div><b>{{ get_correct_translation_value(variant, "title", [language], true, false, "product_translations") }}</b></div>
            <div>
              {{
                get_correct_translation_value(variant, "option1", [language], true, false) === default_option_name ?
                  "" :
                  [1,2,3].map(
                    number => get_correct_translation_value(variant, `option${number}`, [language], true, false)
                  ).filter(val => val).join(", ")
              }}
            </div>
          </md-table-cell>
          <md-table-cell v-if="!searching">
            <span v-if="is_currency_symbol(currency_signs[get_correct_translation_value(variant, 'currency_code', [language], false, false, 'product_translations')])" class="md-prefix">
              {{ currency_signs[get_correct_translation_value(variant, 'currency_code', [language], false, false, 'product_translations')] }}
            </span>
            {{ get_correct_translation_value(variant, "price", [language], false) }}
            <span v-if="!is_currency_symbol(currency_signs[get_correct_translation_value(variant, 'currency_code', [language], false, false, 'product_translations')])" class="md-suffix">
              {{ currency_signs[get_correct_translation_value(variant, 'currency_code', [language], false, false, 'product_translations')] }}
            </span>
          </md-table-cell>
          <md-table-cell v-if="!searching">{{ variant.sku || "" }}</md-table-cell>
          <md-table-cell v-if="!searching">
            <md-field md-inline>
              <md-input
                data-cy="inventory-quantity"
                :disabled="(!permissions.products.manage && is_stock_tab) || !!is_inventory_check_detail_open"
                type="number"
                :value="variant.inventory_quantity"
                @change="event => update_variant_quantity(variant.product_id, variant.id, variant.is_shopify, event.target.value)"
              />
            </md-field>
          </md-table-cell>
          <md-table-cell v-if="!searching">
            <md-field md-inline>
              <md-input
                data-cy="inventory-ideal-quantity"
                :disabled="(!permissions.products.manage && is_stock_tab) || !!is_inventory_check_detail_open"
                type="number"
                :value="variant.ideal_quantity || ''"
                @change="event => update_variant_quantity(variant.product_id, variant.id, variant.is_shopify, event.target.value, true)"
              />
            </md-field>
          </md-table-cell>
          <md-table-cell v-if="!searching && (show_inventory_check_field || is_inventory_check_detail_open)">
            <md-field md-inline>
              <md-input
                data-cy="actual-stock-quantity"
                :disabled="!permissions.products.manage || !!is_inventory_check_detail_open"
                type="number"
                :value="is_inventory_check_detail_open ? variant.stock_quantity : get_stock_quantity_of_variant(variant.id)"
                @change="event => update_variant_stock_quantity(variant.id, Number(event.target.value))"
              />
            </md-field>
          </md-table-cell>
          <md-table-cell v-if="!searching && is_inventory_check_detail_open && !is_stock_tab">
            {{ variant.quantity_difference }}
          </md-table-cell>
          <md-table-cell v-if="!searching && is_inventory_check_detail_open && !is_stock_tab">
            <span v-if="is_currency_symbol(currency_signs[get_correct_translation_value(variant, 'currency_code', [language], true, false, 'price_differences')])" class="md-prefix">
              {{ currency_signs[get_correct_translation_value(variant, 'currency_code', [language], true, false, 'price_differences')] }}
            </span>
            {{ get_correct_translation_value(variant, "price_difference", [language], true, false, "price_differences") }}
            <span v-if="!is_currency_symbol(currency_signs[get_correct_translation_value(variant, 'currency_code', [language], true, false, 'price_differences')])" class="md-prefix">
              {{ currency_signs[get_correct_translation_value(variant, 'currency_code', [language], true, false, 'price_differences')] }}
            </span>
          </md-table-cell>
          <md-table-cell v-if="searching" class="product-inventory-manager__search-item element-loading"/>
          <md-table-cell v-if="searching" class="product-inventory-manager__search-item element-loading"/>
          <md-table-cell v-if="searching" class="product-inventory-manager__search-item element-loading"/>
          <md-table-cell v-if="searching" class="product-inventory-manager__search-item element-loading"/>
          <md-table-cell v-if="searching" class="product-inventory-manager__search-item element-loading"/>
          <md-table-cell v-if="searching" class="product-inventory-manager__search-item element-loading"/>
          <md-table-cell v-if="searching && (show_inventory_check_field || is_inventory_check_detail_open)" class="product-inventory-manager__search-item element-loading"/>
          <md-table-cell v-if="searching && is_inventory_check_detail_open" class="product-inventory-manager__search-item element-loading"/>
          <md-table-cell v-if="searching && is_inventory_check_detail_open" class="product-inventory-manager__search-item element-loading"/>
        </md-table-row>
      </md-table>
    </div>

    <div v-else class="product-inventory-manager__inventory-checks">
      <p class="md-title">
        {{ $translate("product_inventory.conducted_inventory_checks") }}
        <md-button data-cy="perform-inventory-check" class="md-raised md-primary" @click="handle_inventory_check" :disabled="!permissions.products.manage">
          <md-icon>playlist_add_check</md-icon>
          {{ $translate(`product_inventory.new_inventory_check`) }}
        </md-button>
      </p>

      <div v-if="inventory_checks.length" class="md-layout md-gutter">
        <div class="md-layout-item md-size-33 md-small-size-50 md-xsmall-size-100">
          <md-list>
            <md-list-item
              v-for="{ date, id } in inventory_checks"
              :key="id"
              class="product-inventory-manager__check md-elevation-3"
            >
              <span class="md-list-item-text">{{ date }}</span>

              <md-button class="md-icon-button md-list-action" @click="selected_inventory_check_id = id">
                <md-icon>visibility</md-icon>
              </md-button>
            </md-list-item>
          </md-list>
        </div>
      </div>
      <p v-else class="md-body-1">{{ $translate("product_inventory.no_inventory_checks") }}</p>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import {ADMIN, CONFIGURATION_STORE, default_option_name, imported, PRODUCTS_STORE, shopify, USER_STORE } from "../../../constants/others_constants"
import { IMPORTED_DATA_UPDATED, SHOPIFY_DATA_UPDATED, UPDATE_LOADER } from "../../../stores/Admin/constants"
import { CLIENT_STORE } from "../../../../Client/constants/other"
import debounce from "../../../../Shared/methods/debounce"
import { GET_USER_LANGUAGE } from "../../../stores/Admin/user/constants"
import get_correct_translation_value from "../../../../Shared/methods/get_correct_translation_value"
import { currency_signs, default_image_src } from "../../../../Shared/constants/other"
import { UPDATE_VARIANTS, UPDATE_CONDUCTED_INVENTORY_CHECKS, UPDATE_INVENTORY_CHECKS } from "../../../stores/Admin/products/constants"
import { search_function } from "../../../../Shared/methods/entity_search"
import { MIN_SEARCH_CHARACTERS } from "../../../../Marketplace/constants/other"
import is_currency_symbol from "../../../../Shared/methods/is_currency_symbol"
import section_description_preview from "../section_description_preview"

const searched_variant_attributes = [
  "translations",
  "product_translations",
  "id",
  "sku",
  "option1",
  "option2",
  "option3",
  "title",
  "product_id"
]
const empty_variants = [{},{},{},{},{},{}]

export default {
  components: {
    section_description_preview
  },
  data() {
    return {
      active_tab: "stock",
      variants_search_input: "",
      filter_keys: [],
      inventory_checked_variants: [],
      selected_inventory_check_id: null,
      new_inventory_check: false,
      searching: false,
      default_option_name,
      currency_signs,
      empty_variants,
      default_image_src
    }
  },
  computed: {
    ...mapState(CONFIGURATION_STORE, ["project_config", "translations", "shipping_options"]),
    ...mapState(CLIENT_STORE, ["project_styles"]),
    ...mapState(USER_STORE, ["permissions", "user_info"]),
    ...mapState(PRODUCTS_STORE, [
      "imported_products",
      "shopify_products",
      "inventory_checks"
    ]),
    ...mapGetters(USER_STORE, { language: GET_USER_LANGUAGE }),
    selected_inventory_check() {
      return this.inventory_checks.find(({ id }) => id === this.selected_inventory_check_id)
    },
    all_variants() {
      return [
        ...(this.imported_products || []).flatMap(product => this.get_variants(product)),
        ...(this.shopify_products || []).flatMap(product => this.get_variants(product, true)),
      ]
    },
    get_correct_variants() {
      return (this.selected_inventory_check && !this.is_stock_tab) ? this.selected_inventory_check.results : this.all_variants
    },
    filtered_variants() {
      return this.get_correct_variants.filter((variant) =>
        (!this.filter_keys.length || !this.filter_keys[0]) ? variant : this.filter_keys.every(
          key => searched_variant_attributes.some(attribute => search_function(variant[attribute], key))
        )
      )
    },
    is_stock_tab() {
      return this.active_tab === 'stock'
    },
    show_inventory_check_field() {
      return this.new_inventory_check && !this.is_stock_tab
    },
    is_inventory_check_detail_open() {
      return !this.is_stock_tab && !!this.selected_inventory_check_id
    }
  },
  watch: {
    filtered_variants() {
      this.searching = false
    },
  },
  methods: {
    ...mapMutations(ADMIN, {
      update_loader: UPDATE_LOADER,
      imported_data_updated: IMPORTED_DATA_UPDATED,
      shopify_data_updated: SHOPIFY_DATA_UPDATED,
    }),
    ...mapMutations(PRODUCTS_STORE, {
      update_variants: UPDATE_VARIANTS,
      update_inventory_checks: UPDATE_CONDUCTED_INVENTORY_CHECKS,
    }),
    ...mapActions(PRODUCTS_STORE, {
      save_inventory_checks: UPDATE_INVENTORY_CHECKS,
    }),
    is_currency_symbol,
    get_correct_translation_value,
    get_stock_quantity_of_variant(variant_id) {
      return (
        this.inventory_checked_variants.length ?
          this.inventory_checked_variants.find(({ id }) => id === variant_id) || { stock_quantity: "" } :
          { stock_quantity: "" }
      ).stock_quantity
    },
    update_variant_stock_quantity(variant_id, value) {
      const found_index = this.inventory_checked_variants.findIndex(({ id }) => id === variant_id)

      return found_index > -1 ?
        this.$set(this.inventory_checked_variants[found_index], "stock_quantity", value) :
        this.inventory_checked_variants.push({ id: variant_id, stock_quantity: value })
    },
    handle_inventory_check() {
      this.update_loader()

      if (!this.new_inventory_check) this.new_inventory_check = true
      else {
        if (!this.inventory_checked_variants.length) return

        let total_loss = {}
        let total_gain = {}
        let total_stock_loss = 0
        let total_stock_gain = 0
        const inventory_check_id = new Date().getTime()
        const inventory_check_results = this.inventory_checked_variants.map(({ id, stock_quantity }) => {
          const {
            price, option1, option2, option3, image, translations,
            product_translations, inventory_quantity, sku, ideal_quantity, title
          } = this.filtered_variants.find(({ id: var_id }) => var_id === id)
          const quantity_difference = Number(stock_quantity) - Number(inventory_quantity)
          const price_differences = Object.keys(translations || {}).reduce((tot, lang) => {
            if (!lang) return tot

            const correct_price = get_correct_translation_value({ translations, price }, "price", [lang])
            const price_difference = quantity_difference * correct_price
            const correct_currency = get_correct_translation_value({ product_translations }, "currency_code", [lang], true, false, "product_translations")

            if (!total_loss[lang]) total_loss[lang] = {}
            total_loss[lang].currency_code = correct_currency
            total_loss[lang].price = total_loss[lang].price || 0
            if (!total_gain[lang]) total_gain[lang] = {}
            total_gain[lang].price = total_gain[lang].price || 0
            total_gain[lang].currency_code = correct_currency

            if (price_difference < 0) total_loss[lang].price = total_loss[lang].price - price_difference
            else total_gain[lang].price = total_gain[lang].price + price_difference

            return {
              ...tot,
              [lang]: {
                currency_code: correct_currency,
                price,
                price_difference
              }
            }
          }, {})

          if (quantity_difference < 0) total_stock_loss -= quantity_difference
          else total_stock_gain += quantity_difference

          return {
            price, id, option1, option2, option3, sku, image, stock_quantity, inventory_quantity,
            ideal_quantity, translations, quantity_difference, price_differences, product_translations,
            title
          }
        })

        this.update_inventory_checks([{
          date: new Date().toLocaleDateString(),
          id: inventory_check_id,
          results: inventory_check_results,
          total_loss,
          total_gain,
          total_stock_loss,
          total_stock_gain
        }, ...this.inventory_checks])
        this.save_inventory_checks()

        this.new_inventory_check = false
        this.selected_inventory_check_id = inventory_check_id
      }

      this.update_loader(false)
    },
    get_variants({ id, variants, translations, images = [], title, sku }, is_shopify) {
      return variants.map(variant => {
        const correct_image = (
          (variant.image_id ? images.find(({ id }) => id === variant.image_id) : images[0]) ||
          { thumb_src: default_image_src }
        )

        return {
          ...variant,
          [is_shopify ? "title" : "product_translations"]: is_shopify ? title : translations,
          sku,
          is_shopify,
          product_id: id,
          image: correct_image.thumb_src || correct_image.src
        }
      })
    },
    search_product_variants() {
      this.searching = true
      debounce(async () => {
        if (
          this.variants_search_input.length > MIN_SEARCH_CHARACTERS
        ) this.filter_keys = this.variants_search_input.split(",").map(key => key ? key.trim() : "")
        else {
          this.filter_keys = []
          this.searching = false
        }
      }, 500, this)()
    },
    update_variant_quantity(product_id, variant_id, is_shopify, value, changing_ideal_quantity) {
      const correct_product_variants = this[`${is_shopify ? shopify : imported}_products`].find(({ id }) => id === product_id).variants

      this.update_variants([
        is_shopify ? shopify : imported,
        product_id,
        correct_product_variants.map(
          variant => ({
            ...variant,
            ...(variant.id === variant_id && {
              [changing_ideal_quantity ? "ideal_quantity" : "inventory_quantity"]: Number(value)
            })
          })
        )
      ])

      this[`${is_shopify ? shopify : imported}_data_updated`](true)
    },
    get_total_price_info(type, key, obj_value) {
      return get_correct_translation_value({ [`total_${type}`]: obj_value }, key, [this.language], true, false, `total_${type}`)
    }
  }
}
</script>

<style lang="scss">
  @use "../../../../../styles/_global-constants" as *;
  @use "../../../../../styles/_admin-constants" as *;
  @use "../../../../../styles/admin" as *;
  @use "../../../../../styles/element_loading.scss";

  .product-inventory-manager {
    &__table {
      img {
        max-width: 50px;
        max-height: 50px;
      }

      .md-field {
        margin: 0;
      }

      .md-icon-font {
        width: $font-size--medium;
        height: $font-size--medium;
        font-size: $font-size--medium !important;
      }
    }

    &__check {
      &:first-child {
        margin-top: 0;
      }

      margin-top: $half-default-size;
    }

    &__inventory {
      &-cancel {
        float: right;
        margin-top: -5px;

        &.md-accent {
          margin-top: 5px;
        }
      }

      &-checks {
        p .md-button {
          float: right;

          @media (max-width: $tablet--small) {
            float: none;
            width: 100%;
            margin: $default-size 0;
          }
        }
      }

      &-actions button {
        @media (max-width: $mobile--large) {
          display: block;
          width: 100%;
          margin: $half-default-size 0 0;
        }
      }
    }

    &__search {
      max-width: 600px;

      &-item {
        transform: scale3d(0.98, 0.85, 1);
      }
    }

    .md-table-head-label {
      min-width: 100px;
    }
  }
</style>
