const clear = entity => {
  clearTimeout(entity.timeout)
  entity.timeout = null
}

export default (func, wait, entity) => {
  return function executedFunction(...args) {
    const later = () => {
      clear(entity)
      func(...args)
    }

    clear(entity)
    entity.timeout = setTimeout(later, wait)
  }
}