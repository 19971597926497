<template>
  <div
    class="product-detail"
    :class="{
      'product-detail--detail': fullscreen,
      'product-detail--deleted': product_data.change_type === deleted,
      'product-detail--list-item': show_list_item,
      'product-detail--preview': show_item_preview
    }"
  >
    <product_favorite_selector
      v-if="show_favorite_selector_on_small_detail"
      :collection_index="collection_index"
      :product_id="product_data.id"
      :products_type="products_type"
      :large="fullscreen"
      :inside_list_item="show_list_item"
      :align_top="all_available_languages.length === 1 && fullscreen"
    />

    <!-- Full product detail -->

      <div v-if="fullscreen">
        <div v-if="product_data.change_type === deleted" class="product-detail__delete__overlay"/>

        <div
          v-if="products_type === imported && !show_item_preview"
          v-observe-visibility="is_visible => are_flags_visible = is_visible"
          class="product-detail__top"
          :class="{
            'product-detail__top--has-multiple-languages': all_available_languages.length > 1,
          }"
        >
          <language_switcher
            v-if="all_available_languages.length > 1"
            :allow_only_enabled="true"
            :show_all_languages="false"
            :selected_language="selected_language"
            :bottom_border="true"
            :missing_languages="missing_language_translations"
            @change-language="language => {
              $emit('toggle_selected_product_language', language)
              selected_language = language
            }"
          />

          <md-speed-dial md-direction="bottom" class="product-detail__speed-dial">
            <md-speed-dial-target class="md-normal">
              <md-icon>more_vert</md-icon>
            </md-speed-dial-target>

            <md-speed-dial-content>
              <product_favorite_selector
                :collection_index="collection_index"
                :product_id="product_data.id"
                :products_type="products_type"
                :large="fullscreen"
                :inside_list_item="show_list_item"
                :display_icon_button="true"
              />

              <md-button
                v-if="!selecting_position"
                class="md-icon-button"
                :class="{ 'product-detail__remove-icon': is_products_in_popular }"
                @click="() => {
                  selecting_position = true
                  add_product_to = 'popular'
                }"
              >
                <md-icon><popular_products_icon /></md-icon>
                <md-tooltip md-direction="left">
                  {{ $translate(`products.${is_products_in_popular ? "remove_from" : "add_to"}.popular`) }}
                </md-tooltip>
              </md-button>

              <md-button
                v-if="!selecting_position"
                class="md-icon-button"
                :class="{ 'product-detail__remove-icon': is_products_in_highlighted }"
                @click="() => {
                  selecting_position = true
                  add_product_to = 'highlighted'
                }"
              >
                <md-icon><highlighted_products_icon /></md-icon>
                <md-tooltip md-direction="left">
                  {{ $translate(`products.${is_products_in_highlighted ? "remove_from" : "add_to"}.highlighted`) }}
                </md-tooltip>
              </md-button>
            </md-speed-dial-content>
          </md-speed-dial>
        </div>

        <div
          class="product-detail__fullscreen"
          :class="{
            'product-detail__fullscreen--cross-sold': is_cross_sold_product,
            'product-detail__fullscreen--preview': show_item_preview
          }"
        >

        <!-- Title and availability -->

          <div class="product-detail__fullscreen__section product-detail__title">
            <md-field>
              <label>{{ $translate("products.product_name") }}:</label>
              <md-input
                data-cy="product-name"
                :disabled="!permissions.products.manage || show_item_preview"
                :value="title"
                md-autogrow
                :placeholder="$translate('products.product_name')"
                @change="({ target }) => title = target.value"
              />
            </md-field>

            <div class="md-subheading product-detail__fullscreen__checkbox">
              <md-checkbox
                data-cy="product-available"
                :disabled="!permissions.products.manage || show_item_preview || product_data.force_unpublished_state"
                v-model="availability"
                class="md-primary"
              >
                  {{ $translate("products.should_product_be_available") }}
                  <md-tooltip v-if="product_data.force_unpublished_state" md-direction="bottom">
                    {{ $translate("products.cross_sale.owner_made_unavailable") }}
                  </md-tooltip>
              </md-checkbox>
            </div>
          </div>

        <!-- Product tags -->

          <div v-if="!show_item_preview" class="product-detail__fullscreen__section">
            <p class="md-title">{{ $translate("products.product_tags") }}:</p>

            <section_description_preview :dense="true">
              <div class="md-body-1">{{ $translate("products.product_tags_message") }}</div>
            </section_description_preview>

            <div class="product-detail__tags">
              <span
                v-for="(tag, ind) in product_tags"
                :key="`tag-${ind}`"
                @click="remove_tag(tag)"
              >
                {{ tag }}
              </span>
              <div v-if="permissions.products.manage">
                <md-field md-inline>
                  <md-input v-model="new_tag_value" placeholder="Tag"/>
                </md-field>
                <md-button
                  class="md-icon-button md-primary md-raised"
                  @click="add_tag"
                >
                  <md-icon>add</md-icon>
                </md-button>
              </div>
            </div>
          </div>

        <!-- Preview product Cross info -->

          <div v-if="show_item_preview" class="product-detail__fullscreen__section product-detail__cross-preview">
            <div class="product-detail__cross-preview-percentage">
              <div class="md-body-2">
                {{ $translate("products.cross_sale.resale_percentage") }}:
                <b>{{ product_data.resale_percentage }}%</b>
                <md-tooltip md-direction="top">
                  {{ $translate("products.cross_sale.profit_on_product") }}
                </md-tooltip>
              </div>
            </div>
            <div class="product-detail__cross-preview-price">
              <div class="md-body-2">
                {{ $translate("products.cross_sale.price") }}:
                <b>{{ currency }} {{ get_correct_translation_value(product_data.variants[0], "price") || 0 }}</b>
              </div>
            </div>
            <div class="product-detail__cross-preview-profit">
              <p class="md-body-2">{{ $translate("products.cross_sale.approximate_profit") }}:</p>
              <div>{{ resale_profit_calculation }}</div>
            </div>
          </div>

        <!-- Gallery -->

          <div class="product-detail__fullscreen__section product-detail__gallery">
            <p class="md-title">{{ $translate("products.images.title") }}:</p>

            <section_description_preview :dense="true">
              <div class="md-body-1">{{ $translate("products.images.message") }}</div>
            </section_description_preview>

            <div class="product-detail__gallery__wrapper">
              <md-button
                v-if="permissions.products.manage && !show_item_preview"
                class="md-fab md-raised md-primary product-detail__gallery__button"
                @click="uploading_image = true"
              >
                <md-icon data-cy="upload-product-image">add</md-icon>
                <md-tooltip md-direction="top">{{ $translate("products.images.add_new_image") }}</md-tooltip>
              </md-button>
              <draggable v-model="images" group="images" @start="drag=true" @end="drag=false" class="product-detail__gallery__images">
                <div
                  v-for="(image, ind) in images"
                  :key="`${product_data.handle}-${ind}-image`"
                  class="product-detail__gallery__image"
                >
                  <img
                    class="md-layout-item"
                    :src="get_image_src(image) || image.attachment"
                  >
                  <md-speed-dial v-if="!show_item_preview" md-event="click" md-effect="scale" md-direction="bottom" class="product-detail__gallery__edit">
                    <md-speed-dial-target class="md-plain">
                      <md-icon>more_vert</md-icon>
                    </md-speed-dial-target>

                    <md-speed-dial-content>
                      <md-button class="md-icon-button" @click="show_image(image, ind)">
                        <md-icon>remove_red_eye</md-icon>
                        <md-tooltip md-direction="top">{{ $translate("products.images.preview_image") }}</md-tooltip>
                      </md-button>

                      <!-- <md-button class="md-icon-button" @click="show_image(image, ind, true)">
                        <md-icon>edit</md-icon>
                        <md-tooltip md-direction="top">{{ $translate("products.images.edit_image") }}</md-tooltip>
                      </md-button> -->

                      <!-- TODO when deleting image, make sure that its also deleted from storage (if user didnt upload existing image again) -->
                      <md-button v-if="permissions.products.manage" class="md-icon-button" @click="delete_image(image, ind)">
                        <md-icon>delete_outline</md-icon>
                        <md-tooltip md-direction="top">{{ $translate("products.images.delete_image") }}</md-tooltip>
                      </md-button>
                    </md-speed-dial-content>
                  </md-speed-dial>
                </div>
              </draggable>
            </div>
            <md-dialog
              :md-active.sync="open_image_modal"
              class="product-detail__gallery__modal"
            >
              <md-dialog-title>
                {{ 
                  uploading_image ?
                    $translate("upload_images") :
                    image_being_edited ? 
                      $translate("products.images.edit_images") : $translate("products.images.preview_images")
                }}
              </md-dialog-title>

              <div v-if="uploading_image">
                <div v-if="uploaded_images.length">
                  <div class="image-upload__image-wrapper">
                    <img
                      v-for="(image, index) in uploaded_images"
                      :key="index"
                      class="image-upload__image"
                      :src="get_image_src(image)"
                    >
                  </div>
                  <div class="image-upload__image-alt-wrapper">
                    <md-field>
                      <md-input
                        :disabled="!permissions.products.manage"
                        :value="image_alt_text"
                        @change="event => image_alt_text = event.target.value"
                        :placeholder="$translate('products.images.alt_text')"
                      />
                    </md-field>
                    <span class="image-upload__question-mark">
                      <md-icon>help_outline</md-icon>
                      <md-tooltip md-direction="top" v-html="$translate('products.images.alt_text_explanation')" class="image-upload__tooltip"/>
                    </span>
                  </div>
                  <md-button class="md-raised md-accent" @click="() => {
                    uploading_image = true; uploaded_images = []; image_alt_text = null
                  }">
                    {{ $translate("clear_images") }}
                  </md-button>
                  <md-button data-cy="finish-upload" class="md-raised md-primary" @click="save_images">
                    {{ $translate("done") }}
                  </md-button>
                </div>
                <div v-else class="md-elevation-5 image-upload">
                  <input
                    data-cy="upload"
                    type="file"
                    id="image-upload"
                    accept=".jpg, .jpeg, .png, .webp"
                    multiple="multiple"
                    @drop="event => add_images(event.target.files)"
                    @input="event => add_images(event.target.files)"
                    v-cloak
                  >
                  <label for="image-upload">
                    <md-icon>cloud_upload</md-icon>
                    {{ $translate("drag_and_drop_image") }}
                  </label>
                </div>
              </div>
              <!-- Todo editation of image (crop, resize, rotate, ...) -->
              <img v-else class="image-upload__image image-upload__image--preview" :src="get_image_src(selected_image, image_dimension_names.desktop)">
            </md-dialog>
          </div>

        <!-- Description -->

          <div class="product-detail__fullscreen__section product-detail__description" data-cy="product-description">
            <p class="md-title">{{ $translate("products.description") }}:</p>
            <p v-if="!show_item_preview" class="md-body-1">{{ $translate("products.product_description_info") }}</p>
            <div v-if="show_item_preview" class="product-detail__preview-text">
              <div v-html="description"/>
              <div class="product-detail__preview-text-overflow"/>
            </div>
            <text_and_html_editor
              v-else
              :disabled="!permissions.products.manage || show_item_preview"
              :value="description"
              @input="value => user_updated_description(value)"
            />
          </div>


        <!-- Message -->

          <div class="product-detail__fullscreen__section product-detail__message" data-cy="product-message">
            <p class="md-title">{{ $translate("products.message") }}:</p>
            <p v-if="!show_item_preview" class="md-body-1">{{ $translate("products.product_message_info") }}</p>
            <div v-if="show_item_preview" class="product-detail__preview-text">
              <div v-html="message"/>
              <div class="product-detail__preview-text-overflow"/>
            </div>
            <text_and_html_editor
              v-else
              :disabled="!permissions.products.manage || show_item_preview"
              :value="message"
              @input="value => user_updated_description(value, true)"
            />
          </div>

        <!-- Product Dimensions -->

          <div v-if="!is_cross_sold_product" class="product-detail__fullscreen__section product-detail__dimensions-config">
            <p class="md-title">{{ $translate("products.product_dimensions") }}:</p>
            
            <md-checkbox
              data-cy="physical-product"
              :disabled="!permissions.products.manage || show_item_preview"
              v-model="requires_shipping"
              class="md-primary product-detail__dimensions__checkbox"
            >{{ $translate("products.is_physical_product") }}</md-checkbox>

            <section_description_preview :dense="true">
              <div class="md-body-1">{{ $translate("products.product_dimensions_message") }}:</div>
            </section_description_preview>

            <dimensions_selector
              :update_variant="update_variant"
              :permissions="permissions"
              :show_item_preview="show_item_preview"
              :variants="variants"
              :selected_distance_unit="selected_distance_unit"
              :selected_weight_unit="selected_weight_unit"
              :show_distances="products_type === imported"
              @update_distance_unit="unit => selected_distance_unit = unit"
              @update_weight_unit="unit => selected_weight_unit = unit"
            />
          </div>

        <!-- Product Categorization -->

          <div v-if="!is_cross_sold_product" class="product-detail__fullscreen__section product-detail__categorization">
            <p class="md-title">{{ $translate("products.product_categorization") }}:</p>

            <p v-if="product_category_route" class="md-body-2">{{ product_category_route }}</p>

            <md-button
              data-cy="select-category"
              v-if="permissions.products.manage && products_type !== shopify && !show_item_preview"
              class="md-raised md-primary"
              @click="show_categories = !show_categories"
              id="marketplace-categorization-button"
            >
              {{ 
                product_category_route ? $translate("products.categories.change_categories_modal") : $translate("products.categories.open_categories_modal")
              }}
            </md-button>

            <md-dialog :md-active.sync="show_categories" :md-click-outside-to-close="false">
              <md-dialog-title>{{ $translate('products.categories.title') }}</md-dialog-title>

              <categorization_selector @selected_categories="update_categories"/>

              <md-dialog-actions>
                <md-button class="md-primary" @click="show_categories = false">{{ $translate('close') }}</md-button>
              </md-dialog-actions>
            </md-dialog>
            <p v-if="products_type === shopify" class="md-body-2">{{ $translate("products.categories.shopify_message") }}</p>
          </div>

        <!-- Marketplace checkbox -->

          <div v-if="!is_cross_sold_product && !show_item_preview" class="product-detail__fullscreen__section product-detail__marketplace">
            <p class="md-title">{{ $translate("products.marketplace") }}:</p>
            <p class="md-body-1">{{ $translate("products.marketplace_message") }}</p>

            <div id="marketplace-product-checkbox">
              <md-checkbox
                data-cy="allow-on-marketplace"
                :disabled="!permissions.products.manage || products_type === shopify"
                v-model="allow_on_marketplace"
                class="md-primary product-detail__dimensions__checkbox"
              >
                {{ $translate("products.marketplace_checkbox") }}
              </md-checkbox>
            </div>
          </div>

        <!-- Cross selling module -->

          <div v-if="!is_cross_sold_product && !show_item_preview" class="product-detail__fullscreen__section product-detail__cross">
            <p class="md-title">{{ $translate("products.cross_sale.title") }}:</p>

            <section_description_preview :dense="true">
              <div class="md-body-1" v-html="$translate('products.cross_sale.message')"/>
            </section_description_preview>

            <div class="product-detail__cross-content">
              <div>
                <md-checkbox
                  data-cy="allow-on-cross"
                  :disabled="!permissions.products.manage || products_type === shopify"
                  v-model="enable_cross_selling"
                  class="md-primary product-detail__dimensions__checkbox"
                >
                  {{ $translate("products.cross_sale.checkbox") }}
                </md-checkbox>

                <div class="product-detail__cross-percentage" v-if="enable_cross_selling">
                  <p class="md-body-1">{{ $translate("products.cross_sale.percentage_message") }}</p>
                  <span class="md-body-2">{{ $translate("products.cross_sale.percentage") }}:</span>
                  <md-field>
                    <md-input
                      data-cy="product-cross-percentage"
                      :disabled="!permissions.products.manage"
                      :value="resale_percentage"
                      type="number"
                      placeholder="0"
                      @change="({ target }) => resale_percentage = target.value"
                    />
                    <span class="md-suffix">%</span>
                  </md-field>
                </div>
              </div>

              <div v-if="enable_cross_selling">
                <p class="md-body-2">{{ $translate("products.cross_sale.percentage_calculation") }}</p>
                <div class="product-detail__cross-calculation">
                  {{ resale_percentage_calculation }}
                </div>
              </div>

              <div class="product-detail__cross-currency" v-if="enable_cross_selling">
                <p class="md-body-2">{{ $translate("products.cross_sale.currency_title") }}</p>
                <div>{{ $translate("products.cross_sale.currency_message") }}</div>
                <md-field>
                  <label for="cross-currency">{{ $translate("products.cross_sale.label") }}</label>
                  <md-select v-model="cross_currency" name="cross-currency" id="cross-currency">
                    <md-option
                      v-for="currency in available_cross_currencies"
                      :key="currency"
                      :value="currency"
                    >{{ currency }}</md-option>
                  </md-select>
                </md-field>
              </div>
            </div>
          </div>

        <!-- Purchase currency -->

          <div v-if="!is_cross_sold_product && !show_item_preview" class="product-detail__fullscreen__section product-detail__purchase-currency">
            <p class="md-title">{{ $translate("products.purchase_price_currency.title") }}:</p>

            <section_description_preview :dense="true">
              <div class="md-body-1">{{ $translate("products.purchase_price_currency.message") }}</div>
            </section_description_preview>

            <md-field data-cy="currency" class="product-detail__purchase-currency">
              <md-select v-model="purchase_currency" name="currency" id="currency" :placeholder="$translate('products.purchase_price_currency.placeholder')">
                <md-option
                  v-for="currency in allowed_currencies"
                  :key="`currency-code-${currency}`"
                  :value="currency"
                >
                  {{ currency }}
                </md-option>
              </md-select>
            </md-field>

            <!-- Currency change message -->
              <div class="product-detail__currency-message">
                <br>
                <b>
                  {{ $translate("products.currency_change.first") }} 
                  <i @click="$router.push({ name: CONFIGURATION, params: { configuration_type: translations_module }})">{{ $translate("side_menu.translations") }}</i> 
                  {{ $translate("products.currency_change.second") }}
                </b>
              </div>
          </div>

        <!-- Variants -->

          <div class="product-detail__fullscreen__section product-detail__variants">
            <product_variants
              :is_product_without_variants="is_product_without_variants"
              :currency="currency"
              :currency_code="currency_code"
              :purchase_currency_sign="purchase_currency_sign"
              :render_variants="render_variants"
              :languages="[current_language_for_translations || user_info.language_code]"
              :is_shopify_product="is_shopify_product"
              :product_data="product_data"
              :component_fully_rendered="component_fully_rendered"
              :products_type="products_type"
              :current_language_for_translations="current_language_for_translations"
              :selected_language="selected_language"
              :requires_shipping="requires_shipping"
              :selected_distance_unit="selected_distance_unit"
              :update_variant="update_variant"
              :selected_weight_unit="selected_weight_unit"
              :show_item_preview="show_item_preview"
              @toggle_variant_dimensions_modal="variant_dimensions_modal = true"
              @switch_selected_variant_index="index => selected_variant_index = index "
            />

            <md-dialog :md-active.sync="variant_dimensions_modal">
              <md-dialog-title>{{ $translate("products.variant_dimensions") }}</md-dialog-title>

              <md-dialog-content>
                <dimensions_selector
                  :update_variant="update_variant"
                  :permissions="permissions"
                  :variants="variants"
                  :show_item_preview="false"
                  :selected_distance_unit="selected_distance_unit"
                  :selected_weight_unit="selected_weight_unit"
                  :show_distances="products_type === imported"
                  :selected_variant_index="selected_variant_index"
                  @update_distance_unit="unit => selected_distance_unit = unit"
                  @update_weight_unit="unit => selected_weight_unit = unit"
                />
              </md-dialog-content>

              <md-dialog-actions>
                <md-button class="md-primary" @click="variant_dimensions_modal = false">{{ $translate("close") }}</md-button>
              </md-dialog-actions>
            </md-dialog>
          </div>
        </div>

        <br/>

        <div class="product-detail__bottom-actions">
          <md-button
            v-if="permissions.products.manage && !show_item_preview"
            class="md-fab md-raised product-detail__delete__button"
            @click="delete_or_restore"
            :class="{
              'md-primary': product_data.change_type === deleted,
              'md-accent': product_data.change_type !== deleted
            }"
            v-observe-visibility="toggle_delete_button_visibility"
          >
            <md-icon>{{ product_data.change_type === deleted ? "restore" : "delete"}}</md-icon>
            <md-tooltip md-direction="top">
              {{ $translate(product_data.change_type === deleted ? "restore" : "delete") }}
              {{ $translate("products.product") }}
            </md-tooltip>
          </md-button>
          <save_button
            :show="show_save_button && is_delete_button_visible"
            :section="$translate('side_menu.product_admin')"
            :product_display="true"
            @save="$emit('save_data')"
            @cancel="$emit('discard_data')"
          />
        </div>

        <transition name="slide-in-languages">
          <div
            v-show="products_type === imported && !are_flags_visible && all_available_languages.length > 1 && !show_item_preview"
            class="product-detail__languages"
          >
            <language_switcher
              :allow_only_enabled="true"
              :show_all_languages="false"
              :selected_language="selected_language"
              :bottom_border="false"
              :missing_languages="missing_language_translations"
              :enable_mobile_view="true"
              @change-language="language => selected_language = language"
            />
          </div>
        </transition>
      </div>

    <!-- Small product detail -->

      <small_product_detail
        v-else
        :main_product_image="get_main_product_image"
        :is_product_without_variants="is_product_without_variants"
        :product_options="product_data.options || []"
        :title="title"
        :product_data="product_data"
        :show_list_item="show_list_item"
        :languages="[
          current_language_for_translations,
          user_info.language_code
        ]"
        @click.native="$emit('selected_product', product_index)"
      />

    <md-snackbar
      md-position="center"
      :md-active.sync="show_paypal_error"
      :md-duration="15000"
      class="md-warning"
    >
      {{ $translate("errors.product.paypal_connection_for_cross_selling") }}

      <md-button @click="$router.push(`/Admin/configuration/${payment_gateway}`)">
        {{ $translate("here") }}
      </md-button>
    </md-snackbar>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex"
import { v4 as uuid } from "uuid"
import draggable from "vuedraggable"
import text_and_html_editor from "../../utils/text_and_html_editor"
import is_value_a_color from "../../../../Shared/methods/is_value_a_color"
import { description_from_message_splitter, image_dimension_names } from "../../../../../data/other_constants"
import get_description_from_product_description from "../../../../Shared/methods/get_description_from_product_description"
import get_message_or_types_from_product_description from "../../../../Shared/methods/get_message_or_types_from_product_description"
import highlighted_products_icon from "../../../../Shared/components/icon_components/highlighted-products-icon"
import popular_products_icon from "../../../../Shared/components/icon_components/popular-products-icon"
import {
  UPDATE_IMAGES,
  UPDATE_TITLE,
  UPDATE_PRODUCT_AVAILABILITY,
  UPDATE_DESCRIPTION,
  UPDATE_VARIANTS,
  DELETE_OR_RESTORE_PRODUCT,
  UPDATE_OPTIONS,
  UPDATE_OPTION_VALUES,
  UPDATE_MARKETPLACE_AVAILABILITY,
  UPDATE_PRODUCT_CATEGORIZATION,
  UPDATE_TAGS,
  UPDATE_PURCHASE_CURRENCY,
  UPDATE_CROSS_CURRENCY,
  UPDATE_POPULAR_OR_HIGHLIGHTED_PRODUCTS,
  UPDATE_CROSS_SELLING_ABILITY,
  UPDATE_RESALE_PERCENTAGE,
} from "../../../stores/Admin/products/constants"
import {
  created, deleted, PRODUCTS_STORE, ADMIN, imported, CONFIGURATION_STORE, USER_STORE,
  shopify, default_currency, CHARTJS_DOWNLOAD_URL,
  translations as translations_module, CONFIGURATION, PRODUCTS, CONTENT_STORE, updated, payment_gateway, severities
} from "../../../constants/others_constants"
import { currency_signs, allowed_currencies, dashed_date_format, message_durations } from "../../../../Shared/constants/other"
import validate from "../../../methods/validations/product_detail"
import {
  SHOPIFY_DATA_UPDATED, IMPORTED_DATA_UPDATED, UPDATE_LOADER, FETCH_AND_ASSING_MOMENT_LIBRARY, UPDATE_IMAGES_FOR_DELETE, ADD_GLOBAL_ERROR
} from "../../../stores/Admin/constants"
import { CLIENT_STORE } from "../../../../Client/constants/other"
import { distance_units, weight_units } from "../../../constants/units"
import dimensions_selector from "./dimensions_selector"
import categorization_selector from "./categorization_selector"
import { check_paypal_payout_connection, get_product_categories } from "../../../constants/endpoints/other"
import is_currency_symbol from "../../../../Shared/methods/is_currency_symbol"
import language_switcher from "../language_switcher"
import get_correct_translation_value from "../../../../Shared/methods/get_correct_translation_value"
import sanitize_image_route from "../../../methods/sanitize_image_route"
import get_image_src from "../../../../Shared/methods/get_image_src"
import get_correct_first_image from "../../../../Shared/methods/get_correct_first_image"
import small_product_detail from "./small_product_detail"
import product_variants from "./product_variants"
import product_favorite_selector from "./product_favorite_selector"
import paypal_connection_check from "../../../methods/paypal_connection_check"
import save_button from "../save_button"
import section_description_preview from "../section_description_preview"

export default {
  components: {
    text_and_html_editor,
    dimensions_selector,
    categorization_selector,
    draggable,
    language_switcher,
    highlighted_products_icon,
    popular_products_icon,
    small_product_detail,
    product_variants,
    product_favorite_selector,
    save_button,
    section_description_preview
  },
  props: {
    product_data: {
      type: Object,
      required: true
    },
    collection_index: {
      type: [Number, null],
      required: false,
      default: 0
    },
    collection_title: {
      type: String,
      required: false,
      default: ""
    },
    product_index: {
      type: Number,
      required: false,
      default: 0
    },
    collections_type: {
      type: String,
      required: false,
      default: ""
    },
    products_type: {
      type: String,
      required: false,
      default: ""
    },
    fullscreen: {
      type: Boolean,
      required: false,
      default: true
    },
    is_shopify_product: {
      type: Boolean,
      required: false,
      default: false
    },
    image_route: {
      type: String,
      required: false,
      default: ""
    },
    show_list_item: {
      type: Boolean,
      required: false,
      default: false
    },
    show_item_preview: {
      type: Boolean,
      required: false,
      default: false
    },
    show_save_button: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data() {
    return {
      show_product_detail: false,
      component_fully_rendered: false,
      image_being_edited: false,
      uploading_image: false,
      editing_image: false,
      show_categories: false,
      chart_loaded: false,
      render_variants: true,
      are_flags_visible: true,
      is_delete_button_visible: false,
      selecting_position: false,
      variant_dimensions_modal: false,
      show_paypal_error: false,
      image_alt_text: null,
      selected_image: {},
      uploaded_images: [],
      product_category_route: "",
      new_tag_value: "",
      selected_language: "",
      add_product_to: "",
      no_of_text_editor_calls: 0, // Needed because the text editor fires event on load
      selected_variant_index: 0,
      deleted,
      distance_units,
      weight_units,
      imported,
      shopify,
      allowed_currencies,
      translations_module,
      image_dimension_names,
      CONFIGURATION,
      payment_gateway
    }
  },
  computed: {
    ...mapState(CONFIGURATION_STORE, ["translations", "shipping_options", "paypal_config", "project_config"]),
    ...mapState(CONTENT_STORE, ["highlighted_products", "popular_products"]),
    ...mapState(CLIENT_STORE, ["project_styles"]),
    ...mapState(USER_STORE, ["permissions", "user_info"]),
    ...mapState(ADMIN, ["$moment"]),
    show_favorite_selector_on_small_detail() {
      return !this.show_item_preview && this.collection_index !== undefined
    },
    is_cross_sold_product() {
      return Boolean(this.product_data.resale_percentage && this.product_data.project_id)
    },
    is_products_in_highlighted() {
      return this.highlighted_products?.includes(this.product_data.id)
    },
    is_products_in_popular() {
      return this.popular_products?.includes(this.product_data.id)
    },
    available_cross_currencies() {
      // Currencies available for this product
      return Object
        .entries(this.product_data?.translations)
        .filter(([lang]) => this.translations[lang])
        .map(([_, { currency_code }]) => currency_code)
        .filter(val => val)
    },
    missing_language_translations() {
      const languages = this.variants.flatMap(({ translations }) => {
        if (!translations) return []

        const missing_languages = []
        const keys_to_check = [
          // Only add options if more than one (Default option)
          ...(
            this.product_data?.options?.length === 1 ?
              [] : this.product_data?.options?.map((_, ind) => `option${ind + 1}`) || []
          ),
          "price"
        ]

        this.all_available_languages.forEach(lang => !translations[lang] && missing_languages.push(lang))
        this.all_available_languages.forEach(lang =>
          !keys_to_check.every(key => translations[lang] && (translations[lang][key] || translations[lang][key] === 0)) &&
          missing_languages.push(lang)
        )

        return Array.from(new Set(missing_languages))
      })

      return Array.from(new Set(languages))
    },
    get_main_product_image() {
      return get_correct_first_image(this.product_data)
    },
    currency_code() {
      return (
        (
          // Use product translations to get currency code when previewing cross sold product
          this.show_item_preview ?
            this.product_data.translations :
            this.translations
        )[this.selected_language] || {}).currency_code || default_currency
    },
    currency() {
      return (
        (
          this.show_item_preview ?
            this.product_data.translations :
            this.translations
        )[this.selected_language] || {}
      )?.[this.show_item_preview ? "currency_code" : "currency_symbol"] ||
        currency_signs[this.currency_code] ||
        this.currency_code
    },
    correct_flag_image() {
      return `/static/icons/countries/${this.selected_language}.svg`
    },
    current_language_for_translations() {
      return this.products_type === imported ? this.selected_language : undefined
    },
    purchase_currency: {
      get() {
        return this.product_data.purchase_currency || ""
      },
      set(currency) {
        if (!currency || currency === this.purchase_currency || !this.component_fully_rendered) return

        this.update_purchase_currency([
          this.products_type,
          this.product_data.id,
          currency,
        ])

        this.$nextTick(() => {
          this.product_data_updated("purchase_currency")
          this.$nextTick(() => this.validate())
        })
      }
    },
    cross_currency: {
      get() {
        return this.product_data.cross_base_currency || ""
      },
      set(currency) {
        if (!this.component_fully_rendered) return

        this.update_cross_currency([
          this.products_type,
          this.product_data.id,
          currency,
        ])

        this.$nextTick(() => {
          this.product_data_updated("cross_currency")
          this.$nextTick(() => this.validate())
        })
      }
    },
    purchase_currency_sign() {
      return currency_signs[this.purchase_currency] || ""
    },
    open_image_modal: {
      get() {
        return !!this.selected_image.src || this.uploading_image
      },
      set() {
        this.selected_image = {}
        this.uploading_image = false
      }
    },
    product_tags: {
      get() {
        return this.product_data.tags || []
      },
      set(updated_tags) {
        if (!this.component_fully_rendered) return

        this.update_tags([
          this.products_type,
          this.product_data.id,
          updated_tags
        ])
        this.product_data_updated("product_tags")
        this.$nextTick(() => this.validate())
      }
    },
    title: {
      get() {
        return this.get_correct_translation_value(this.product_data, "title", false, !this.fullscreen)
      },
      set(new_title) {
        if (!this.component_fully_rendered) return

        this.update_title([
          this.products_type,
          this.product_data.id,
          new_title,
          this.current_language_for_translations
        ])
        this.product_data_updated("title")
        this.$nextTick(() => this.validate())
      }
    },
    resale_percentage: { // TODO make sure user has Paypal setup including account email
      get() {
        return this.product_data.resale_percentage || 0
      },
      set(percentage) {
        const new_percentage = Number(percentage)

        if (!this.component_fully_rendered && !isNaN(new_percentage)) return

        this.update_resale_percentage([
          this.products_type,
          this.product_data.id,
          new_percentage,
        ])
        this.product_data_updated("resale_percentage")
        this.$nextTick(() => this.validate())
      }
    },
    resale_percentage_calculation() {
      return `${this.currency_code} ${Number(
        get_correct_translation_value(this.variants[0], "price", [this.selected_language]) || 0
      ) * ((100 - this.resale_percentage) / 100)}`
    },
    resale_profit_calculation() {
      return `${this.currency_code} ${Math.ceil(Number(
        get_correct_translation_value(this.variants[0], "price", [this.selected_language]) || 0
      ) * (1 - ((100 - this.resale_percentage) / 100)))}`
    },
    images: {
      get() {
        return (this.product_data.images || [])
      },
      set(new_images) {
        if(!new_images || !this.component_fully_rendered) return

        this.update_images([
          this.products_type,
          this.product_data.id,
          new_images
        ])
        this.product_data_updated("images")
      }
    },
    description() {
      return get_description_from_product_description(this.get_correct_translation_value(this.product_data, "body_html", false, true))
    },
    message() {
      return get_message_or_types_from_product_description(this.get_correct_translation_value(this.product_data, "body_html", false, true), true)
    },
    variants: {
      get() {
        return this.product_data.variants || []
      },
      set(new_variants) {
        if(!new_variants || !this.component_fully_rendered) return

        this.update_variants([
          this.products_type,
          this.product_data.id,
          new_variants,
          this.current_language_for_translations
        ])
        this.product_data_updated("variants")
        this.$nextTick(() => this.validate())
      }
    },
    availability: {
      get() {
        return Boolean(this.product_data.published_at)
      },
      set(updated_availability) {
        if(updated_availability === undefined || !this.component_fully_rendered) return

        const published_at = updated_availability ? new Date().toISOString() : null
        const published = Boolean(updated_availability)
        const published_scope = updated_availability ? "global" : ""

        this.update_availability([
          this.products_type,
          this.product_data.id,
          published_at,
          published,
          published_scope
        ])
        this.product_data_updated("availability")
      }
    },
    is_product_without_variants() {
      return this.variants.length === 1
    },
    no_of_text_editors_with_value() {
      return [this.message, this.description].filter(value => value).length
    },
    selected_weight_unit: {
      get() { return (this.variants[0] || {}).weight_unit || "" },
      set(unit) {
        this.variants = this.variants.map(variant => ({ ...variant, weight_unit: unit || weight_units[0] })) // default to g
      }
    },
    selected_distance_unit: {
      get() { return (this.variants[0] || {}).distance_unit || "" },
      set(unit) {
        this.variants = this.variants.map(variant => ({ ...variant, distance_unit: unit || distance_units[0] })) // default to cm
      }
    },
    requires_shipping: {
      get() { return (this.variants[0] || {}).requires_shipping },
      set(value) {
        this.variants = this.variants.map(variant => ({ ...variant, requires_shipping: value }))
        this.$nextTick(() => this.validate())
      }
    },
    allow_on_marketplace: {
      get() { return this.product_data.allow_on_marketplace },
      set(value) {
        if (!this.component_fully_rendered) return

        this.update_marketplace_availability([
          this.products_type,
          this.product_data.id,
          value
        ])
        this.product_data_updated("allow_on_marketplace")
        this.$nextTick(() => this.validate())
      }
    },
    enable_cross_selling: {
      get() { return this.product_data.enable_cross_selling },
      async set(value) {
        if (!this.component_fully_rendered) return

        // If setting as true, make sure paypal correctly connected
        if (value) {
          const is_paypal_connected = await this.has_user_connected_paypal()
          if (!is_paypal_connected) return this.show_paypal_error = true
        }

        this.update_cross_selling_ability([
          this.products_type,
          this.product_data.id,
          value
        ])

        // Auto select cross currency if only one available
        if (this.available_cross_currencies.length === 1) {
          this.cross_currency = this.available_cross_currencies[0]
        }

        this.product_data_updated("enable_cross_selling")
        this.$nextTick(() => this.validate())
      }
    },
    all_available_languages() {
      return Object.keys(this.translations)
    },
  },
  watch: {
    product_data: {
      handler({ id }, { id: new_id }) {
        if (id !== new_id) window.firebase?.analytics()?.logEvent("view_product", {
          store_id: this.project_config.project_id,
          collection: this.collection_title,
          product: this.title
        });
      },
      deep: true
    },
    "product_data.categories"(new_val = [], old_val = []) {
      if (old_val.join("") !== new_val.join("") && this.fullscreen && new_val.length) {
        this.product_category_route = ""
        this.get_product_category_route()
        this.$nextTick(() => this.validate())
      }
    },
    "product_data.change_type"(new_val, old_val) {
      if (old_val !== new_val) this.$nextTick(() => this.validate())
    },
    async current_language_for_translations() {
      this.render_variants = false
      await this.validate()
      this.$nextTick(() => this.render_variants = true)
    },
    add_product_to(new_val) {
      if (new_val) {
        this.update_popular_or_highlighted([this.product_data.id, new_val === "popular"])
        this.add_product_to = ""
        this.selecting_position = false
      }
    }
  },
  async mounted() {
    if (this.fullscreen) {
      window.firebase?.analytics()?.logEvent("view_product", {
        store_id: this.project_config.project_id,
        collection: this.collection_title,
        product: this.title
      });

      if (this.product_data.categories?.length) this.get_product_category_route()

      if (!window.Chart) {
        await this.$loadScript(CHARTJS_DOWNLOAD_URL)
        await new Promise((resolve) => {
          const interval = setInterval(() => {
            if (window.Chart) {
              clearInterval(interval)
              this.chart_loaded = true
              resolve()
            }
          }, 300);
        })
      } else this.chart_loaded = true

      if (this.show_item_preview) this.selected_language = Object?.keys(this.product_data.translations)?.[0]
    }
    else this.selected_language = this.user_info.language_code

    this.$nextTick(() => this.component_fully_rendered = true)

    await this.fetch_and_asing_moment()
  },
  methods: {
    ...mapMutations(PRODUCTS_STORE, {
      update_images: UPDATE_IMAGES,
      update_title: UPDATE_TITLE,
      update_resale_percentage: UPDATE_RESALE_PERCENTAGE,
      update_availability: UPDATE_PRODUCT_AVAILABILITY,
      update_description: UPDATE_DESCRIPTION,
      update_variants: UPDATE_VARIANTS,
      update_options: UPDATE_OPTIONS,
      delete_or_restore_product: DELETE_OR_RESTORE_PRODUCT,
      update_option_values: UPDATE_OPTION_VALUES,
      update_marketplace_availability: UPDATE_MARKETPLACE_AVAILABILITY,
      update_cross_selling_ability: UPDATE_CROSS_SELLING_ABILITY,
      update_categorization: UPDATE_PRODUCT_CATEGORIZATION,
      update_tags: UPDATE_TAGS,
      update_purchase_currency: UPDATE_PURCHASE_CURRENCY,
      update_cross_currency: UPDATE_CROSS_CURRENCY
    }),
    ...mapMutations(ADMIN, {
      imported_data_updated: IMPORTED_DATA_UPDATED,
      shopify_data_updated: SHOPIFY_DATA_UPDATED,
      update_loader: UPDATE_LOADER,
      image_delete_toggle: UPDATE_IMAGES_FOR_DELETE,
      add_global_message: ADD_GLOBAL_ERROR
    }),
    ...mapActions(ADMIN, {
      fetch_and_asing_moment: FETCH_AND_ASSING_MOMENT_LIBRARY
    }),
    ...mapActions(PRODUCTS_STORE, {
      update_popular_or_highlighted: UPDATE_POPULAR_OR_HIGHLIGHTED_PRODUCTS
    }),
    is_value_a_color,
    get_description_from_product_description,
    is_currency_symbol,
    get_image_src,
    toggle_delete_button_visibility(is_visible) {
      if (process.env.VUE_APP_TESTING === "true") return

      this.$emit("toggle_top_save_button", is_visible)
      this.is_delete_button_visible = is_visible
    },
    async has_user_connected_paypal() {
      this.update_loader()
      const is_paypal_connected = await paypal_connection_check(this.paypal_config.client_id)
      const { data: payout_data } = await check_paypal_payout_connection(this.paypal_config)

      if (!payout_data) this.add_global_message({
        severity: severities.WARNING,
        message: "payouts_not_enabled",
        duration: message_durations.long
      })

      this.update_loader(false)

      return Boolean(is_paypal_connected && this.paypal_config.email && payout_data)
    },
    update_variant(variant_ind, key, value) {
      if (key === "compare_at_price") {
        value =
          (Number(value) >= Number(this.get_correct_translation_value(this.variants[variant_ind], "price", this.languages)) ||
          !this.get_correct_translation_value(this.variants[variant_ind], "price", this.languages)) ?
            value : this.get_correct_translation_value(this.variants[variant_ind], "price", this.languages)
      }
      if (key === "price") value = (isNaN(value) || !value) ? 0 : Number(value)
      if (key.includes("option")) {
        this.update_option_values([
          this.products_type,
          this.product_data.id,
          Number(key.replace("option", "")) - 1,
          value,
          variant_ind,
          this.current_language_for_translations
        ])
      }

      const temp_variants = this.variants.map((variant, index) => {
        if(index === variant_ind || variant_ind === undefined) {
          if (
            ["price", "compare_at_price", "option1", "option2", "option3"].includes(key) &&
            this.current_language_for_translations
          ) {
            if (!variant.translations) variant.translations = {}
            if (!variant.translations[this.current_language_for_translations]) variant.translations[this.current_language_for_translations] = {}

            variant.translations[this.current_language_for_translations][key] = value
          } else variant[key] = value

          if ("price" === key && !this.is_shopify_product) {
            const date = this.$moment().format(dashed_date_format)
            variant.price_history = {
              ...(variant.price_history || {}),
              [date]: {
                ...((variant.price_history || {})[date] || {}),
                [this.current_language_for_translations]: {
                  currency: this.currency_code,
                  value
                }
              }
            }
          }

          variant.change_type = variant.change_type === created ? created : updated
        }
        return variant
      })

      this.variants = temp_variants

      if (
        key === "price" &&
        this.variants[variant_ind].compare_at_price &&
        Number(value) > Number(this.variants[variant_ind].compare_at_price)
      ) {
        this.update_variant(variant_ind, "compare_at_price", value)
      }
    },
    get_correct_translation_value(item, key, get_language, allow_other = false) {
      return get_correct_translation_value(item, key, [
        this.current_language_for_translations,
      ], allow_other, get_language)
    },
    add_tag() {
      if (this.new_tag_value) {
        this.product_tags = [...this.product_tags, this.new_tag_value]

        this.new_tag_value = ""
      }
    },
    remove_tag(tag) {
      if (this.permissions.products.manage) this.product_tags = this.product_tags.filter(tag_val => tag_val !== tag)
    },
    async get_product_category_route() {
      if (!this.product_data.categories) return
      const { data } = await get_product_categories({ category_ids: this.product_data.categories, get_parents: true })
      this.product_category_route = data
    },
    update_categories(final_categories) {
      this.update_categorization([
        this.products_type,
        this.product_data.id,
        final_categories
      ])
      this.product_data_updated("update_categories")
      this.show_categories = false
    },
    user_updated_description(value, is_message = false) {
      if (
        this.component_fully_rendered &&
        this.no_of_text_editor_calls >= this.no_of_text_editors_with_value &&
        value !== "<p><br></p>" // Default editor value
      ) {
        const assemble_product_description = `${
          is_message ? this.description : value}${
          description_from_message_splitter} {${
          is_message ? value : this.message}
        }`

        this.update_description([
          this.products_type,
          this.product_data.id,
          assemble_product_description,
          this.current_language_for_translations
        ])
        this.product_data_updated("update_description")
      }
      if (this.no_of_text_editor_calls < this.no_of_text_editors_with_value) this.no_of_text_editor_calls++
    },
    show_image(image, index, edited = false) {
      this.uploading_image = false
      this.image_being_edited = edited

      this.selected_image = {
        ...image,
        index
      }
    },
    delete_image({ id: image_id }) {
      const correct_image = this.images.find(({ id }) => id === image_id)
      this.image_delete_toggle({
        images: correct_image.thumb_src ?
          [correct_image.src, correct_image.thumb_src] : [...Object.values(correct_image.src)],
        store_type: PRODUCTS
      })
      this.images = this.images.filter(({ id }) => id !== image_id)
    },
    add_images(files) {
      Array.from(files).forEach((file) => {
        try {
          const reader = new FileReader()

          reader.addEventListener("load", () => {
            const image = {
              src: reader.result,
              id: uuid(),
              file,
              change_type: created,
              route: sanitize_image_route(this.title)
            }

            this.uploaded_images.push(image)
          }, false)

          reader.readAsDataURL(file)
        } catch (error) {
          console.log(error)
        }
      })
    },
    async save_images() {
      this.images = [
        ...this.images,
        ...this.uploaded_images.map(image => ({ ...image, alt_text: this.image_alt_text }))
      ]

      this.image_alt_text = null
      this.uploading_image = false
      this.uploaded_images = []
    },
    delete_or_restore() {
      this.delete_or_restore_product([this.products_type, this.product_data.id, this.product_data.change_type === deleted])
      this.product_data_updated("delete_or_restore")
    },
    product_data_updated(update_type = "") {
      window.firebase?.analytics()?.logEvent("update_product", {
        store_id: this.project_config.project_id,
        collection: this.collection_title,
        product: this.title,
        update_type
      });

      this[`${this.products_type}_data_updated`](true)
    },
    ...validate
  }
}
</script>

<style lang="scss">
  @use "../../../../../styles/_global-constants" as *;
  @use "../../../../../styles/_admin-constants" as *;
  @use "../../../../../styles/admin_image_upload.scss";
  @use "../../../../../styles/flag_change.scss";

  .product-detail {
    position: relative;
    padding: $default-size 0;

    @media (max-width: $mobile) {
      padding: $half-default-size 0
    }

    &__preview-text {
      position: relative;

      div {
        max-height: 500px;
        overflow: auto;

        &:first-child {
          padding-bottom: $default-size;
        }
      }

      &-overflow {
        position: absolute;
        bottom: 0;
        left: 0;
        content: "";
        height: $default-size;
        width: 100%;
        background: linear-gradient(0deg, $pure-white 9%, rgba($pure-white, 0.27354691876750703) 76%);
      }
    }

    &--preview {
      margin-top: 0 !important;

      .product-detail__title .md-field {
        width: 100%;
      }
    }

    #marketplace-product-checkbox {
      display: inline-block;
    }

    &--list-item {
      padding: 0 0 $half-default-size;
    }

    &__speed-dial {
      position: relative;
      float: right;

      .product-favorite {
        display: none;

        @media (max-width: $tablet--small) {
          display: block;
          position: relative;
          top: 0;
          right: 0;
        }
      }

      .md-fab {
        width: $icon-size;
        height: $icon-size;
      }

      .md-speed-dial-content {
        position: absolute;
        top: $icon-size;
        left: 0;
        width: $icon-size;
        z-index: $z-index--hidden;
      }

      &:hover {
        .md-speed-dial-content {
          z-index: 1;
        }
      }
    }

    &__description {
      grid-area: description;
    }

    &__message {
      grid-area: message;
    }

    &__purchase-currency {
      grid-area: purchase-currency;
    }

    &__tags {
      grid-area: tags;
    }

    &__marketplace {
      grid-area: marketplace;
    }

    &__categorization {
      grid-area: categorization;
    }

    &__variants {
      grid-area: variants;
      
      @media (max-width: $tablet) {
        overflow-x: auto;
      }
    }

    &__title {
      grid-area: title;
      padding: $default-size;

      @media (max-width: $tablet--small) {
        padding: 0;
      }

      .md-field input {
        font-size: 25px !important;
      }
    }

    &__cross {
      grid-area: cross;

      &-preview {
        grid-area: cross-preview;

        b {
          font-size: 16px;
          vertical-align: middle;
          margin-left: $half-default-size;
        }

        > div {
          padding: $half-default-size 0;

          &:last-child {
            padding-bottom: 0;
          }
        }

        &-percentage, &-price {
          border-bottom: 1px solid $material-grey;
        }

        &-profit {
          p {
            margin: 0 0 $half-default-size;
          }

          div {
            color: $green;
            font-size: 30px;
            font-weight: bold;
            text-align: center;
          }
        }
      }

      &-percentage {
        border-top: 1px solid $material-grey;

        .md-field {
          width: 50px !important;
          margin-left: $half-default-size !important;
        }
      }

      &-currency {
        grid-area: currency;
        margin-top: $default-size !important;
        border-top: 1px solid $material-grey;
        
        p {
          margin-bottom: 0;
        }

        .md-field {
          padding-top: $half-default-size !important;
          width: 200px !important;

          label {
            top: 16px !important;
          }

          &.md-has-value label {
            top: 0 !important;
          }
        }
      }

      &-content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
          "percentage calculation"
          "currency currency";
        column-gap: $default-size;
        max-width: 1200px;

        @media (max-width: $tablet--small) {
          grid-template-columns: 1fr;
          grid-template-areas:
            "percentage"
            "calculation"
            "currency";
        }

        .md-field {
          padding-top: 0;
          min-height: 0;
        }

        > div {
          &:first-child {
            grid-area: percentage;
          }
          &:nth-child(2) {
            grid-area: calculation;
            position: relative;
            padding-left: $half-default-size;

            &::before {
              position: absolute;
              left: -#{$half-default-size};
              top: 0;
              height: 100%;
              width: 1px;
              background-color: $material-grey;
              content: "";

              @media (max-width: $tablet--small) {
                left: 0;
                top: $half-default-size;
                height: 1px;
                width: 100%;
              }
            }
          }
        }
      }

      &-calculation {
        grid-area: calculation;
        padding: 0 $default-size;
        font-weight: bold;
        font-size: $title-size--large;
        color: $green;
        text-align: center;
        line-height: normal;

        @media (max-width: $tablet--small) {
          padding: 0 $half-default-size;
          font-size: $title-size--medium;
        }
      }
    }

    &__remove-icon {
      position: relative;

      &::before, &::after {
        position: absolute;
        content: "";
        width: 70%;
        height: 1px;
        background-color: $pure-black;
        left: 15% !important;
        top: calc(50% - 1px) !important;
        opacity: 1 !important;
      }

      &::before {
        transform: rotate(45deg);
      }
      &::after {
        transform: rotate(-45deg);
      }
    }

    &__top {
      position: relative;
      margin-top: $half-default-size;

      &--has-multiple-languages {
        min-height: 80px;

        .product-detail__speed-dial {
          position: absolute;
          top: 0;
          right: 0;
          top: $material-button-small;
        }
      }

      .language-switcher {
        display: inline-block;
        width: 100%;
        vertical-align: middle;
      }
    }

    &--detail {
      margin-top: 30px;
      padding: 0 0 $default-size;
      border-top: 1px solid $material-grey;

      @media (max-width: $tablet--small) {
        & > .product-favorite {
          display: none;
        }
      }
    }

    &--deleted {
      .small-product-detail {
        background: rgba($material-red, 0.5);
        background: linear-gradient(359deg, #f17272 2%, rgba(0,0,0,0) 99%);
        opacity: 0.5;
      }

      .product-detail__fullscreen {
        max-height: 800px;
        overflow: hidden;
      }
    }

    &__languages {
      position: fixed;
      bottom: $default-size;
      right: $default-size;
      z-index: 4;
    }

    &__question-mark {
      i {
        width: $font-size--medium;
        min-width: $font-size--medium;
        height: $font-size--medium;
        font-size: $font-size--medium !important;
      }

      &-input {
        display: inline-flex !important;
        width: calc(100% - 17px - #{$font-size--medium} - #{$half-default-size}) !important;
      }
    }

    &__purchase-currency.md-field {
      max-width: 200px;

      input.md-input {
        font-size: $material-size !important;
      }
    }

    &__language {
      height: 30px !important;
    }

    &__currency-message {
      i {
        color: $blue;
        cursor: pointer;
      }
    }

    &__fullscreen {
      display: grid;
      width: 100%;
      justify-content: start;
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        "title tags"
        "gallery gallery"
        "message description"
        "categorization cross"
        "marketplace cross"
        "purchase-currency dimensions"
        "variants variants";
      column-gap: $double-default-size;
      row-gap: $double-default-size;

      @media (max-width: $tablet--small) {
        grid-template-columns: 1fr;
        grid-template-areas:
          "title"
          "tags"
          "gallery"
          "message"
          "description"
          "categorization"
          "marketplace"
          "cross"
          "purchase-currency"
          "dimensions"
          "variants";
      }

      &--preview {
        grid-template-areas:
          "title cross-preview"
          "gallery gallery"
          "message description"
          "variants variants" !important;
        column-gap: $half-default-size;
        row-gap: $half-default-size;

        .product-detail__fullscreen__section::after {
          bottom: -18px;
        }
      }

      &--cross-sold {
        grid-template-areas:
          "title tags"
          "gallery gallery"
          "message description"
          "variants variants";
      }

      &__section {
        position: relative;
        max-width: 100%;

        tbody img {
          width: $double-default-size;
          height: $double-default-size;
          object-fit: contain;
          object-position: center;
        }

        &::after {
          content: "";
          position: absolute;
          bottom: -31px;
          width: 100%;
          left: 0;
          height: 1px;
          background: $material-grey--darker;
        }

        &:last-child::after {
          display: none;
        }
      }

      .md-field {
        display: inline-flex;
        width: 100%;
        margin: 0;
        align-items: baseline;
        max-width: 500px;

        input {
          width: 100%;
        }
      }

      &__checkbox {
        display: block;
      }

      &__add {
        float: right;
      }
    }

    &__tags {
      span {
        position: relative;
        display: inline-block;
        padding: $half-default-size $default-size;
        margin: 0 $half-default-size $half-default-size 0;
        background-color: $blue;
        color: $pure-white;
        border-radius: $border-radius;
        vertical-align: middle;
        overflow: hidden;
        cursor: pointer;

        &::after {
          position: absolute;
          content: "✕";
          top: 100%;
          left: 0;
          width: 100%;
          height: 100%;
          line-height: 40px;
          text-align: center;
          background-color: $material-red;
          transition: .2s ease-in;
          font-size: 20px;
          opacity: 0.9;
        }

        &:hover {
          &::after {
            transform: translateY(-100%);
          }
        }
      }

      .md-button {
        display: inline-block;
        vertical-align: middle;
      }

      .md-field {
        margin-right: $half-default-size;
      }

      .md-button {
        margin: 0;

        * {
          background-color: $blue;
        }
      }

      div {
        display: inline-block;
        padding: $half-default-size;
        border-radius: $border-radius;
        vertical-align: middle;
        background-color: $material-grey--light;

        .md-field {
          display: inline-flex;
          width: 80px;
          padding: 0;
          height: 30px;
          min-height: 30px;
          vertical-align: bottom;
          align-items: baseline;

          input {
            font-size: 15px !important;
          }
        }
      }
    }

    &__gallery {
      grid-area: gallery;

      &__wrapper {
        display: flex;
        align-items: center;
        padding: $default-size $default-size 0 0;

        & > div {
          max-width: calc(100% - 120px);
          margin-left: $default-size;
        }

        @media (max-width: $tablet--small) {
          display: block;
          padding: 0;

          & > div {
            max-width: 100%;
            margin-left: 0;
          }
        }
      }

      &__image {
        $max-image-size: 200px;
        $max-image-size--small: 100px;

        position: relative;
        display: inline-block;
        max-width: $max-image-size;
        max-height: $max-image-size;
        margin: 0 $default-size $default-size 0;
        vertical-align: top;

        @media (max-width: $tablet--small) {
          margin: 0 $half-default-size $half-default-size 0;
        }

        img {
          max-width: $max-image-size;
          max-height: $max-image-size;
          padding: $half-default-size;
          border-radius: $border-radius;
          object-fit: contain;
          object-position: center;
          box-shadow: 0 0px 10px 0px $shadow-color;

          @media (max-width: $tablet--small) {
            max-width: $max-image-size--small;
            max-height: $max-image-size--small;
          }
        }

        &:hover {
          .product-detail__gallery__edit {
            top: 10px;
            opacity: 1;
          }
        }
      }

      &__modal {
        padding: 0 $default-size;
        text-align: center;

        button {
          margin: $default-size;

          @media (max-width: $tablet--small) {
            margin: $half-default-size;
          }
        }
      }

      &__edit {
        position: absolute;
        top: -5px;
        left: calc(100% - 50px);
        opacity: 0;
        transition: 250ms ease-in-out;
        cursor: pointer;

        .md-fab {
          width: $icon-size;
          height: $icon-size;
        }
      }

      &__button {
        margin: 0 $default-size $default-size !important;
        vertical-align: middle !important;

        @media (max-width: $tablet--small) {
          margin: 0 0 $default-size !important;
          width: $icon-size !important;
          height: $icon-size !important;
        }
      }
    }

    &__delete {
      &__overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: calc(100% - 135px); // 135px is height of .product-detail__bottom-actions
        background: $material-red;
        background: linear-gradient(359deg, rgb(255 192 192) 2%, rgba(0,0,0,0) 99%);
        z-index: 5;
      }

      &__button {
        vertical-align: top !important;
        margin: 0 !important;
      }
    }

    &__bottom-actions {
      text-align: center;
    } 

    &__dimensions {
      display: inline-block;
      padding: $default-size;
      vertical-align: top;
      width: 200px;
      margin-left: $default-size;

      @media (max-width: $material-medium-breakpoint) {
        display: block;
        padding: $half-default-size;
        vertical-align: top;
        width: 100%;
        margin: 0;
      }

      .md-subheading {
        text-align: center;
        font-weight: bold;
      }

      &-config {
        grid-area: dimensions;
      }

      &:first-child {
        margin-left: 0;

        @media (max-width: $material-medium-breakpoint) {
          margin-bottom: $half-default-size;
        }
      }

      .md-field {
        width: 100%;
        font-size: 16px !important;
        padding-top: 10px;
        margin: $half-default-size 0;

        input {
          font-size: 16px !important;
          width: calc(100% - 33px) !important;
          vertical-align: bottom;
        }

        .md-suffix {
          display: inline-block;
          vertical-align: bottom;
        }
      }

      &__checkbox {
        display: flex !important;
        align-items: center;

        label {
          height: auto !important;
        }
      }
    }
  }

  .md-field label {
    left: 15px;
  }
  .md-field.md-focused label, .md-field.md-has-value label {
    left: 0;
  }

  .slide-in-languages-enter-active, .slide-in-languages-leave-active {
    transition: .2s;
  }
  .slide-in-languages-enter, .slide-in-languages-leave-to {
    opacity: 0;
    left: 50%;
    transform: translate(-50%, 100%);
  }
</style>
