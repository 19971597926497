<template>
  <section class="small-product-detail__wrapper" @mouseenter="show_cross_options = true" @mouseleave="show_cross_options = false">
    <transition name="show-cross-options">
      <div
        v-if="show_cross_sale_info && (show_cross_options || already_added)"
        class="small-product-detail__option-buttons"
        :class="{ 'small-product-detail__option-buttons--added': already_added }"
      >
        <div v-if="!already_added">
          <md-button
            class="md-icon-button md-raised md-primary md-success"
            @click="$emit('add_product')"
          >
            <md-icon>add</md-icon>
            <md-tooltip md-direction="left">
              {{ $translate("products.cross_sale.add_cross_product") }}
            </md-tooltip>
          </md-button>
          <md-button
            class="md-icon-button md-raised md-primary"
            @click="$emit('preview_product')"
          >
            <md-icon>remove_red_eye</md-icon>
            <md-tooltip md-direction="left">
              {{ $translate("products.cross_sale.preview_cross_product") }}
            </md-tooltip>
          </md-button>
        </div>
        <div v-else class="small-product-detail__option-buttons-already-added">
          <p>{{ $translate("products.cross_sale.already_in_store") }}</p>
        </div>
      </div>
    </transition>

    <div
      class="small-product-detail md-elevation-5"
      :class="{
        'small-product-detail--list-item': show_list_item,
        'small-product-detail--list-item-no-variants': is_product_without_variants
      }"
    >
      <div class="small-product-detail__info" :class="{ 'small-product-detail__info--small': show_cross_sale_info }">
        <div class="md-title">
          <span v-if="is_cross_sold_product && !show_cross_sale_info" class="small-product-detail__logo">
            <md-tooltip md-direction="left">{{ $translate("products.cross_sale.is_cross_sold") }}</md-tooltip>
            <ulsemo_logo /> {{ product_origin }}
          </span>
          {{ title }}
        </div>
        <div>
          <div v-if="show_cross_sale_info" class="small-product-detail__percentage">
            <md-tooltip md-direction="left">{{ $translate("products.cross_sale.resale_percentage") }}</md-tooltip>
            {{ product_data.resale_percentage }}%
          </div>
          <div class="small-product-detail__price">
            <md-tooltip md-direction="left">{{ $translate("products.cross_sale.price") }}</md-tooltip>
            {{ currency }} <b>{{ price }}</b>
          </div>
        </div>
        <div v-if="show_cross_sale_info && product_origin" class="small-product-detail__country">
          <md-tooltip md-direction="left">{{ $translate("products.cross_sale.country_of_origin") }}</md-tooltip>
          <location_icon /> {{ product_origin }}
        </div>
      </div>
      <img
        class="md-layout-item"
        :src="main_product_image"
      >
      <div v-if="!is_product_without_variants" class="small-product-detail__options">
        <div v-if="show_list_item" class="small-product-detail__options-list">
          <div
            v-for="({ values, name, translations }) in product_data.options"
            :key="`option-${get_correct_translation_value({ translations, name }, 'name', languages)}-${product_data.handle}`"
          >
            {{ get_correct_translation_value({ translations, name }, "name", languages) }}:
            <div>
              <span
                data-cy="product-variant-color"
                class="md-elevation-3"
                v-for="(value, ind) in remove_duplicate_options(values, translations)"
                :key="`extra-item-option-${value}-${ind}`"
                :style="{
                  backgroundColor: is_value_a_color(value) ? value : project_styles.colors.background_heavy
                }"
                :class="{
                  'small-product-detail__options__color': is_value_a_color(value),
                  'small-product-detail__options__transparent': is_value_a_color(value) && value === 'transparent'
                }"
                v-html="is_value_a_color(value) ? '' : value"
              />
            </div>
          </div>
        </div>
        <md-table v-else>
          <md-table-row
            v-for="({ values, name, translations }) in product_data.options"
            :key="`option-${get_correct_translation_value({ translations, name }, 'name', languages)}-${product_data.handle}`"
          >
            <md-table-cell>{{ get_correct_translation_value({ translations, name }, "name", languages) }}:</md-table-cell>
            <md-table-cell>
              <span
                data-cy="product-variant-color"
                class="md-elevation-3"
                v-for="(value, ind) in remove_duplicate_options(values, translations)"
                :key="`extra-item-option-${value}-${ind}`"
                :style="{
                  backgroundColor: is_value_a_color(value) ? value : project_styles.colors.background_heavy
                }"
                :class="{
                  'small-product-detail__options__color': is_value_a_color(value),
                  'small-product-detail__options__transparent': is_value_a_color(value) && value === 'transparent'
                }"
                v-html="is_value_a_color(value) ? '' : value"
              />
            </md-table-cell>
          </md-table-row>
        </md-table>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex"
import { default_language } from "../../../../../../functions/constans/other"
import countries from "../../../../../data/countries"
import { CLIENT_STORE } from "../../../../Client/constants/other"
import get_correct_translation_value from "../../../../Shared/methods/get_correct_translation_value"
import is_value_a_color from "../../../../Shared/methods/is_value_a_color"
import { CONFIGURATION_STORE, USER_STORE } from "../../../constants/others_constants"
import location_icon from "../../../../Shared/components/icon_components/location-icon"
import ulsemo_logo from "../../../../Shared/components/icon_components/ulsemo-icon"

export default {
  components: {
    location_icon,
    ulsemo_logo
  },
  props: {
    title: {
      type: String,
      required: true
    },
    main_product_image: {
      type: String,
      required: true
    },
    is_product_without_variants: {
      type: Boolean,
      required: true
    },
    product_options: {
      type: Array,
      required: true
    },
    product_data: {
      type: Object,
      required: true
    },
    languages: {
      type: Array,
      required: true
    },
    show_list_item: {
      type: Boolean,
      required: false,
      default: false
    },
    show_cross_sale_info: {
      type: Boolean,
      required: false,
      default: false
    },
    already_added: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data() {
    return {
      show_cross_options: false,
    }
  },
  computed: {
    ...mapState(CLIENT_STORE, ["project_styles"]),
    ...mapState(USER_STORE ["user_info"]),
    ...mapState(CONFIGURATION_STORE, ["translations"]),
    product_origin() {
      return countries.find(({ code }) => this.product_data.project_country === code)?.name || ""
    },
    available_price_language() {
      return get_correct_translation_value(
        this.product_data.variants[0],
        "price",
        [this.user_info?.language_code || default_language, ...this.languages],
        false,
        true
      )
    },
    price() {
      return get_correct_translation_value(this.product_data.variants[0], "price", [this.available_price_language], false) || 0
    },
    currency() {
      return this.translations[this.available_price_language]?.currency_code
    },
    is_cross_sold_product() {
      return Boolean(this.product_data.resale_percentage && this.product_data.project_id)
    },
  },
  methods: {
    get_correct_translation_value,
    is_value_a_color,
    remove_duplicate_options(values, translations) {
      let final_values

      if (translations) {
        if (translations[default_language]) final_values = translations[default_language].values
        else if (
          this.user_info?.language_code &&
          translations[this.user_info.language_code]
        ) final_values = translations[this.user_info.language_code].values
        else final_values = translations[Object.keys(translations)[0]].values
      }

      return (final_values || values)
        .reduce((tot, current) => tot.includes(current) ? tot : [...tot, current] , [])
        .filter(val => val !== null)
    },
  }
}
</script>

<style lang="scss">
  @use "../../../../../styles/_global-constants" as *;
  @use "../../../../../styles/_admin-constants" as *;
  @use "../../../../../styles/slide_in_animation" as *;

  .small-product-detail {
    height: 100%;
    padding: $half-default-size;
    text-align: center;
    border-radius: $border-radius;
    cursor: pointer;
    transition: $material-transition;

    &:hover {
      background-color: $material-grey--light;
    }

    &__wrapper {
      position: relative;
    }

    &__option-buttons {
      display: flex;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      align-items: center;
      justify-content: center;
      background: $shadow-color;
      text-align: center;
      line-break: 100%;
      border-radius: $border-radius;

      &--added {
        background: $transparency-white-color;
      }

      &-already-added {
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          font-weight: bold;
          font-size: 20px;
          text-shadow: 0px 0px 14px rgba(0,0,0,0.3);
        }
      }
    }

    &.small-product-detail--list-item {
      p {
        padding-right: $default-size;
        font-size: 14px;
        line-height: 14px;
        text-align: left;
        margin: 0 !important;
      }

      img {
        width: 50px !important;
        height: 50px !important;
        object-fit: cover;
      }

      tr {
        &:first-child td {
          border: 0;
        }

        td {
          border: 0;
          height: 30px;

          .md-table-cell-container {
            padding: 0 $half-default-size 0 0 !important;
          }
        }
      }

      .small-product-detail__options {
        margin: 0;
        padding-left: 10px;
        border-left: 1px solid $material-grey--light;
      }


      &-no-variants {
        .small-product-detail__info--small {
          width: 100% !important;
          grid-template-columns: 1fr 1fr !important;
          grid-template-areas:
            "info-title info-pricing"
            "info-country info-pricing" !important;

          .small-product-detail__percentage, .small-product-detail__price {
            margin-left: auto !important;
          }

          .small-product-detail {
            &__percentage {
              margin: 5px 0;
            }
            &__price {
              margin: 0;
            }
          }
        }
      }

      .small-product-detail__info {
        width: 200px;
        margin-bottom: 0;

        .md-title {
          width: 100px;
        }

        div:nth-child(2) {
          padding-top: 0;
        }

        &--small {
          width: 100px;
          grid-template-columns: 1fr;
          grid-template-areas:
            "info-title"
            "info-pricing"
            "info-country";
          text-align: left;

          .small-product-detail {
            &__percentage {
              margin: 5px 0;
            }
            &__price {
              margin: 0;
            }
          }
        }
      }
    }

    &__logo {
      width: 20px;
      height: 20px;
      vertical-align: middle;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    &__info {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: $half-default-size;
      margin-bottom: $half-default-size;
      text-align: right;

      &--small {
        text-align: right;
        grid-template-areas:
          "info-title info-pricing"
          "info-country info-pricing";

        > div:nth-child(2) {
          grid-area: info-pricing;
          padding-top: 0 !important;
          margin-bottom: 5px;
        }

        .md-title {
          grid-area: info-title;
        }

        .small-product-detail__country {
          grid-area: info-country;
        }
      }

      > div:nth-child(2) {
        padding-top: $default-size;
      }

      .md-title {
        font-size: 16px;
        line-height: 16px;
        text-align: left;
      }
    }

    &__price {
      color: $green;
      width: fit-content;
      margin: 0 0 0 auto;
    }

    &__percentage {
      margin: 0 0 5px auto;
      color: $pure-white;
      background-color: $green;
      padding: 5px $half-default-size;
      border-radius: 6px;
      font-weight: bold;
      font-size: 16px;
      width: fit-content;
    }

    &__country {
      text-align: left;
      color: $material-grey--darkest;
      font-size: 12px;

      svg {
        display: inline-block;
        width: 15px;
        height: 15px;
        vertical-align: middle;
        fill: $material-grey--darkest;
      }
    }

    &--list-item {
      display: grid;
      grid-template-columns: 50px 200px auto;
      grid-template-areas: "image title options options";
      column-gap: $half-default-size;

      &-no-variants {
        grid-template-columns: 50px 1fr;
        grid-template-areas: "image title";
      }
    }

    p {
      grid-area: title;
      margin: 0 0 $half-default-size;
    }

    img {
      grid-area: image;
      height: 150px !important;
      width: 100%;
      object-fit: contain;
      object-position: center;
    }

    &__options {
      grid-area: options;
      margin-top: $half-default-size;
      text-align: left;
      align-items: start;

      span {
        display: inline-block;
        height: 30px;
        margin: 2.5px 5px 2.5px 0;
        line-height: 30px;
        padding: 0 15px;
        color: $pure-white;
        vertical-align: middle;
      }

      &__color {
        width: $double-default-size;
        padding: 0;
        border-radius: 100%;
      }

      &-list {
        display: grid;
        column-gap: 5px;
        grid-template-columns: repeat(3, 1fr);

        > div {
          display: grid;
          column-gap: 10px;
          grid-template-columns: auto 2fr;
          text-align: center;

          div {
            text-align: left;
          }
        }
      }

      tr {
        &:first-child td {
          border: 0;
        }

        td {
          border: 0;
          height: 30px;

          .md-table-cell-container {
            padding: 0 $half-default-size 0 0 !important;
          }
        }
      }

      &__transparent {
        background-image:
          linear-gradient(45deg, rgba(128, 128, 128, 0.55) 25%, transparent 25%),
          linear-gradient(-45deg, rgba(128, 128, 128, 0.50) 25%, transparent 25%),
          linear-gradient(45deg, transparent 75%, rgba(128, 128, 128, 0.5) 75%),
          linear-gradient(-45deg, transparent 75%, rgba(128, 128, 128, 0.5) 75%);
        background-size: 20px 20px;
        background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
      }
    }
  }

  @keyframes option-buttons-slide-in {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .show-cross-options-enter-active {
    animation: option-buttons-slide-in .5s;
  }
  .show-cross-options-leave-active {
    animation: option-buttons-slide-in .5s reverse;
  }
</style>
