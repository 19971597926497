export default function (ref, labels, data, assigning_to, label, type = "bar", borderColor = "#448aff", backgroundColor = "#448aff", multiple_data_sets) {
  if (!this.chart_loaded) return
  if (this[assigning_to]) this[assigning_to].destroy()

  this[assigning_to] = new Chart(ref, {
    type,
    data: {
      labels,
      datasets: multiple_data_sets ? data.map((data_part, index) => ({
        label: label[index],
        backgroundColor: backgroundColor[index],
        borderColor: borderColor[index],
        data: data_part,
        borderWidth: 1
      })) : [{
        label,
        backgroundColor,
        borderColor,
        data,
        borderWidth: 1,
        hoverOffset: 4
      }]
    },
    options: {
      pointRadius: 1,
      responsive: true,
      interaction: {
        mode: "index",
        intersect: false,
      },
      stacked: false,
    }
  })
}
