<template>
  <component
    v-if="is_selector_enabled"
    :is="display_icon_button ? 'md-button' : 'div'" 
    class="product-favorite"
    @click="update_favorite_rating"
    :class="{
      'product-favorite--selected': is_product_favorite,
      'product-favorite--large': large,
      'product-favorite--list-item': inside_list_item,
      'md-icon-button md-raised': display_icon_button,
      'product-favorite--align-top': align_top,
    }"
  >
    <md-icon>{{ is_product_favorite ? "star" : "star_border" }}</md-icon>
    <md-tooltip md-direction="top">{{ $translate("products.favorite_product_message") }}</md-tooltip>
  </component>
</template>

<script>
import { mapMutations, mapState } from "vuex"
import { ADMIN_STORE, PRODUCTS_STORE, imported, updated } from "../../../constants/others_constants"
import { IMPORTED_DATA_UPDATED } from "../../../stores/Admin/constants"
import { UPDATE_COLLECTION } from "../../../stores/Admin/products/constants"

export default {
  props: {
    collection_index: {
      type: Number,
      required: true
    },
    product_id: {
      type: String,
      required: true
    },
    products_type: {
      type: String,
      required: true
    },
    large: {
      type: Boolean,
      required: true
    },
    inside_list_item: {
      type: Boolean,
      required: true
    },
    display_icon_button: {
      type: Boolean,
      required: false,
      default: false
    },
    align_top: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapState(PRODUCTS_STORE, ["imported_collections"]),
    is_product_favorite() {
      return this.imported_collections[this.collection_index]?.products?.find(
        ({ id }) => id === this.product_id
      )?.is_favorite
    },
    is_selector_enabled() {
      return this.products_type === imported
    }
  },
  methods: {
    ...mapMutations(ADMIN_STORE, {
      imported_data_updated: IMPORTED_DATA_UPDATED,
    }),
    ...mapMutations(PRODUCTS_STORE, {
      update_collection: UPDATE_COLLECTION,
    }),
    update_favorite_rating() {
      this.update_collection([
        this.products_type,
        this.collection_index,
        "products",
        this.imported_collections[this.collection_index].products.map(
          product => product.id === this.product_id ? 
            {
              ...product,
              is_favorite: !this.is_product_favorite
            } :
            product
        )
      ])
      this.update_collection([
        this.products_type,
        this.collection_index,
        "change_type",
        updated
      ])
      this.imported_data_updated(true)
    }
  }
}
</script>

<style lang="scss">
  @use "../../../../../styles/_global-constants" as *;
  @use "../../../../../styles/_admin-constants" as *;

  .product-favorite {
    position: absolute;
    top: calc(#{$default-size} + 5px);
    right: 18px;
    cursor: pointer;
    z-index: 1;

    @media (max-width: $mobile) {
      top: calc(#{$half-default-size} + 5px);;
      right: 11px;
    }

    i {
      transform: $material-transition;
    }

    &--selected i {
      color: #d4af37 !important;
    }

    &--large {
      top: #{$icon-size + $default-size};
      right: #{$icon-size + $default-size};

      i {
        font-size: 35px !important;
      }
    }

    &--align-top {
      top: $default-size;
    }

    &--list-item {
      top: 5px;
      right: 5px;
    }
  }
</style>
