<template>
  <section class="admin-products">
    <save_button
      :show="show_save_button && permissions.products.manage && !hide_top_save_button"
      :section="$translate('side_menu.product_admin')"
      @save="save_updated_data"
      @cancel="fetch_products"
    />
    <error_bar v-if="selected_product || page_type === inventory" :error_list="error_list" :title="error_list_title" :product_id="selected_product ? selected_product.id : ''"/>

    <product_inventory v-if="page_type === inventory"/>
    <section v-else>
      <p class="product-display__empty-message md-headline" v-if="!is_shopify_configured && page_type === shopify">
        {{ $translate("products.empty_shopify_message") }}
        <md-button
          v-if="permissions.configuration.sections.shopify.module"
          :md-ripple="false"
          @click="$router.push(`/Admin/configuration/${shopify}`)"
        >
          {{ $translate("products.go_to_shopify_config") }}
        </md-button>
      </p>

      <!-- If shopify products displayed, user must have configured Shopify connection -->
      <div
        v-if="page_type !== shopify || (page_type === shopify && is_shopify_configured)"
        class="product-display scroll-content"
      >
        <div>
          <h3 class="md-display-1">
            {{ $translate(`products.${$route.params.page_type}_title`) }}
            <span>
              <md-icon>info</md-icon>
              <md-tooltip
                class="product-display__main-message"
                md-direction="bottom"
                v-html="$translate('products.main_message')"
              />
            </span>

            <div class="refresh-admin-data">
              <md-speed-dial md-direction="bottom" class="refresh-admin-data__mobile-content">
                <md-speed-dial-target class="md-normal">
                  <md-icon>more_vert</md-icon>
                </md-speed-dial-target>

                <md-speed-dial-content>
                  <csv_upload />
                  <video_tutorial section="product_creation" />
                  <md-button class="md-icon-button md-normal md-raised" @click="fetch_products">
                    <md-icon>cached</md-icon>
                    <md-tooltip md-direction="top">{{ $translate("refresh") }} {{ $translate("data") }}</md-tooltip>
                  </md-button>
                </md-speed-dial-content>
              </md-speed-dial>
              <div class="refresh-admin-data__desktop-content">
                <csv_upload />
                <video_tutorial section="product_creation" />
                <md-button class="md-icon-button md-normal md-raised" @click="fetch_products">
                  <md-icon>cached</md-icon>
                  <md-tooltip md-direction="top">{{ $translate("refresh") }} {{ $translate("data") }}</md-tooltip>
                </md-button>
              </div>
            </div>
          </h3>
          <section id="all-collections-tour">

            <!-- Parent collection tabs -->
            <div class="product-display__collections" id="parent-collections-tour">
              <span class="md-caption">{{ $translate("products.parent_collections.title") }}</span>

              <md-tabs
                v-if="get_parent_collections.length"
                :md-active-tab="`${current_parent_collection_index}-tab`"
                @md-changed="id => tab_changed(id, 'parent_collection')"
              >
                <template slot="md-tab" slot-scope="{ tab }">
                  <div
                    class="product-display__inner-tab-wrapper"
                    :class="{ 'product-display__empty-collections': tab.label === collections_without_parents }"
                  >
                    {{ tab.label }}
                    <md-button
                      v-if="tab.label !== collections_without_parents && permissions.products.manage"
                      class="md-icon-button product-display__collections__edit"
                      @click="preparing_for_collection_update(tab.data.index, true)"
                    >
                      <md-icon>edit</md-icon>
                      <md-tooltip md-direction="top">{{ $translate("products.parent_collections.edit")}}</md-tooltip>
                    </md-button>
                  </div>
                </template>

                <md-tab
                  v-for="(parent_collection, ind) in get_parent_collections"
                  :key="`tab-${ind}-parent-collection`"
                  :id="`${ind}-tab`"
                  :md-label="parent_collection"
                  :md-template-data="{ index: ind }"
                />
              </md-tabs>
              <md-button
                data-cy="add-parent-collection"
                v-if="permissions.products.manage"
                class="md-icon-button md-raised md-primary"
                :class="{ 'md-button-disabled': !get_all_collections.length }"
                @click="get_all_collections.length ? create_new_collection(true) : null"
              >
                <md-icon>add</md-icon>
                <md-tooltip md-direction="top">{{
                  get_all_collections.length ?
                    $translate("products.parent_collections.add") :
                    $translate("products.parent_collections.no_collection_message")
                }}</md-tooltip>
              </md-button>
            </div>

            <!-- Collection tabs -->
            <div>
              <div class="product-display__collections" id="collections-tour">
                <span class="md-caption">{{ $translate("products.collections.title") | capitalize }}:</span>
                
                <md-tabs :md-active-tab="`${current_collection_index}-tab`" @md-changed="tab_changed">
                  <template slot="md-tab" slot-scope="{ tab }">
                    <div
                      class="product-display__inner-tab-wrapper"
                      :class="{ 'product-display__empty-collections': tab.label === products_without_collection }"
                    >
                      {{ decode_route(tab.label) }}
                      <md-button
                        v-if="tab.label !== products_without_collection && permissions.products.manage"
                        class="md-icon-button product-display__collections__edit"
                        @click="preparing_for_collection_update(tab.data.index)"
                      >
                        <md-icon>edit</md-icon>
                        <md-tooltip md-direction="top">{{ $translate("products.collections.edit")}}</md-tooltip>
                      </md-button>
                    </div>
                  </template>

                  <md-tab
                    v-for="({ title }, ind) in get_collections"
                    :key="`tab-${ind}-collection`"
                    :id="`${ind}-tab`"
                    :md-label="title"
                    :md-template-data="{ index: ind }"
                  />
                </md-tabs>
                <md-button
                  data-cy="add-collection"
                  v-if="permissions.products.manage"
                  class="md-icon-button md-raised md-primary"
                  @click="create_new_collection(false)"
                >
                  <md-icon>add</md-icon>
                  <md-tooltip md-direction="top">{{ $translate("products.collections.add") }}</md-tooltip>
                </md-button>
              </div>
            </div>
          </section>
          <div v-if="selected_product" class="product-display__product-tabs">
            <span class="md-caption">{{ $translate("products.products") | capitalize }}:</span>
            <md-button class="md-icon-button md-raised" @click="switch_product_index(null)">
              <md-icon>keyboard_backspace</md-icon>
            </md-button>

            <!-- Product tabs -->

            <md-tabs
              md-swipeable
              :md-active-tab="`${current_product_index}-product`"
              @md-changed="id => tab_changed(id, 'product')"
            >
              <template slot="md-tab" slot-scope="{ tab }">
                <div>
                  <span v-if="tab.data.resale_percentage && tab.data.project_id">
                    <md-tooltip md-direction="bottom">{{ $translate("products.cross_sale.is_cross_sold") }}</md-tooltip>
                    <ulsemo_logo />
                  </span>
                  {{ decode_route(tab.label) }}
                </div>
              </template>

              <md-tab
                v-for="(product, ind) in get_products"
                :key="`tab-${ind}-product`"
                :id="`${ind}-product`"
                :md-label="get_correct_translation_value(product, 'title', [selected_product_language || user_info.language_code])"
                :md-template-data="{ index: ind, resale_percentage: product.resale_percentage, project_id: product.project_id  }"
              />
            </md-tabs>
          </div>
        </div>
        <div>
          <product_detail
            v-if="selected_product"
            :collection_index="get_active_collection_index"
            :product_index="current_product_index"
            :collections_type="store_collections_name"
            :product_data="selected_product"
            :products_type="page_type"
            :is_shopify_product="page_type === shopify"
            :image_route="get_product_image_route"
            :collection_title="selected_collection_title"
            :show_save_button="show_save_button && permissions.products.manage"
            @save_data="save_updated_data"
            @discard_data="fetch_products"
            @toggle_top_save_button="val => hide_top_save_button = val"
            @toggle_selected_product_language="lang => selected_product_language = lang"
          />
          <div v-else>
            <span class="md-caption vertical-inline-block">{{ $translate("products.products") | capitalize }}:</span>

            <md-speed-dial v-if="permissions.products.manage" data-cy="add-new-product-dropdown" md-direction="bottom" class="admin-products__speed-dial">
              <md-speed-dial-target class="md-normal">
                <md-icon>playlist_add</md-icon>
              </md-speed-dial-target>

              <md-speed-dial-content>
                <md-button
                  data-cy="add-new-product"
                  class="md-icon-button md-primary"
                  @click="create_new_product()"
                >
                  <md-icon>add</md-icon>
                  <md-tooltip md-direction="right">{{ $translate("products.add_product") }}</md-tooltip>
                </md-button>

                <csv_upload :right_tooltip="true"/>

                <md-button
                  v-if="page_type !== shopify"
                  class="md-icon-button"
                  @click="toggle_cross_products_modal = true"
                >
                  <md-icon><ulsemo_logo /></md-icon>
                  <md-tooltip md-direction="right">{{ $translate("products.cross_sale.create_button") }}</md-tooltip>
                </md-button>
              </md-speed-dial-content>
            </md-speed-dial>


            <div class="admin-products__products-toggle">
              <md-button class="md-icon-button md-raised" :disabled="show_product_list" @click="show_product_list = true">
                <md-icon>list</md-icon>
              </md-button>
              <md-button class="md-icon-button md-raised" :disabled="!show_product_list" @click="show_product_list = false">
                <md-icon>apps</md-icon>
              </md-button>
            </div>

            <div class="md-layout md-gutter product-display__products" :class="{ 'product-display__products-list': show_product_list }" id="products-preview-tour">
              <product_detail
                data-cy="product-detail"
                v-for="(product, ind) in get_products"
                :key="`${ind}-product`"
                :class="{ 'md-layout-item md-size-20 md-large-size-25 md-medium-size-33 md-small-size-50 md-xsmall-size-100': !show_product_list }"
                :product_data="product"
                :products_type="page_type"
                :fullscreen="false"
                :collection_index="get_active_collection_index"
                :product_index="ind"
                :show_list_item="show_product_list"
                @selected_product="switch_product_index(ind)"
              />
            </div>
          </div>
        </div>
        <collection_modal
          :is_edited_collection_parent="is_edited_collection_parent"
          :edited_collection_index="edited_collection_index"
          :open_collection_edit_modal="open_collection_edit_modal"
          :edited_collection_children="edited_collection_children"
          :get_parent_collections="get_parent_collections"
          :get_collections="get_collections"
          :validate="validate"
          :get_all_products="get_all_products"
          :get_correct_collections_data="get_correct_collections_data"
          :page_type="page_type"
          :encode_route="encode_route"
          :current_collection_index="current_collection_index"
          :get_all_collections="get_all_collections"
          :determine_collection_switch="determine_collection_switch"
          :current_parent_collection_index="current_parent_collection_index"
          :edited_collection_name="edited_collection_name"
          :collection_entities="collection_entities"
          :collection_name_error="collection_name_error"
          :select_collections_error="select_collections_error"
          @clear_collection_children="edited_collection_children = []"
          @toggle_collection_modal="val => open_collection_edit_modal = val"
          @clear_edited_collection_index="edited_collection_index = null"
          @clear_edited_collection_parent="is_edited_collection_parent = false"
          @update_current_collection_index="(index) => current_collection_index = index"
          @update_edited_collection_name="(val) => edited_collection_name = val"
          @update_entities_for_collection="entities => collection_entities = entities"
          @clear_collection_name_error="collection_name_error = null"
        />
      </div>

      <div v-if="get_all_products_with_errors.length && !selected_product" class="admin-products__products-with-errors-wrapper md-elevation-9">
        <p>{{ $translate("products.products_with_errors") }}</p>
        <div class="admin-products__products-with-errors">
          <div
            v-for="{ id, images, collections, ...rest } in get_all_products_with_errors"
            :key="id"
            @click="open_product(
              id,
              ((collections || [])[0] || {}).id,
              ((collections || [])[0] || {}).body_html ? decode_route(get_curly_brace_content(collections[0].body_html)[1]) : ''
            )"
          >
            <img :src="get_correct_first_image({ images })">
            <md-tooltip md-direction="top">{{ get_correct_translation_value(rest, "title", [user_info.language_code]) }}</md-tooltip>
          </div>
        </div>
      </div>
    </section>

    <md-dialog :md-active.sync="toggle_cross_products_modal">
      <cross_product_picker
        v-if="toggle_cross_products_modal"
        @add_product="product => create_new_product(product)"
      />
    </md-dialog>
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex"
import html_enity_encoder from "urlencode"
import product_inventory from "../utils/products/product_inventory"
import video_tutorial from "../utils/video_tutorial"
import product_detail from "../utils/products/product_detail"
import {
   shopify, created, deleted, removed, PRODUCTS_STORE,
  ADMIN_STORE, imported, CONFIGURATION_STORE, USER_STORE, inventory
} from "../../constants/others_constants"
import {
  UPDATE_PARENT_COLLECTION,
  UPDATE_COLLECTION,
  UPDATE_SHOPIFY_PRODUCTS,
  UPDATE_SHOPIFY_COLLECTIONS,
  CREATE_COLLECTION,
  UPDATE_PRODUCT_COLLECTIONS,
  CREATE_PRODUCT,
  CLEAR_ERRORS_SECTION,
  UPDATE_IMPORTED_PRODUCTS,
  UPDATE_IMPORTED_COLLECTIONS,
  FETCH_IMPORTED_PRODUCTS,
  UPDATE_PRODUCT_CURRENCIES,
} from "../../stores/Admin/products/constants"
import save_button from "../utils/save_button"
import validator from "../../methods/validations/products"
import get_all_products_with_errors from "../../methods/get_all_products_with_errors"
import sanitize_string_for_object_key from "../../methods/sanitize_string_for_object_key"
import error_bar from "../utils/error_bar"
import get_curly_brace_content from "../../../Shared/methods/get_curly_brace_content"
import {
  UPDATE_LOADER,
  IMPORTED_DATA_UPDATED,
  SHOPIFY_DATA_UPDATED,
  FETCH_SHOPIFY_DATA,
  FETCH_CONFIGURATION_DATA,
  UPDATE_IMAGES_FOR_DELETE,
} from "../../stores/Admin/constants"
import replace_dashes_for_spaces from "../../../Shared/methods/replace_dashes_for_spaces"
import replace_spaces_for_dashes from "../../../Shared/methods/replace_spaces_for_dashes"
import get_correct_translation_value from "../../../Shared/methods/get_correct_translation_value"
import { default_image_src } from "../../../Shared/constants/other"
import { USER_STORE_TOUR_TOGGLE } from "../../stores/Admin/user/constants"
import { event_hub } from "../../../../main"
import get_correct_first_image from "../../../Shared/methods/get_correct_first_image"
import ulsemo_logo from "../../../Shared/components/icon_components/ulsemo-icon"
import cross_product_picker from "../utils/products/cross_product_picker"
import cross_product_formatter from "../../methods/cross_product_formatter"
import csv_upload from "../utils/products/csv_upload"
import collection_modal from "../utils/products/collection_modal"

export default {
  components: {
    product_detail,
    product_inventory,
    save_button,
    error_bar,
    video_tutorial,
    ulsemo_logo,
    cross_product_picker,
    csv_upload,
    collection_modal
  },
  data() {
    return {
      add_new_category_opener: false,
      has_product_sizes: false,
      open_collection_edit_modal: false,
      is_edited_collection_parent: false,
      toggle_cross_products_modal: false,
      hide_top_save_button: false,
      show_product_list: false,
      current_parent_collection_index: 0,
      current_collection_index: 0,
      edited_collection_name: "",
      adding_size: "",
      adding_stock: "",
      adding_sku: "",
      selected_product_language: "",
      collection_name_error: null,
      select_collections_error: null,
      height_of_selection_menu: "278px",
      collections_without_parents: this.$translate("products.parent_collections.without_parent"),
      products_without_collection: this.$translate("products.parent_collections.without_collection"),
      collection_entities: [],
      edited_collection_children: [],
      current_product_index: null,
      edited_collection_index: null,
      new_category_data: {
        name: ""
      },
      user_tour: null
    }
  },
  computed: {
    ...mapState(PRODUCTS_STORE, [
      "imported_collections",
      "imported_products",
      "shopify_collections",
      "shopify_products",
      "error_list",
      "error_list_title"
    ]),
    ...mapState(CONFIGURATION_STORE, ["shopify_config", "translations", "shipping_options"]),
    ...mapState(ADMIN_STORE, [
      "user_edited_shopify_products",
      "user_edited_imported_products",
      "user_edited_website_configuration",
    ]),
    ...mapState(USER_STORE, [
      "permissions", "user_info", "showing_store_tour", "showing_marketplace_tour", "projects"
    ]),
    get_all_products_with_errors,
    allow_collection_save() {
      // Check that parent collection name isnt being duplicated and has collections assigns
      if (this.is_edited_collection_parent)
        return !this.get_parent_collections
          .map(val => val.toLowerCase())
          .includes(this.edited_collection_name.toLowerCase()) &&
          this.collection_entities.length > 0

      return Boolean(this.edited_collection_name)
    },
    is_shopify_configured() {
      return this.shopify_config.shopify_public ? [
        ...Object.values(this.shopify_config.shopify_public),
        ...Object.values(this.shopify_config.shopify_secret)
      ].every(val => val) : false
    },
    show_save_button() {
      return (this.user_edited_shopify_products || this.user_edited_imported_products) && !this.error_list.length
    },
    page_type() {
      return this.$route.params.page_type
    },
    store_collections_name() {
      return `${this.page_type}_collections`
    },
    get_correct_collections_data() {
      return (this[this.store_collections_name] || []).sort((a, b) => {
        if(!b.body_html || (!a.body_html && !b.body_html)) return 1
        if(!a.body_html) return -1
        if(get_curly_brace_content(this.decode_route(a.body_html))[1] < get_curly_brace_content(this.decode_route(b.body_html))[1]) return -1
        if(get_curly_brace_content(this.decode_route(a.body_html))[1] > get_curly_brace_content(this.decode_route(b.body_html))[1]) return 1
        return 0;
      })
    },
    /**
     * Gets parent collections (ie. Clothes for t-shirts) from description of a 
     * collection in format -> {parent collection name}
     */
    get_parent_collections() {
      if (this.get_correct_collections_data.length) {
        const are_there_collections_without_parent = this.get_correct_collections_data.some(({ body_html }) => !get_curly_brace_content(body_html))

        const parent_collections = this.get_correct_collections_data
          .filter(({ change_type, body_html }) => change_type !== deleted && body_html && get_curly_brace_content(body_html)[1])
          .map(({ body_html }) => get_curly_brace_content(this.decode_route(body_html))[1])
          .reduce((total, current) => {
            if(!total.includes(current)) total.push(current)

            return total
          }, [])

        return [
          ...parent_collections,
          ...(
            are_there_collections_without_parent ||
            this.products_without_collections().length ?
              [this.collections_without_parents] : []
          )
        ]
      }

      return []
    },
    /**
     * Gets collections under parent collection
     */
    get_collections() {
      /**
       * Checks if the collections have a parent collection 
       */
      if (this.get_parent_collections.length) {
        /**
         * Checks if the selected parent collection is for products without a collection
         * and if there even are any products without collection, if so, and an empty
         * "Without collection" object to the total array
         */
        return (this.products_without_collections().length && this.is_selected_parent_collection_for_without_parent ?
          [
            ...this.get_correct_collections_data.filter(this.filter_collections_by_parent),
            { title: this.products_without_collection }
          ] : this.get_correct_collections_data.filter(this.filter_collections_by_parent)
        ).filter(({ change_type }) => change_type !== deleted)
      }

      /**
       * If not, then check if there are any products without collection and add an empty
       * "Without collection" object to the total array
       */
      return (
        this.products_without_collections().length ?
          [...this.get_correct_collections_data, { title: this.products_without_collection }] :
          this.get_correct_collections_data
      ).filter(({ change_type }) => change_type !== deleted)
    },
    /**
     * Gets all products for page type
     */
    get_all_products() {
      return this[`${this.page_type}_products`] || []
    },
    /**
     * Gets all collections for page type
     */
    get_all_collections() {
      return this[`${this.page_type}_collections`] || []
    },
    selected_collection_title() {
      return this.get_collections?.[this.current_collection_index]?.title
    },
    get_products() {
      if (this.current_collection_index !== undefined && this.get_collections.length) {
        // If collection is newly created or has ID (therefor exists)
        if (
          this.get_collections[this.current_collection_index]?.id >= 0 ||
          this.get_collections[this.current_collection_index]?.id ||
          this.get_collections[this.current_collection_index]?.change_type === created
        ) return this.get_all_products.filter(
          (product = {}) => (
            this.get_collections[this.current_collection_index].products || []
          ).some((collection_product = {}) => product.id === collection_product.id)
        )

        return this.products_without_collections()
      }

      return []
    },
    selected_product() {
      return this.get_products[this.current_product_index]
    },
    is_selected_parent_collection_for_without_parent() {
      return this.get_parent_collections[this.current_parent_collection_index] === this.$translate("products.parent_collections.without_parent")
    },
    get_active_collection_index() {
      if (
        this.current_collection_index !== undefined &&
        this.current_collection_index > -1 &&
        this.get_collections[this.current_collection_index]
      ) {
        const active_collection_id = this.get_collections[this.current_collection_index].id

        return this.get_correct_collections_data.findIndex(({ id }) => id === active_collection_id)
      }

      return null
    },
    get_product_image_route() {
      const parent = this.encode_route(this.get_parent_collections[this.current_parent_collection_index])
      const collection = (this.get_collections[this.current_collection_index] || {}).handle || ""
      const product =
        (this.get_products[this.current_product_index] || {}).buffer_id ||
        (this.get_products[this.current_product_index] || {}).id ||
        ""

      return `${parent + "/"}${collection + "/"}${product}`
    },
    all_available_languages() {
      return Object.keys(this.translations)
    },
  },
  watch: {
    current_product_index(new_val) {
      if (new_val === null) this.hide_top_save_button = false
    },
    page_type(type) {
      if (type === "inventory") return

      this.current_product_index = null
      this.current_collection_index = 0
      this.current_parent_collection_index = 0

      if (!this[`${type}_products`]) this.fetch_products()
    },
    current_parent_collection_index() {
      this.current_product_index = null
      this.current_collection_index = 0
    },
    current_collection_index() {
      this.current_product_index = null
    },
    is_shopify_configured(new_val, old_val) {
      if (new_val && new_val !== old_val) this.fetch_shopify_data()
    },
    selected_product() {
      this.selected_product_language = ""
    }
  },
  created() {
    if (!this.permissions.products.module) return this.$router.push("/admin");
    this.shopify = shopify
    this.inventory = inventory
    this.default_image_src = default_image_src
    event_hub.$on("clear_selection", () => {
      this.current_parent_collection_index = 0
      this.current_collection_index = 0
      this.current_product_index = null
    })
  },
  async mounted() {
    this.update_loader();
    await Promise.all([
      !this[`${this.page_type}_products`] ? this.fetch_products(false) : null,
      !Object.keys(this.translations).length
        ? this.fetch_configuration()
        : null,
    ]);
    this.update_loader(false);

    // Old user guide tour - Only show to first time users with dummy products
    // if ((this.showing_store_tour || this.showing_marketplace_tour) && this.projects.length === 1) {
    //   this.user_tour = new window.user_tour(base_tour_config);
    //   this.user_tour.setSteps(await (
    //     this.showing_store_tour ? initial_store_tour : initial_marketplace_tour
    //   )(this.user_tour));
    //   this.user_tour.start();
    // }
  },
  beforeDestroy() {
    if (this.user_tour) {
      this.user_store_tour_toggle(false)
      this.user_tour.stop()
    }
  },
  methods: {
    ...mapMutations(PRODUCTS_STORE, {
      update_parent_collection: UPDATE_PARENT_COLLECTION,
      update_collection: UPDATE_COLLECTION,
      create_collection: CREATE_COLLECTION,
      update_product_collections: UPDATE_PRODUCT_COLLECTIONS,
      create_product: CREATE_PRODUCT,
      clear_errors: CLEAR_ERRORS_SECTION,
      update_currencies: UPDATE_PRODUCT_CURRENCIES,
    }),
    ...mapMutations(ADMIN_STORE, {
      update_loader: UPDATE_LOADER,
      imported_data_updated: IMPORTED_DATA_UPDATED,
      shopify_data_updated: SHOPIFY_DATA_UPDATED,
      image_delete_toggle: UPDATE_IMAGES_FOR_DELETE
    }),
    ...mapMutations(USER_STORE, {
      user_store_tour_toggle: USER_STORE_TOUR_TOGGLE,
    }),
    ...mapActions(PRODUCTS_STORE, {
      update_shopify_products: UPDATE_SHOPIFY_PRODUCTS,
      update_shopify_collections: UPDATE_SHOPIFY_COLLECTIONS,
      update_imported_products: UPDATE_IMPORTED_PRODUCTS,
      update_imported_collections: UPDATE_IMPORTED_COLLECTIONS,
      fetch_imported_data: FETCH_IMPORTED_PRODUCTS
    }),
    ...mapActions(CONFIGURATION_STORE, {
      fetch_configuration: FETCH_CONFIGURATION_DATA
    }),
    ...mapActions(ADMIN_STORE, {
      fetch_shopify_data: FETCH_SHOPIFY_DATA,
    }),
    get_correct_first_image,
    get_correct_translation_value,
    get_curly_brace_content,
    switch_product_index(index) {
      this.current_product_index = index
    },
    products_without_collections() {
      const products_without_collections = this.get_all_products.filter(({ collections }) => !collections || !collections.length)
      const product_with_deleted_collections = this.get_all_products.filter(({ collections }) => collections && collections.length && collections.every(
        ({ collection_change_type }) => collection_change_type === removed)
      )

      return [
        ...products_without_collections,
        ...product_with_deleted_collections
      ]
    },
    open_product(product_id, collection_id, parent_collection) {
      this.update_loader(true)
      const parent_collection_index = parent_collection ?
        this.get_parent_collections.findIndex(parent => parent === parent_collection) :
        this.get_parent_collections.length - 1 // Get without parent collection
      
      this.current_parent_collection_index = parent_collection_index
      this.$nextTick(() => {
        const collection_index = collection_id ?
          this.get_collections.findIndex(({ id }) => id === collection_id) :
          -1

        const product_index = (collection_index > -1 ?
          this.get_collections[collection_index].products :
          this.products_without_collections()
        )?.findIndex(({ id }) => id === product_id)

        this.current_collection_index = collection_index
        this.$nextTick(() => {
          this.current_product_index = product_index
          this.update_loader(false)
        })
      })
    },
    decode_route(route) {
      return this.page_type === shopify ? replace_dashes_for_spaces(route) : html_enity_encoder.decode(route)
    },
    encode_route(route = "") {
      return this.page_type === shopify ? sanitize_string_for_object_key(replace_spaces_for_dashes(route)) : html_enity_encoder(route)
    },
    async fetch_products(run_loader = true) {
      if (run_loader) this.update_loader()

      if (this.page_type === inventory) {
        this.imported_data_updated(false)
        this.shopify_data_updated(false)

        await this.fetch_imported_data()
        await this.fetch_shopify_data()
      }
      else {
        this[`${this.page_type}_data_updated`](false)
        await this[`fetch_${this.page_type}_data`]()
      }

      if (run_loader) this.update_loader(false)
    },
    async save_updated_data() {
      if (this.page_type === inventory) {
        if (this.user_edited_imported_products) await this.update_data(imported)
        if (this.user_edited_shopify_products) await this.update_data(shopify)
      }
      else this.update_data()
    },
    async update_data(data_origin) {
      // Synchronize currencies for Ulsemo products
      if (
        (data_origin || this.page_type) === imported &&
        this.user_edited_website_configuration?.translations
      ) this.all_available_languages.forEach(
        language => this.update_currencies([language, this.translations[language].currency_code])
      )

      // Update products and collections
      await this[`update_${data_origin || this.page_type}_products`]([this[`${data_origin || this.page_type}_products`] || [], false])
      await this[`update_${data_origin || this.page_type}_collections`]([this[`${data_origin || this.page_type}_collections`], false])

      this.update_loader()

      setTimeout(async () => {
        await this.fetch_products()
        this.update_loader(false)

        // If not from inventory module
        if (!data_origin) this.determine_collection_switch()
      }, 1000);
    },
    tab_changed(tab_identifier, type = "collection") {
      if (!tab_identifier || tab_identifier === "null-tab") return

      const tab_index = tab_identifier.match(/\d+/g).map(Number)[0]

      if (!this[`get_${type}s`][tab_index]) return
      // Unselect products
      if (type === "collection") this.current_product_index = null

      this[`current_${type}_index`] = tab_index
    },
    async create_new_product(cross_product) {
      this.update_loader()
      const correct_collection = this.get_collections[this.current_collection_index]
      const correct_collection_index_in_total_array = this.get_correct_collections_data.findIndex(
        ({ id }) => id === correct_collection.id
      )

      await this.create_product([
        this.page_type,
        correct_collection_index_in_total_array,
        this.page_type === imported,
        this.all_available_languages,
        this.translations,
        cross_product ? await cross_product_formatter(cross_product) : null
      ])

      this.$nextTick(() => {
        this.toggle_cross_products_modal = false
        const index_of_new_product = this.get_products.length - 1
        this.current_product_index = correct_collection_index_in_total_array > -1 ?
          this.get_collections[this.current_collection_index].products.length - 1 : index_of_new_product

        this[`${this.page_type}_data_updated`](true)
        this.update_loader(false)
      })
    },
    /**
     * Collection update functions
     */
    determine_collection_switch() {
      if (
        (this.get_collections?.length && !this.get_collections[this.current_collection_index]) ||
        (this.get_parent_collections?.length && !this.get_parent_collections[this.current_parent_collection_index])
      ) {
        this.current_collection_index = 0
        this.current_parent_collection_index = 0
        this.current_product_index = null
      }
      if (!this.get_products[this.current_product_index]) this.current_product_index = null
    },
    preparing_for_collection_update(index, is_parent_collection = false) {
      this[`current_${is_parent_collection ? "parent_" : ""}collection_index`] = index
      this.edited_collection_index = index
      this.is_edited_collection_parent = is_parent_collection
      if (!is_parent_collection) this.current_collection_index = index
      const correct_collections = this[is_parent_collection ? "get_parent_collections" : "get_collections"]

      this.edited_collection_children = this[
        is_parent_collection ? "get_correct_collections_data" : "get_all_products"
      ].filter(({ change_type }) => change_type !== deleted) // Correct children without deleted
      this.edited_collection_name = is_parent_collection ? this.decode_route(correct_collections[index]) : correct_collections[index].title
      this.collection_entities = this.edited_collection_children.reduce((tot, { id }, ind) => {
        if(
          (this.get_collections.some(({ id: corect_id }) => corect_id === id) && is_parent_collection) ||
          (this.get_products.some(({ id: corect_id }) => corect_id === id) && !is_parent_collection)
        ) tot.push(ind)
        return tot
      }, [])

      this.open_collection_edit_modal = true
    },
    create_new_collection(is_parent_collection) {
      this.is_edited_collection_parent = is_parent_collection
      this.edited_collection_children = this[
        is_parent_collection ?  "get_correct_collections_data" : "get_all_products"
      ].filter(({ change_type }) => change_type !== deleted)
      this.open_collection_edit_modal = true
      this.edited_collection_index = undefined
    },
    /**
     * Helper functions
     */
    filter_collections_by_parent({ body_html }) {
      const does_collection_have_parent = !!get_curly_brace_content(body_html)

      return does_collection_have_parent ?
        get_curly_brace_content(body_html)[1] === this.encode_route(
          this.get_parent_collections[this.current_parent_collection_index]
        ) : this.is_selected_parent_collection_for_without_parent
    },
    ...validator
  }
}
</script>

<style lang="scss">
  @use "../../../../styles/_admin-constants" as *;
  @use "../../../../styles/_global-constants" as *;

  .product-display {
    position: relative;
    padding-bottom: $default-size;

    &__main-message {
      height: auto !important;
    }

    #all-collections-tour {
      display: inline-block;
    }

    &__empty-message {
      width: 100%;
      max-width: 900px;
      margin: $double-default-size auto;

      button {
        margin: $default-size 0;
      }
    }

    &__modal-info {
      padding: 0 $default-size;
      font-size: $font-size--medium;
    }

    &__collections {
      display: inline-block;
      margin: 0 0 $default-size;

      .md-tabs, button, span {
        display: inline-flex;
        vertical-align: middle;
      }

      &__edit {
        opacity: 0;
        width: 0 !important;
        height: 30px !important;
        font-size: 10px !important;
        min-width: 0 !important;
        margin: 0 0 0 -15px !important;
        transition: 200ms ease-out;

        .md-icon {
          width: 100%;
          height: 100%;
          font-size: 19px !important;
        }

        .md-ripple {
          padding: 0 !important;
        }
      }

      .md-tab-nav-button:hover {
        .product-display__collections__edit {
          width: 30px !important;
          min-width: 30px !important;
          margin: 0 0 0 10px !important;
          opacity: 1;
        }
      }
    }

    &__empty-collections {
      opacity: 0.6;
    }

    .md-active &__empty-collections {
      opacity: 1;
    }

    &__products {
      align-content: flex-start;

      &-list {
        display: grid !important;
        flex-wrap: unset !important;
        grid-template-columns: 1fr !important;
        padding: $half-default-size $half-default-size 0;
      }
    }

    &__product-tabs {
      position: relative;
      margin-top: $default-size;
      height: calc(#{$double-default-size} + 8px);

      .md-tab-nav-button svg {
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-right: 3px;
        vertical-align: text-top;
      }

      .md-button, .md-tabs {
        display: inline-block;
        vertical-align: middle;
      }

      .md-tabs-navigation .md-ripple {
        padding: 0 !important;
      }

      .md-caption {
        position: absolute;
        top: -17px;
      }

      .md-tabs {
        width: calc(100% - #{$double-default-size} - 26px); // 26px margins of button + tabs + default el size
        margin-left: 10px;
      }
    }

    .md-tabs {
      display: inline-block;
      overflow-x: auto;
      max-width: calc(100% - #{$icon-size});

      @media (max-width: $tablet--small) {
        max-width: calc(100vw - #{$icon-size + $default-size + $admin-side-menu-width--mobile + ($material-size--small * 2)});
      }
    }

    .md-layout.md-gutter {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }

    .md-tabs-navigation {
      display: block;
      white-space: nowrap;

      .md-tab-nav-button {
        overflow: hidden;
      }
    }

    .md-ripple {
      padding: 0 $half-default-size;
    }

    .md-tab-nav-button {
      min-width: 100px;
      padding: 0 10px;
      box-sizing: border-box;

      &:after {
        position: absolute;
        top: 15%;
        right: 0;
        height: 70%;
        width: 1px;
        content: "";
        background: $pure-white;
        background: linear-gradient(to bottom, $pure-white 0%, $blue 50%, $pure-white 100%);
      }

      &:nth-last-child(2):after {
        display: none !important;
      }
    }

    &__inner-tab-wrapper {
      padding-right: 5px;
    }

    .md-caption {
      @media (max-width: $tablet--small) {
        display: block;
      }
    }
  }

  .admin-products {
    &__speed-dial {
      position: relative;
      margin-left: $default-size;

      .md-fab {
        width: 40px;
        height: 40px;
      }

      .md-speed-dial-target {
        cursor: default !important;
      }

      .md-speed-dial-content {
        position: absolute;
        top: 49px;
        left: 0;
        width: 40px;
        z-index: $z-index--hidden;
      }

      &:hover {
        .md-speed-dial-content {
          z-index: 1;
        }
      }
    }

    &__products-toggle {
      float: right;
    }

    &__products-with-errors {
      display: flex;
      overflow: auto;
      height: 100px;
      padding: $half-default-size;

      &-wrapper {
        position: fixed;
        bottom: $half-default-size;
        max-width: 60%;
        min-width: 400px;
        left: 50%;
        transform: translateX(-50%);
        background-color: $pure-white;
        border-radius: $border-radius;

        @media (max-width: $tablet--small) {
          max-width: calc(100% - #{$default-size + $admin-side-menu-width--mobile});
          min-width: unset;
          left: #{$admin-side-menu-width--mobile + $half-default-size};
          transform: unset;
        }

        p {
          margin: 0;
          font-weight: bold;
          color: $material-red;
          padding: $half-default-size $half-default-size 0;
        }
      }

      div {
        display: inline-flex;
        transition: .2s ease-out;
        border: 1px solid $material-grey--light;
        border-radius: 3px;
        margin-right: $half-default-size;
        padding: $half-default-size;
        transition: .2s linear;
        cursor: pointer;

        &:last-child {
          margin: 0;
        }

        &:hover {
          box-shadow: 1px 4px 12px 1px $shadow-color-light;
          transform: scale(1.08);
        }
      }

      img {
        max-width: 80px;
        max-height: 60px;
        object-fit: contain;
      }

      span {
        display: none;
      }
    }
  }
</style>
