import { v4 as uuid } from "uuid";
import html_enity_encoder from "urlencode";
import he from "he"
import { csv_fields } from "../constants/csv_import_constants";
import store from "../../../store";
import {
  added,
  ADMIN_STORE,
  CONFIGURATION,
  created,
  default_currency,
  default_option_name,
  default_product_name,
  ulsemo,
} from "../constants/others_constants";
import { description_from_message_splitter, max_number_of_options } from "../../../data/other_constants";
import empty_variant_object from "../constants/empty_objects/empty_variant_object";
import { distance_units, weight_units } from "../constants/units";
import empty_collection_object from "../constants/empty_objects/empty_collection_object";
import { default_language } from "../../Shared/constants/other";
import sanitize_image_route from "./sanitize_image_route"
import empty_option_object from "../constants/empty_objects/empty_option_object";

const get_translation_data = (
  data,
  available_languages,
  correct_key,
  data_index = ""
) => {
  const values_per_language = available_languages.reduce((total, lang) => {
    const found_value_translation = data.reduce((correct_value, [key, val]) => {
      let is_correct_key

      // Make sure that you are getting variant_prices and not variant_prices_before_discount
      if (correct_key === csv_fields.variant_prices) {
        is_correct_key = key.includes(
          `${correct_key.toLowerCase()}${data_index ? " " + data_index : ""}`
        ) && !key.includes(csv_fields.variant_prices_before_discount.toLowerCase())
      }
      else is_correct_key = key.includes(
        `${correct_key.toLowerCase()}${data_index ? " " + data_index : ""}`
      );

      if (!is_correct_key) return correct_value;

      // Check if value key includes correct language
      if (key.includes(` ${lang}`)) return val;
      // If value has already been assigned, no need to search further
      if (correct_value) return correct_value;
      // Assign any other translations value or value without specified language
      return val;
    }, "");

    return {
      ...total,
      [lang]: found_value_translation,
    };
  }, {});

  return values_per_language;
};

const get_variants = (data, store_languages) => {
  const variant_related_keys = [
    "variant_prices",
    "variant_option_values",
    "variant_prices_before_discount",
    "variant_purchase_prices",
    "variant_dimensions",
    "variant_skus",
    "variant_total_inventory",
  ];
  const get_number_of_variants = variant_related_keys
    .map(
      (variant_key) =>
        data
          .find(([key]) =>
            key.includes(csv_fields[variant_key].toLowerCase())
          )?.[1]
          ?.split(variant_key === "variant_dimensions" ? ")," : ",")?.length ||
        0
    )
    .reduce(
      (longest_number, current_number) =>
        current_number > longest_number ? current_number : longest_number,
      0
    );
  const general_dimensions = data.find(([key]) =>
    key.includes(csv_fields.dimensions.toLowerCase())
  )?.[1]?.split(",")

  const [variant_prices, variant_prices_before_discount] = [
    "variant_prices",
    "variant_prices_before_discount",
  ].map((key) => get_translation_data(data, store_languages, csv_fields[key]));

  const created_variants = Array.from(Array(get_number_of_variants)).map(
    (_, index) => {
      const inventory_quantity =
        data
          .find(([key]) =>
            key.includes(csv_fields.variant_total_inventory.toLowerCase())
          )?.[1]
          ?.split(",")
          .map((val) => Number(val) || 0)[index] || 0;
      const purchase_price =
        data
          .find(([key]) =>
            key.includes(csv_fields.variant_purchase_prices.toLowerCase())
          )?.[1]
          ?.split(",")
          .map((val) => Number(val) || 0)[index] || 0;
      const sku =
        data
          .find(([key]) =>
            key.includes(csv_fields.variant_skus.toLowerCase())
          )?.[1]
          ?.split(",")
          .map((val) => val.replaceAll(" ", ""))?.[index] || "";

      // Finding correct variant dimension
      const variant_dimensions =
        data
          .find(([key]) =>
            key.includes(csv_fields.variant_dimensions.toLowerCase())
          )?.[1]
          ?.split("),")
          .map((val) =>
            val.replaceAll("(", "").replaceAll(")", "").split(",")
          )?.[index] || [];

      const dimension_values_array = (
        variant_dimensions?.length ? variant_dimensions : general_dimensions
      )?.map((val) => val.replace(/[^0-9]+/g, "").replaceAll(" ", ""));
      const dimension_units_array = (
        variant_dimensions?.length ? variant_dimensions : general_dimensions
      )?.map((dim) => dim.replace(/[0-9]/g, "").replaceAll(" ", ""));
      const distance_unit = distance_units?.includes(dimension_units_array?.[0])
        ? dimension_units_array?.[0]
        : null;
      const weight_unit = weight_units?.includes(
        dimension_units_array?.[dimension_units_array?.length - 1]
      )
        ? dimension_units_array?.[dimension_units_array?.length - 1]
        : null;

      // Variant translations
      const translations = store_languages.reduce((tot, lang) => {
        const compare_at_price_values =
          variant_prices_before_discount?.[lang]?.split(",");
        const compare_at_price =
          compare_at_price_values?.[index] || compare_at_price_values[0];

        const price_values = variant_prices?.[lang].split(",");
        const price = Number(price_values?.[index] || price_values[0] || 0);

        const first_option_value =
          get_translation_data(
            data,
            store_languages,
            csv_fields.variant_option_values,
            1
          )?.[lang].replaceAll(" ", "").split(",")?.[index] || default_option_name;
        const second_option_value =
          get_translation_data(
            data,
            store_languages,
            csv_fields.variant_option_values,
            2
          )?.[lang].replaceAll(" ", "").split(",")?.[index] || "";
        const third_option_value =
          get_translation_data(
            data,
            store_languages,
            csv_fields.variant_option_values,
            3
          )?.[lang].replaceAll(" ", "").split(",")?.[index] || "";

        return {
          ...tot,
          [lang]: {
            option1: he.decode(first_option_value),
            option2: he.decode(second_option_value),
            option3: he.decode(third_option_value),
            compare_at_price: he.decode(compare_at_price),
            price,
          },
        };
      }, {});

      return empty_variant_object(
        uuid(),
        {
          ...(distance_unit && {
            length: he.decode(dimension_values_array?.[0]),
            width: he.decode(dimension_values_array?.[1]),
            height: he.decode(dimension_values_array?.[2]),
          }),
          ...(weight_unit && {
            weight: he.decode(dimension_values_array?.[3]),
          }),
        },
        undefined,
        Boolean(distance_unit || weight_unit),
        {
          translations,
          inventory_quantity: inventory_quantity,
          purchase_price: purchase_price,
          sku: he.decode(sku),
          ...(distance_unit && { distance_unit: he.decode(distance_unit), }),
          ...(weight_unit && { weight_unit: he.decode(weight_unit), }),
        }
      );
    }
  );

  // Dimensions that help fill out missing dimension values based on dimensions of other variants
  const base_dimensions = created_variants.reduce((build_dimensions, {
    weight,
    length,
    width,
    height,
    distance_unit,
    weight_unit
  }) => {
    if (Object.values(build_dimensions).every(val => val)) return build_dimensions
    
    return {
      weight: build_dimensions.weight || weight,
      length: build_dimensions.length || length,
      width: build_dimensions.width || width,
      height: build_dimensions.height || height,
      distance_unit: build_dimensions.distance_unit || distance_unit,
      weight_unit: build_dimensions.weight_unit || weight_unit,
      
    }
  }, { weight: 0, length: 0, width: 0, height: 0, distance_unit: "", weight_unit: "" })

  return created_variants.map(variant => ({
    ...variant,
    weight: variant.weight || base_dimensions.weight,
    length: variant.length || base_dimensions.length,
    width: variant.width || base_dimensions.width,
    height: variant.height || base_dimensions.height,
    distance_unit: variant.distance_unit || base_dimensions.distance_unit,
    weight_unit: variant.weight_unit || base_dimensions.weight_unit,
  }));
};

export default (converted_csv_data) => {
  const lower_case_data_entries = Object.entries(converted_csv_data).map(
    ([key, value]) => [key.toLowerCase(), value]
  );
  const product_id = uuid();
  const store_languages = Object.keys(
    store.state[ADMIN_STORE][CONFIGURATION].translations
  );

  const [product_titles, product_descriptions, product_messages] = [
    "product_title",
    "description",
    "message",
  ].map((key) =>
    get_translation_data(
      lower_case_data_entries,
      store_languages,
      csv_fields[key]
    )
  );

  const is_product_available =
    lower_case_data_entries.find(
      ([key]) => key === csv_fields.is_product_available.toLowerCase()
    )?.[1] === "TRUE";

  const categories =
    lower_case_data_entries.find(
      ([key]) => key === csv_fields.categorization.toLowerCase()
    )?.[1].replaceAll(" ", "").split(",").filter(val => val)

  const tags =
    lower_case_data_entries.find(
      ([key]) => key === csv_fields.tags.toLowerCase()
    )?.[1].replaceAll(" ", "").split(",")

  const allow_on_marketplace =
    Boolean(lower_case_data_entries.find(
      ([key]) => key === csv_fields.place_on_marketplace.toLowerCase()
    )?.[1] === "TRUE" &&
    categories.length &&
    store.state[ADMIN_STORE][CONFIGURATION]?.shipping_options?.project_address?.country
  )

  const translations = store_languages.reduce(
    (tot, lang) => ({
      ...tot,
      [lang]: {
        title: he.decode(product_titles[lang] || product_titles[Object.keys(product_titles)[0]] || default_product_name),
        body_html: he.decode(`${product_descriptions[lang]}${description_from_message_splitter}{${product_messages[lang]}
        }`),
        currency_code: he.decode(
          store.state[ADMIN_STORE][CONFIGURATION].translations?.[lang]
            ?.currency_code || default_currency,
        )
      },
    }),
    {}
  );

  // Get Variants
  const variants = get_variants(lower_case_data_entries, store_languages)

  // Get Options
  const number_of_variant_options = lower_case_data_entries.find(
    ([key]) => key.includes(csv_fields.variant_option_names.toLowerCase())
  )?.[1]?.split(",")?.length || 0

  const variant_options = Array.from(
    Array(
      number_of_variant_options <= max_number_of_options ?
        number_of_variant_options : max_number_of_options
    )
  ).map(
    (_, option_index) => ({
      translations: {
        ...store_languages.reduce(
          (tot, lang) => ({
            ...tot,
            [lang]: {
              name: get_translation_data(
                lower_case_data_entries,
                store_languages,
                csv_fields.variant_option_names
              )[lang]?.replaceAll(" ", "").split(",")?.[option_index],
              values: variants.map(({ translations }) => translations[lang][`option${option_index + 1}`]),
            },
          }),
          {}
        ),
      }
    })
  );

  // Get collections
  const collection_name = lower_case_data_entries.find(
    ([key]) => key === csv_fields.collection.toLowerCase()
  )?.[1]
  const parent_collection_name = lower_case_data_entries.find(
    ([key]) => key === csv_fields.parent_collection.toLowerCase()
  )?.[1]

  const collections = collection_name ? [empty_collection_object(
    collection_name,
    html_enity_encoder(collection_name),
    `{${html_enity_encoder(parent_collection_name)}}`
  )] : undefined

  // Get Images
  const image_alt_texts = lower_case_data_entries.find(
    ([key]) => key === csv_fields.image_alt_texts.toLowerCase()
  )?.[1]?.split(",").filter(val => val)
  const images = lower_case_data_entries.find(
    ([key]) => key === csv_fields.images.toLowerCase()
  )?.[1]?.split(",").filter(val => val).map((src, index) => (
    {
      alt_text: he.decode(image_alt_texts?.[index] || image_alt_texts?.[0] || product_titles?.[default_language] || product_titles?.[store_languages?.[0]]),
      src,
      id: uuid(),
      change_type: created,
      route: sanitize_image_route(product_titles?.[default_language] || product_titles?.[store_languages?.[0]] || "")
    }
  ))

  return {
    translations,
    handle: product_id,
    id: product_id,
    change_type: created,
    published_at: is_product_available ? new Date().toISOString() : null,
    published: is_product_available,
    variants,
    vendor: ulsemo,
    options: variant_options.length ? variant_options : [empty_option_object(store_languages)],
    collection_change_type: added,
    allow_on_marketplace,
    categories,
    collections,
    tags,
    images
  };
};
